'use strict';

function InvoicesCtrl($scope, $state, getIdData, prefix, defaultPagingValue, $http, $rootScope, warningModal, $translate) {

    $scope.selectedTab = "Invoices";
    $scope.jobId = $state.params.jobId;
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.purchaseOrders = getIdData.purchaseOrders;
    $scope.purchaseOrderCount = getIdData.purchaseOrderCount;
    $scope.jobSubNavStatic = false;

    $scope.job = getIdData.jobDetails;
    $scope.isAdditionalWork = getIdData.isAdditionalWork;
    $scope.amountPaid = getIdData.amountPaid;
    $scope.quoted_amount = getIdData.quoted_amount;
    $scope.quoted_amount_inc_vat = getIdData.quoted_amount_inc_vat;
    $scope.total_invoiced = getIdData.total_invoiced;
    $scope.total_invoiced_exc_vat = getIdData.total_invoiced_exc_vat;
    $scope.reminderInvoiceExcVat = getIdData.reminderInvoiceExcVat;
    $scope.reminderInvoice = getIdData.reminderInvoice;
    $scope.totalPaid = getIdData.totalPaid;
    $scope.totalCredit = getIdData.totalCredit;
    $scope.totalCreditExcVat = getIdData.totalCreditExcVat;
    $scope.total_draft_invoiced = getIdData.total_draft_invoiced;
    $scope.total_draft_invoiced_exc_vat = getIdData.total_draft_invoiced_exc_vat;
    $scope.invoices = getIdData.invoices;
    $scope.invoicesCount = getIdData.invoicesCount;
    $scope.invoiceCreditNoteCount = getIdData.invoiceCreditNoteCount;
    $scope.draft_invoices = getIdData.draft_invoices;
    $scope.draft_invoicesCount = getIdData.draft_invoicesCount;
    $scope.milestoneId = getIdData.jobDetails.active_milestone_id;
    $scope.milestones = getIdData.milestones;
    $scope.milestonesCount = getIdData.milestonesCount;
    $scope.notInvoicedMilestoneCount = getIdData.notInvoicedMilestoneCount;
    $scope.isHideVat = getIdData.isHideVat;
    $scope.informations = getIdData.informations;
    $scope.paymentCharge = getIdData.paymentCharge;
    $scope.toatal_additional_work = getIdData.totalAdditionalWork;
    $scope.toatal_additional_work_with_vat = getIdData.totalAdditionalWorkIncVat;
    $scope.reminderInvoiceStatus = getIdData.reminderInvoiceStatus;
    $scope.reminderToCollect = getIdData.reminderToCollect;
    $scope.reminderToinvoice = getIdData.reminderToinvoice;
    $scope.reminderToinvoiceIncVat = getIdData.reminderToinvoiceIncVat;
    if($scope.notInvoicedMilestoneCount == 1 && getIdData.addFinalInvoice) {
        $scope.invoiceType = 'final';
    } else {
        $scope.invoiceType = 'partial';
    }

    $scope.addPartialInvoice = getIdData.addPartialInvoice;
    $scope.addFinalInvoice = getIdData.addFinalInvoice;

    if ($state.current.data.limitInvoices) {
        $scope.limitInvoices = parseInt($state.current.data.limitInvoices);
    } else {
        $scope.limitInvoices = defaultPagingValue;
    }

    if ($state.current.data.pageNumInvoices) {
        $scope.pageNumInvoices = parseInt($state.current.data.pageNumInvoices);
    } else {
        $scope.pageNumInvoices = 1;
    }

    if ($state.current.data.limitDraftInvoices) {
        $scope.limitDraftInvoices = parseInt($state.current.data.limitDraftInvoices);
    } else {
        $scope.limitDraftInvoices = defaultPagingValue;
    }

    if ($state.current.data.pageNumDraftInvoices) {
        $scope.pageNumDraftInvoices = parseInt($state.current.data.pageNumDraftInvoices);
    } else {
        $scope.pageNumDraftInvoices = 1;
    }

    if ($state.current.data.limitMilestones) {
        $scope.limitMilestones = parseInt($state.current.data.limitMilestones);
    } else {
        $scope.limitMilestones = defaultPagingValue;
    }

    if ($state.current.data.pageNumMilestones) {
        $scope.pageNumMilestones = parseInt($state.current.data.pageNumMilestones);
    } else {
        $scope.pageNumMilestones = 1;
    }

    $scope.job_has_invoices = $scope.job.invoices && $scope.job.invoices.length > 0;
    $scope.job_from_estimate = $scope.job.estimate !== undefined;
    $scope.job_is_completed = ($scope.job.status == 'completed') ? true : false;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('jobId:selected', { 'jobId' : $scope.jobId, 'jobType' : $scope.job['type']});
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    var fetchUrl = prefix + '/customers/'+$scope.customerTabName+'/' + $scope.selectedId + '/jobs/'+$scope.jobId+'/invoices/view';

    $scope.fetchInvoices = function (pageNumInvoices) {
        $http.get(fetchUrl + '?pageNumInvoices=' + pageNumInvoices + '&limitInvoices=' + $scope.limitInvoices).success(function (data) {
            $scope.invoices = data.invoices;
            $scope.invoiceCreditNoteCount = getIdData.invoiceCreditNoteCount;
            $scope.invoicesCount = data.invoicesCount;
            $scope.quoted_amount = data.quoted_amount;
            $scope.total_invoiced = data.total_invoiced;
            $scope.total_invoiced_exc_vat = data.total_invoiced_exc_vat;
            $scope.reminderInvoiceExcVat = data.reminderInvoiceExcVat;
            $scope.reminderInvoice = data.reminderInvoice;
            var message = {name: 'invoicesCount', value: $scope.invoicesCount};
            $rootScope.$emit('event:changeJobScope', message);
        })
    }

    $scope.fetchDraftInvoices = function (pageNumDraftInvoices) {
        $http.get(fetchUrl + '?pageNumDraftInvoices=' + pageNumDraftInvoices + '&limitDraftInvoices=' + $scope.limitDraftInvoices).success(function (data) {
            $scope.draft_invoices = data.draft_invoices;
            $scope.draft_invoicesCount = data.draft_invoicesCount;
            $scope.total_draft_invoiced = data.total_draft_invoiced;
            $scope.total_draft_invoiced_exc_vat = data.total_draft_invoiced_exc_vat;
        })
    }

    $scope.fetchMilestones = function (pageNumMilestones) {
        $http.get(fetchUrl + '?pageNumMilestones=' + pageNumMilestones + '&limitMilestones=' + $scope.limitMilestones).success(function (data) {
            $scope.milestones = data.milestones;
            $scope.milestonesCount = data.milestonesCount;
        })
    }

    $scope.handleJobCompleted = function() {
        var warningMessage = '',
            completedReason = $scope.job.completedreason.toLowerCase(),
            warningId = $scope.job.completedreason.toLowerCase();
        if($scope.job_is_completed) {

            if(completedReason == 'freeofcharge') {
                completedReason = $translate("free.of.charged");
            }
            warningMessage = $translate("You.cannot.invoice.this.job.because.this.job.has.been") + completedReason;
        } else if($scope.addFinalInvoice == false && $scope.addPartialInvoice == false) {
            warningMessage = $translate("You.cannot.add.invoice.because.final.invoice.has.been.created");
        }
        warningModal.show(warningMessage, "Invoice job", "invoice_job_"+warningId);
    }
}

function JobPriceCtrl($scope, $state, $http, $rootScope, $timeout, $filter, $q, confirmationBoxHelper, toastBox, $modal, warningModal, $window, prefix)
{
    var isFromCreditNote = $state.current.name == 'loggedin.customer_list.view.job.invoices.view.view_invoice.new_credit_note';
    $scope.valuesToSend = {};
    $scope.lineItemScope = [];

    $scope.$on('delete_spreadsheet', function(e, categoryId) {
        var deleted_category = _.find($scope.invoice_item_categories, function(category) {
            return category.spreadsheet_categoryId === categoryId
        });

        deleted_category.chosen = false;
        deleted_category.selected = false;

        if($scope.invoiceId) {

            $http.delete($scope.invoiceItemCategoryDeleteUrl + '/' + categoryId).
                success(function (data, status) {
                    // Success message
                });
        }

        $scope.updateChosenInvoiceItemCategories();
        $scope.$broadcast("sidepanel:invoice_item_categories",categoryId);

        //# Check with spreadsheet full validation with category types
        if(typeof $scope.validateSpreadsheetWithCategoryId["type_id_"+categoryId] != 'undefined') {
            delete $scope.validateSpreadsheetWithCategoryId["type_id_"+categoryId];

            $scope.checkedSpreadsheet = $scope.checkValidFunction($scope.validateSpreadsheetWithCategoryId);

            if($scope.checkedSpreadsheet) {
                $scope.saveSpreadsheet = true;
            } else {
                $scope.saveSpreadsheet = false;
            }
        }
    });

    $scope.$on('new_invoice:update_item_categories', function(e, chosen_categories) {

        // Handle to avoid the reload the spreadsheet and calculation issue.
        $scope.new_invoice_item_categories = [];
        $scope.invoice_item_categories.map(function(invoiceCategory) {
            chosen_categories.map(function(category) {
                if (category.spreadsheet_categoryId == invoiceCategory.spreadsheet_categoryId) {
                    invoiceCategory.chosen = true;
                }
            });
            $scope.new_invoice_item_categories.push(invoiceCategory);
        });

        $scope.invoice_item_categories = $scope.new_invoice_item_categories;
        $scope.updateChosenInvoiceItemCategories();
        $scope.isdeleteSpreadsheet = !!((Object.keys($scope.spreadsheetLineItems)).length > 1);

    })

    $scope.updateChosenInvoiceItemCategories = function() {
        $scope.chosen_invoice_item_categories = [];

        $scope.invoice_item_categories.map(function(category) {
            if (category.chosen === true) {
                $scope.chosen_invoice_item_categories.push(category);
            }
        });
    }

    $scope.transitionTo = function transitionTo(invoice_type) {
        if ($scope.disable_final_invoice) {
            // If the user is trying to change a partial invoice
            // to a final invoice but there's already a final
            // invoice saved against the job
            var modal = document.querySelector('#cannot-change-to-final-invoice');

            $(modal).modal('show');
        } else {
            if ($scope.editing_invoice === true) {
                $state.transitionTo('loggedin.customer_list.view.job.invoices.view.edit', {'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId, 'invoiceType': invoice_type, 'invoiceId': $scope.invoiceId});
            } else {
                $state.transitionTo("loggedin.customer_list.view.job.invoices.new", {'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId, 'invoiceType': invoice_type});
            }
        }
    }

    // Validate the spreadsheet
    $scope.$on("validate:save-spreadsheet-data", function(event, status) {
        $scope.saveSpreadsheet = status.isValid;
    });

    $scope.validateSpreadsheetWithCategoryId=[];
    // Update the spreadsheet data with array format
    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        // Category Id will be true only for full breakdown by category.
        var categoryId = message.categoryId;
        var category = message.category;
        var rows = message.rows;
        $scope.checkedSpreadsheet = false;
        if(rows.length) {
            if(categoryId) {
                $scope.validateSpreadsheetWithCategoryId["type_id_"+categoryId]=false;
            }
            // Used to check the quantity & nominal code is required in spreadsheet.
            for(var y=0; y < rows.length; y++) {
                if(rows[y].row[0].associated_rows[0][0].value) {
                    if((rows[y].row[0].associated_rows[0][2].value > 0) && rows[y].row[0].associated_rows[0][5].value) {
                        $scope.checkedSpreadsheet = true;
                        $scope.mustDisableSave = false;
                    } else {
                        $scope.checkedSpreadsheet = false;
                        if(categoryId) {
                            $scope.mustDisableSave = true;
                        }
                        break;
                    }
                    if(categoryId) {
                        $scope.validateSpreadsheetWithCategoryId["type_id_"+categoryId]=true;
                    }
                }
            }

            if(categoryId) {
                $scope[categoryId] = rows;
            } else {
                $scope[category] = rows;
            }
        }

        if(categoryId && $scope.mustDisableSave == false) {
            $scope.checkedSpreadsheet = $scope.checkValidFunction($scope.validateSpreadsheetWithCategoryId);
        }

        if($scope.checkedSpreadsheet) {
            $scope.saveSpreadsheet = true;
        } else {
            $scope.saveSpreadsheet = false;
        }
    });

    // Validate with multiple spreadsheet with category type "only in Full breakdown by category"
    $scope.checkValidFunction = function(validateSpreadsheets) {
        var b = [], prev;
        validateSpreadsheets.sort();
        for(var i in validateSpreadsheets) {
            if ( validateSpreadsheets[i] !== prev ) {
                var arrayKey = validateSpreadsheets[i].toString();
                b[arrayKey]=1;
            } else {
                var arrayKey = validateSpreadsheets[i].toString();
                b[arrayKey]++;
            }
            prev = validateSpreadsheets[i];
        }
        if((typeof b['true'] == 'undefined') || (b['true'] == 0)) {
            return false;
        } else {
            return true;
        }
    }

    HandleDeleteRowFromSpreadsheet.call(this, $scope);

    $scope.handleStateTransition = function(toState) {
        $scope.invoicing_milestone = false;
        $scope.disable_final_invoice = false;

        if (toState.name.includes('new') && !isFromCreditNote) {
            $scope.editing_invoice = false;
        } else {
            $scope.editing_invoice = true;
        }
        if (!$scope.editing_invoice && $scope.job.estimate === undefined) {
            if ($scope.active_option !== 'no_breakdown') {
                $scope.active_option = 'no_breakdown';
            }
        }

        if ($scope.editing_invoice) {
            if ($scope.job.job_is_completed) {
                if ($scope.active_invoice_type === 'partial') {
                    $scope.disable_final_invoice = true;
                }
            }
        }

        if (parseFloat($state.params.milestoneId) > 0) {
            $scope.handleInvoicingMilestone();
        }
    }

    $scope.handleEstimateInvoiceValidation = function() {
        if ($scope.active_option === 'no_breakdown') {
            var total = $scope.total_price - ($scope.initialQuoteAmount + $scope.retentionQuoteAmount),
                quoted_amount = $scope.quotedAmount - ($scope.initialQuoteAmount + $scope.retentionQuoteAmount);
        } else {
            var total = $scope.total_price - ($scope.initialQuoteAmount + $scope.retentionQuoteAmount),
             quoted_amount = $scope.quotedAmount - ($scope.initialQuoteAmount + $scope.retentionQuoteAmount);
        }
        return roundFloat(total,2) !== roundFloat(quoted_amount,2);
    }

    $scope.handleMilestoneInvoiceValidation = function() {
        if ($scope.active_option === 'no_breakdown') {
            return parseFloat($scope.no_breakdown_grand_total.toFixed(2)) !== parseFloat($scope.quotedAmount.toFixed(2));
        } else {
            var total = parseFloat($scope.total_price) +
                    parseFloat($scope.total_tax) -
                    parseFloat($scope.total_cis),
                quoted_amount = parseFloat($scope.quotedAmount.toFixed(2));

            return total !== quoted_amount;
        }
    }

    $scope.handleServiceJobDates = function() {
        if (($scope.job && $scope.job.isservicejob) && (typeof $scope.job.milestones != 'undefined')) {
            var diary_event_dates = $scope.job.milestones[0].diary_events.map(function(diary_event) {
                return diary_event.js_date;
            })

            if (diary_event_dates.indexOf($scope.job.service_date) === -1) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        if(message.name === "invoiceDate"){
            $scope.selectedDate = message.date;
            $scope.refreshPaymentDueDate();
        }else if(message.name === "paymentDueDate"){
            $scope.paymentDueDate = message.date;
        }else{
            $scope.selectedDate = message.date;
        }
    });

    $scope.confirmUnderstandSave = function(textValue) {
        if(textValue.toLowerCase() === 'understand') {
            $scope.displayUnderstandModal = false;
            $scope.errorUnderstand= false;
            $scope.handleSave();
            //$scope.displayUnderstandModal = true;
            $scope.closeModal('#confirm-edit-invoice');
        }else {
            $scope.errorUnderstand= true;
        }
    }
    $scope.editmodel = '';

    $scope.$watch('editmodel', function(decision) {
        if (decision === 'understand') {
            $scope.showDisabled = true;
        } else {
            $scope.showDisabled = false;
        }
    });

    // Open the service job modal box
    $scope.serviceModel = function () {
        $scope.shouldBeOpen = true;
    }

    // Accept the quoted amount is less than or greater than the grant total.
    $scope.acceptQuotedAmount = function() {
        $scope.isAcceptQuotedAmount = true;
        $scope.closeModal('#invoice-does-not-match-quote');
        $scope.handleSave();
    }

    // Accept the invoice amount is greater than the invoice total
    $scope.acceptCreditNoteAmount = function() {
        $scope.isAcceptCreditNoteAmount = true;
        $scope.closeCreditNoteModal('#credit-note-does-not-match-quote');
        $scope.handleSave();
    }

    // Assign the add line item search textbox value in modal box.
    $scope.$on('addLineItemPopupSearch', function(event, data) {
        $scope.addLineItemPopupClosed = data;
    });

    $scope.$on("modal:close", function() {
        $scope.shouldBeOpen = false;
        $scope.saving = false;
    })

    $scope.handleSave = function(modelResp) {
        $scope.saving = true;
        var invoice_is_less_than_its_payments = false,
            invoice_does_not_equal_quote,
            invoice_does_not_equal_milestone_quote;

        if(isFromCreditNote && $scope.invoiceId) {
            var showCreditNoteWarningModal = parseFloat($scope.invoiceAmount) < roundFloat(($scope.totalpriceExc + $scope.total_tax - $scope.total_cis),2);
            if(showCreditNoteWarningModal && $scope.isAcceptCreditNoteAmount == false) {
                var modal = document.querySelector('#credit-note-does-not-match-quote');
                $(modal).modal('show');
                $scope.saving = false;
                return false;
            }
        }

        if ($scope.job && $scope.job.estimateId && !$scope.milestoneId && $scope.isAcceptQuotedAmount == false) {
            invoice_does_not_equal_quote = $scope.handleEstimateInvoiceValidation();
            $scope.quoted_amount = $scope.quotedAmount - ($scope.initialQuoteAmount + $scope.retentionQuoteAmount);
        }
        if ($scope.milestoneId && $scope.isAcceptQuotedAmount == false) {
            invoice_does_not_equal_milestone_quote = $scope.handleMilestoneInvoiceValidation();
            $scope.quoted_amount = $scope.quotedAmount;
        }

        if ((invoice_does_not_equal_quote || invoice_does_not_equal_milestone_quote) && $scope.active_invoice_type == 'final' ) {
            $('#invoice-does-not-match-quote').modal('show');
        } else {
            $scope.isAcceptQuotedAmount = false;

            var show_service_job_ui = false;
            // Need to show only its a service job and the job need to complete
            if($scope.job && ($scope.job.isservicejob && $scope.active_invoice_type == 'final')) {
                var show_service_job_ui = true;
            }

            if ($scope.editing_invoice === true && $scope.displayUnderstandModal == true && !isFromCreditNote) {
                $('#confirm-edit-invoice').modal('show');

            } else if (show_service_job_ui && $scope.serviceJobUpdate == false && $scope.showServiceModalBox == true) {
                $scope.serviceModel();
                $scope.saving = false;
            } else {
                    var totalPriceExc = parseFloat($scope.totalpriceExc);
                // No breakdown
                if(parseInt(this.invoiceBreakdown) === 1) {
                    $scope.total_tax = $scope.noBreakdownVatCost;
                    $scope.total_cis = $scope.no_breakdown_total_cis;
                }

                // This condition only while the job from estimate and its with initial or retention.
                if($scope.initialQuoteAmount || $scope.retentionQuoteAmount) {
                    $scope.newTotalPrice = (totalPriceExc - ($scope.initialQuoteAmount + $scope.retentionQuoteAmount));
                    $scope.newTotalVatPrice = ($scope.total_tax - ($scope.initialQuoteVatAmount + $scope.retentionQuoteVatAmount));

                } else {
                    $scope.newTotalPrice = totalPriceExc;
                    $scope.newTotalVatPrice = $scope.total_tax;
                }

                $scope.grandTotal = parseFloat((($scope.newTotalPrice + $scope.newTotalVatPrice) - $scope.total_cis).toFixed(2));
                $scope.compareNewTotalPrice = parseFloat(($scope.newTotalPrice + $scope.newTotalVatPrice).toFixed(2));

                // Cannot able to create a negative invoice
                if($scope.compareNewTotalPrice < 0) {
                    var reasons = "Please review this invoice. Invoices cannot be created with a negative total",
                        warningModalBoxId = $scope.total_price.toString().replace('.','_');

                    warningModal.show(reasons, "Save invoice", "invoice_cannot_save_minus_"+ warningModalBoxId);
                    $scope.saving = false;
                }
                // This condition only while the job from estimate and its with initial or retention.If the total vat is negative amount the a modal box will appear.
                else if($scope.newTotalVatPrice < 0){
                    var reasons = "Please review this invoice. Invoices cannot be created with a negative vat",
                        warningModalBoxId = $scope.total_price.toString().replace('.','_');

                    warningModal.show(reasons, "Save invoice", "invoice_cannot_save_minus_"+ warningModalBoxId);
                    $scope.saving = false;
                }
                // If the total is less than the paid amount the a modal box will appear.
                else if($scope.grandTotal < $scope.amountAllocated && !isFromCreditNote) {
                    var reasons = "The total (£"+ $scope.grandTotal +") is less than this paid amount (£" +$scope.amountAllocated + "). Please make sure that the invoice total is equal to the paid amount.",
                        warningModalBoxId = $scope.grandTotal.toString().replace('.','_');

                    warningModal.show(reasons, "Save invoice", "invoice_cannot_save_"+ warningModalBoxId);
                    $scope.saving = false;
                }
                // Checked this condition while enter value in add line item search textbox and hit enter key, the form will submit.
                else if($scope.addLineItemPopupClosed) {
                    $scope.saving = false;
                    return false;
                }
                else {
                        if(parseInt(this.invoiceBreakdown) == 1)
                        {
                            // No breakdown
                            $scope.total_tax = $scope.noBreakdownVatCost;
                            $scope.total_cis = $scope.no_breakdown_total_cis;

                            var noBreakdownParam = "&invoice[no_breakdown_cis]=" + this.no_breakdown_cis +
                                "&invoice[no_breakdown_vat_rate]=" + $scope.no_breakdown_vat_rate +
                                "&invoice[taxItemId]=" + $scope.taxItemId +
                                "&invoice[no_breakdown_nominal_code]=" + $scope.no_breakdown_nominal_code +
                                "&invoice[no_breakdown_CIS_rate]=" + $scope.noBreakdownCISRate +
                                "&invoice[no_breakdown_total_price]=" + $scope.total_price;
                        }else
                        {
                            $scope.spreadsheetSave.push($scope.spreadsheetLineItems);
                        }


                    var date = moment($scope.selectedDate).format('YYYY-MM-DD');
                    var paymentDueDate = moment($scope.paymentDueDate).format('YYYY-MM-DD');
                    var description = (!this.invoiceDescription) ? '' : encodeURIComponent(this.invoiceDescription);
                    var rowsToDelete = ($scope.rowToDelete && $scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
                    var params = "invoice[description]=" + description +
                        "&invoice[moreDetails]=" + encodeURIComponent(this.more_details) +
                        "&invoice[customerReference]=" + encodeURIComponent($scope.customerReference) +
                        "&invoice[userGroupId]=" + this.userGroupId +
                        "&invoice[invoiceAddressId]=" + this.invoiceAddressId +
                        "&invoice[invoiceNumber]=" + this.invoiceNumber +
                        "&invoice[vatType]=" + $scope.vatType +
                        "&invoice[date]=" + date +
                        "&invoice[paymentDueDate]=" + paymentDueDate +
                        "&invoice[invoiceCategoryId]=" + this.invoiceCategoryId +
                        "&invoice[invoiceBreakdownType]=" + this.invoiceBreakdown +
                        "&invoice[draftInvoice]=" + $scope.draft_invoice +
                        "&invoice[invoiceTotal]=" + $scope.newTotalPrice +
                        "&invoice[totalVat]=" + $scope.newTotalVatPrice +
                        "&invoice[invoiceCISTotal]=" + $scope.total_cis +
                        "&invoice[rowsToDelete]=" + rowsToDelete +
                        "&invoice[isChangedInvoiceBreakdownType]=" + $scope.isChangedInvoiceBreakdownType +
                        "&invoice[invoiceType]=" + $scope.active_invoice_type;

                    // Get the spreadsheet details
                    if($scope.spreadsheetSave.length) {
                        var spreadsheetData = "&invoice[spreadSheetData]=" + encodeURIComponent(angular.toJson($scope.spreadsheetSave));
                        params = params + spreadsheetData;
                    }

                    if($scope.saveCustomisableSpreadsheetColumn) {
                        var configColumn = "&invoice[configColumn]=" + encodeURIComponent(angular.toJson($scope.saveCustomisableSpreadsheetColumn));
                        params = params + configColumn;
                    }

                    if(this.invoiceBreakdown == 1) {
                        params = params + noBreakdownParam;
                    }

                    // Only if select the add line items.
                    if($scope.lineItemScope.length) {
                        var lineItemParam = "&invoice[lineItems]=" + encodeURIComponent(angular.toJson($scope.lineItemScope));
                        params = params + lineItemParam;
                    }

                    $scope.pathToSave = ($scope.invoiceId) ? $scope.editUrl : $scope.addUrl;
                    $scope.params = params;

                    if($scope.active_invoice_type === 'final' && !$scope.draft_invoice && parseInt($scope.futureDiaryEventCount) > 0){
                        $scope.finalInvoiceConfirmation = true;
                    }else{
                        $scope.saveInvoice();
                    }
                }
            }
        }
    }

    $scope.saveInvoice = function () {
        try {
            $http.post($scope.pathToSave, $scope.params).
            success(function (data, status) {
                if (status == 200) {
                    if(_.isObject(data) && data['error'] == true && data['hotId'])
                    {
                        var responseObj =  JSON.parse(data);
                        $scope.saving = false;
                        $scope.spreadsheetSave = [];
                        var reasons = responseObj.message,
                            warningModalBoxId = "spreadsheet_validation";

                        warningModal.show(reasons, "Save invoice", "invoice_cannot_save_minus_"+ warningModalBoxId);
                        $scope.highlightErrorRows(responseObj.rows,responseObj.hotId,true);

                    }else if(data['error'] == true && data['message'] == 'Final invoice already created'){
                        toastBox.show(data['message'], 4000);
                        $state.transitionTo("loggedin.customer_list.view.job.invoices", {id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId}, {reload: true});
                    }
                    else if(typeof data['error'] != "undefined" || data['error'] == true)
                    {
                        $scope.saving = false;
                        toastBox.show(data['message']+', please try again', 4000);
                    }else
                    {
                        $scope.lineItemScope = [];
                        if($scope.isFromInvoiceAddress) {
                            $state.transitionTo("loggedin.invoice_address.view.details", {id: $state.params.id});
                        } else if((!$state.params.jobId || isFromCreditNote) && data.id) {
                            $state.transitionTo("loggedin.customer_list.view.credit_note.details", {id: $state.params.id, type: $state.params.type, creditNoteId: data.id}, {reload: true});
                        } else if($scope.invoiceId || $scope.screenName == 'invoice') {
                            $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_invoice", {id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId, invoiceId: data}, {reload: true});
                            $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : true});
                        } else {
                            $state.transitionTo("loggedin.customer_list.view.job.invoices", {id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId}, {reload: true});
                        }
                    }
                }
            }).
            error(function (e) {
                $scope.saving = false;
                toastBox.show('Error processing Invoice, please try again', 4000);
            });
        }
        catch (error) {
            $scope.saving = false;
            toastBox.show('Error processing Invoice, please try again', 4000);
        }
    }

    $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
       // $scope.handleStateTransition(toState);
    });

    /*==========================================================================================
     When adding line items to the no breakdown, this function gets fired
     ==========================================================================================*/
    $rootScope.$on('invoice:add_to_total', function(e, total_to_add) {
        $scope.total_price += total_to_add;
        $scope.calculateNoBreakdownGrandTotals();
    })

    /*==========================================================================================
     Change the invoice breakdown - here we need to check if there are categories
     for each line item when switching to a line item breakdown
     ==========================================================================================*/
    $scope.handleSelectedBreakdown = function(active_option) {
        var user_has_edited_breakdown = true;

       // For get the previous selected invoice breakdown value
        $scope.spreadsheetLineItems = {}; // set empty array when load new spreadsheet
        $scope.isLoadExistingSpreadsheetData = false;
        if($scope.active_option == 'no_breakdown') {
            $scope.previousBreakdown = 1;
        } else if($scope.active_option == 'category_breakdown') {
            $scope.previousBreakdown = 2;
            $scope.checkedSpreadsheet = false;
        } else if($scope.active_option == 'full_breakdown') {
            $scope.previousBreakdown = 3;
            $scope.checkedSpreadsheet = false;
        } else if($scope.active_option == 'full_breakdown_by_category') {
            $scope.previousBreakdown = 4;
            $scope.checkedSpreadsheet = false;
        }

        $scope.saveSpreadsheet = false;

        if($scope.job && $scope.job.estimateId) {
            user_has_edited_breakdown = ($scope.total_price !== $scope.original_price);
        }

        var should_show_confirmation = !$scope.user_has_already_confirmed_breakdown &&
            ($scope.editing_invoice ||
            $scope.invoicing_milestone ||
            ($scope.job && $scope.job.estimate) && !$scope.invoicing_milestone) ||
            (user_has_edited_breakdown && $scope.total_price !== 0);

        // Used the && condition to avoid the duplicate(While click on the same active option the modal box will open)
        if (active_option === 'full_breakdown_by_category' && $scope.active_option != 'full_breakdown_by_category') {
            if (should_show_confirmation) {
                var modal = document.querySelector('#show-confirmation-of-line-item-breakdown');

                $scope.invoice_breakdown_is_too_simple = true;
                $scope.deferred_category_breakdown_function = $scope.fullBreakdownByCategory;
                $(modal).modal('show');
            } else {
                $scope.fullBreakdownByCategory();
            }
        } else if (active_option === 'full_breakdown' && $scope.active_option != 'full_breakdown') {
            if (should_show_confirmation) {
                var modal = document.querySelector('#show-confirmation-of-line-item-breakdown');

                $scope.invoice_breakdown_is_too_simple = true;
                $scope.deferred_category_breakdown_function = $scope.fullBreakdown;
                $(modal).modal('show');
            } else{
                $scope.fullBreakdown();
            }
        } else if (active_option === 'category_breakdown' && $scope.active_option != 'category_breakdown') {
            if (should_show_confirmation) {
                var modal = document.querySelector('#show-confirmation-of-line-item-breakdown');

                $scope.invoice_breakdown_is_too_simple = true;
                $scope.deferred_category_breakdown_function = $scope.categoryBreakdown;
                $(modal).modal('show');
            } else {
                $scope.categoryBreakdown();
            }
        } else if (active_option === 'no_breakdown' && $scope.active_option != 'no_breakdown') {
            if (should_show_confirmation) {
                var modal = document.querySelector('#show-confirmation-of-line-item-breakdown');

                $scope.invoice_breakdown_is_too_simple = true;
                $scope.deferred_category_breakdown_function = $scope.noBreakdown;
                $(modal).modal('show');
            } else {
                $scope.confirmActiveOptionChange = true;
                $scope.noBreakdown();
            }
            $scope.saveSpreadsheet = true;
            $scope.isSpreadsheetValidate = true;
        }

        $scope.vatType = (!should_show_confirmation)? $scope.defaultVatType : $scope.vatType;

        // Reset the select invoice item category in side panel.
        $scope.updateChosenInvoiceItemCategories();

        $scope.$broadcast('removeCheckedInAddLineItem', true); // reset all line items in side panel
    };

    $scope.confirmBreakdown = function() {
        $scope.confirmActiveOptionChange = true;

        // If select the add line item and then change the Invoice Breakdown type without saving, then we need to rest the checked box.
        $scope.$broadcast('removeCheckedInAddLineItem', $scope.confirmActiveOptionChange);

        if ($scope.active_option === 'no_breakdown') {

            $scope.taxItemId = $scope.defaultTaxItem;
            this.taxItemId = $scope.defaultTaxItem;
            $scope.no_breakdown_cis = 'No';
            $scope.total_price = 0;

            if($scope.noBreakdownNominalCodeArray.length) {
                $scope.no_breakdown_nominal_code = $scope.firstNominalCode;
            } else {
                $scope.no_breakdown_nominal_code = '';
            }

            $scope.no_breakdown_total_price = 0;
        }

        if($scope.invoiceId && !isFromCreditNote) {
            /*$http.delete($scope.changebreakdownDeleteUrl).
                success(function (data, status) {
                    // Success message
                });*/
            $scope.isChangedInvoiceBreakdownType = true;
        }
        $scope.vatType =  $scope.defaultVatType;
        $scope.deferred_category_breakdown_function();
        $scope.user_has_already_confirmed_breakdown = true;
        $scope.confirmActiveOptionChange = false;
        $('#show-confirmation-of-line-item-breakdown').modal('hide');
    }
    $scope.closeModal = function(modalName) {
        $scope.confirmActiveOptionChange = false;
        $scope.invoiceBreakdown = $scope.previousBreakdown;
        $scope.saveSpreadsheet = true;
        $(modalName).modal('hide');
    }

    // Close the model box if the credit note is greater that invoice amount
    // Having problem with when we cancel the modal box. It set the previous breakdown type.
    $scope.closeCreditNoteModal = function(modalName) {
        $scope.confirmActiveOptionChange = false;
        $scope.saveSpreadsheet = true;
        $(modalName).modal('hide');
    }

    $scope.$watch('invoice_has_cis', function(decision) {
        if (decision === 'No') {
            $scope.total_cis = 0;
        } else {
            $scope.calculateGrandTotals();
        }
    });

    /*==========================================================================================
     Handle switching the invoice breakdown
     ==========================================================================================*/
    $scope.handleInvoicingMilestone = function() {
        var milestone_index = $scope.job.milestones.map(function(milestone) {
            return milestone.id;
        }).indexOf(parseInt($state.params.milestoneId));

        if (milestone_index > -1) {
            $scope.active_milestone = $scope.job.milestones[milestone_index];
        }

        $scope.active_invoice_type = 'partial';
        $scope.invoicing_milestone = true;
        $scope.invoice_help_text = "When invoicing a milestone, Commusoft automatically creates an invoice for the milestone with the amount set up in the invoice schedule. This is the amount that the customer agreed to pay for this milestone. If you want to change the pricing breakdown, you can but you will need to enter the line items manually."
    }

    $scope.noBreakdown = function() {

        // Scope used for send the spreadsheet data to backend during save option.
        $scope.spreadsheetSave = [];

        if($scope.confirmActiveOptionChange) {

            $scope.taxItemId = $scope.defaultTaxItem;
            $scope.no_breakdown_cis = 'No';
            $scope.total_price = 0;
            $scope.no_breakdown_nominal_code = $scope.firstNominalCode;
            $scope.no_breakdown_total_price = 0;
            $scope.totalpriceExc = 0;
        }

        $scope.resetLocalStorageTotals();
        $scope.show_engineer_feedback_link = true;
        $scope.active_option = 'no_breakdown';
        $scope.resetLocalStorageTotals();
        $scope.show_grand_totals = true;

        if($scope.no_breakdown_total_price && ($scope.confirmActiveOptionChange == false)) {
            $scope.total_price = parseFloat($scope.no_breakdown_total_price);
        }

        $scope.calculateNoBreakdownGrandTotals();
        $rootScope.$broadcast('active_option_changed', $scope.active_option);
    }

    $scope.categoryBreakdown = function() {
        // Scope used for send the spreadsheet data to backend during save option.
        $scope.spreadsheetSave = [];
        $scope.job_invoice_category_breakdown = '';

        $scope.resetLocalStorageTotals();
        $scope.show_engineer_feedback_link = true;
        $scope.active_option = 'category_breakdown';
        $scope.resetLocalStorageTotals();

        $rootScope.$broadcast('active_option_changed', $scope.active_option);
    }

    $scope.fullBreakdown = function() {

        // Scope used for send the spreadsheet data to backend during save option.
        $scope.spreadsheetSave = [];

        $scope.resetLocalStorageTotals();
        $scope.show_engineer_feedback_link = true;
        $scope.active_option = 'full_breakdown';
        $scope.resetLocalStorageTotals();

        $rootScope.$broadcast('active_option_changed', $scope.active_option);
    }

    $scope.fullBreakdownByCategory = function() {

        // Scope used for send the spreadsheet data to backend during save option.
        $scope.spreadsheetSave = [];

        $scope.resetLocalStorageTotals();
        $scope.show_engineer_feedback_link = true;
        $scope.active_option = 'full_breakdown_by_category';
        $scope.resetLocalStorageTotals();

        $rootScope.$broadcast('active_option_changed', $scope.active_option);
    }

    $scope.resetLocalStorageTotals = function resetLocalStorageTotals() {
        // clear grandtotals from localstorage
        var pricing_grand_totals_exist = localStorage.getItem('jobs:price:spreadsheetGrandTotals') !== null;
        if (pricing_grand_totals_exist) {
            localStorage.removeItem('jobs:price:spreadsheetGrandTotals');
        }
    }

    $scope.calculateNoBreakdownGrandTotals = function() {
       // During enter the total in input box, we will get the latest value using THIS keyword and assign to SCOPE.
        var total_price = 0;
        var no_breakdown_vat_rate;

        if(this.total_price) {
            $scope.total_price = total_price = roundFloat(this.total_price,2);
        }

        if(this.taxItemId){
            var taxItemId =  parseInt(this.taxItemId);
            var selectedTaxItem = (_.where($scope.taxDetails,{id:taxItemId}));
            $scope.taxItemId = selectedTaxItem[0].id;
            if(selectedTaxItem.length > 0)
            {
                $scope.no_breakdown_vat_rate = selectedTaxItem[0].effective_tax;

                // $scope.total_tax_rate = selectedTaxItem[0].totalTax; // without compound rate if exist
                // $scope.effective_tax_rate = selectedTaxItem[0].effectiveTax;
                // $scope.tax_item_data = {};
                // var taxRateData = $scope.taxDetails['taxRates'][taxItemId];
                //
                // for (var i=0 ;i < taxRateData.length;i++){
                //     var componentId = taxRateData[i]['taxComponent']['id'];
                //     $scope.tax_item_data[componentId] =  { 'taxRate':parseFloat(taxRateData[i]['taxRate']),'componentName':taxRateData[i]['taxComponent']['component']};
                // }
            }
        }else{
            $scope.no_breakdown_vat_rate = 0;
        }

        no_breakdown_vat_rate = parseFloat($scope.no_breakdown_vat_rate);

        if($scope.vatType === "inc_vat")
        {
            $scope.totalpriceExc = roundFloat((total_price * 100)/(no_breakdown_vat_rate + 100),2);
            $scope.noBreakdownVatCost = $scope.total_price - $scope.totalpriceExc;
        }else
        {
            $scope.totalpriceExc = total_price;
            $scope.noBreakdownVatCost = roundFloat((total_price/100) * no_breakdown_vat_rate,2);
        }

        $scope.no_breakdown_cis = (this.no_breakdown_cis) ? this.no_breakdown_cis: 'No';
        $scope.no_breakdown_nominal_code = (this.no_breakdown_nominal_code) ? this.no_breakdown_nominal_code: null;
        $scope.no_breakdown_total_cis = ($scope.no_breakdown_cis === "Yes") ? roundFloat( ($scope.totalpriceExc / 100) * $scope.noBreakdownCISRate,2 ): 0 ; // Note 0.3 is the CIS percentage set up in company settings
        $scope.no_breakdown_grand_total = $scope.totalpriceExc + $scope.noBreakdownVatCost - $scope.no_breakdown_total_cis;
        $scope.total_tax = $scope.noBreakdownVatCost;
        $scope.total_cis = $scope.no_breakdown_total_cis;
    };

    /*==========================================================================================
     Grand Totals
     ==========================================================================================*/
    $scope.calculateGrandTotals = function calculateGrandTotals() {
        $scope.total_price = 0;
        $scope.total_tax = 0;
        $scope.total_cis = 0;

        // Not used in add/edit invoice screen
        $scope.total_uncategorised_discount = 0;
        $scope.total_uncategorised_commission = 0;

        var pricing_grand_totals_str = localStorage.getItem('jobs:price:spreadsheetGrandTotals'),
            pricing_grand_totals = JSON.parse(pricing_grand_totals_str);

        if (pricing_grand_totals) {
            $scope.show_grand_totals = pricing_grand_totals.length > 0;

            for(var i = 0, l = pricing_grand_totals.length; i < l; i++) {
                var key = Object.keys(pricing_grand_totals[i]);

                $scope.total_price += pricing_grand_totals[i][key]["Total"];
                $scope.total_tax += pricing_grand_totals[i][key]["Total VAT"];

                if (pricing_grand_totals[i][key]["Total CIS"] !== undefined) {
                    $scope.total_cis += pricing_grand_totals[i][key]["Total CIS"];
                }

                // Not used in add/edit invoice screen
                if ($scope.include_uncategorised_discount === true) {
                    if (key[0] === 'Invoice breakdown') {
                        $scope.total_uncategorised_discount -= pricing_grand_totals[i][key]["Discount"];
                        $scope.total_tax -= pricing_grand_totals[i][key]["Discount VAT"];
                    }
                }
                if ($scope.include_uncategorised_commission === true) {
                    if (key[0] === 'Invoice breakdown') {
                        $scope.total_uncategorised_commission -= pricing_grand_totals[i][key]["Commission"];
                        $scope.total_tax -= pricing_grand_totals[i][key]["Commission VAT"];
                    }
                }
            }

            $scope.total_price = roundFloat($scope.total_price,2);
            $scope.total_tax = roundFloat($scope.total_tax,2);
            $scope.total_cis = roundFloat($scope.total_cis,2);

            // Not used in add/edit screen
            if($scope.total_uncategorised_discount) {
                $scope.total_uncategorised_discount = roundFloat($scope.total_uncategorised_discount,2);
                $scope.total_uncategorised_commission = roundFloat($scope.total_uncategorised_commission,2);

                // Used for multiple invoice screen.
                $rootScope.$emit('leftnav:update_pricing', $scope.total_price);
            }
        }
    }

    $rootScope.$on('update:jobs:price:spreadsheetGrandTotals', function() {
        $scope.calculateGrandTotals();
    });

    /*==========================================================================================
     Configurable spreadsheet options in full breakdown and full breakdown by category
     ==========================================================================================*/
    $scope.$on('estimate_price:columns_configured', function(e, data) {
        if($scope.invoiceId || $scope.creditNoteId){
            $scope.show_vat_in_grand_totals = data.show_vat_in_grand_totals;
            $scope.handleSpreadsheetColumns(data.columns);
            $scope.customisable_spreadsheet_columns = data.columns;
            // Added this scope, to get the column for save.
            $scope.saveCustomisableSpreadsheetColumn = data;

            if($scope.creditNoteId) {
                var updateUrl = prefix + '/updateCreditNoteConfigColumn';
                var values = 'configColumn[columns]=' + angular.toJson(data) + '&configColumn[creditNoteId]=' +$scope.creditNoteId;
            } else {
                var updateUrl = prefix + '/updateConfigColumn';
                var values = 'configColumn[columns]=' + angular.toJson(data) + '&configColumn[invoiceId]=' +$scope.invoiceId;
            }

            $http.post(updateUrl, values).
                success(function (data, status) {
                    if (status == 200) {
                        $scope.$emit('update_config_columns', data);
                        $scope.flashMessage = "Updated";
                    }
                });
        } else {
            $scope.show_vat_in_grand_totals = true;
            $scope.$emit('update_config_columns', $scope.show_vat_in_grand_totals);
        }
    });

    $scope.handleSpreadsheetColumns = function(column_settings) {
        $rootScope.$emit('spreadsheet:updatePricingColumns', column_settings);
    }

    $scope.closePanel = function closePanel() {
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $timeout(function() {
        $scope.quote_and_existing_payment_information = [];

        /*if ($scope.job.quoted_amount && !$scope.invoicing_milestone && !$scope.job.estimate) {
            $scope.quote_and_existing_payment_information.push({
                type: 'info',
                message: "You have quoted " + $filter('customCurrency')($scope.job.quoted_amount, '£') + " for this job"
            });
        }*/
        /*if ($scope.editing_invoice) {
            $scope.quote_and_existing_payment_information.push({
                type: 'info',
                message: "The customer has paid " + $filter('customCurrency')($scope.job_invoice.grand_total - $scope.job_invoice.balance, '£') + " of this invoice"
            });
        }*/
        /*if ($scope.invoicing_milestone) {
            $scope.quote_and_existing_payment_information.push({
                type: 'info',
                message: "You have quoted " + $filter('customCurrency')($scope.active_milestone.quoted_amount, '£') + " for milestone " + $scope.active_milestone.name.toLowerCase()
            });
        }*/

        if (($scope.job && $scope.job.estimate && !$scope.invoicing_milestone) ||
            ($scope.invoicing_milestone && $scope.active_milestone.additional_work === true)) {
            $scope.fullBreakdown();
        } else {
            if ($scope.invoicing_milestone) {
                $scope.no_breakdown_vat_rate = "20";
                $scope.total_price = parseFloat(($scope.active_milestone.quoted_amount * 0.8333333333333333333333).toFixed(2));
                $scope.noBreakdown();
            } else {
                if ($scope.active_option === 'full_breakdown_by_category') {
                    $scope.fullBreakdownByCategory();
                } else if ($scope.active_option === 'category_breakdown') {
                    $scope.categoryBreakdown();
                } else if ($scope.active_option === 'full_breakdown') {
                    $scope.fullBreakdown();
                } else {
                    $scope.noBreakdown();
                }
            }
        }
        $timeout(function() {
            $scope.original_price = $scope.total_price;
        }, 1000);
    }, 50);
    $scope.updateChosenInvoiceItemCategories();

}

function InvoiceViewCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate, emailService, $compile, $timeout, $sce) {
    if(getIdData.deleteOrNull== 'Deleted' ) {
        var message = getIdData.deleteOrNull;
        $state.transitionTo('loggedin.customer_list.view.job.invoices', {'id': $state.params.id, 'type': $state.params.type, 'jobId':$state.params.jobId},{reload: true});
    }
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.invoiceId = $state.params.invoiceId || getIdData.invoiceId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.hideSubNavCustomers = true;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.prefix = prefix;
    $scope.show_tabs = true;
    $scope.job = getIdData.jobDetails;
    $scope.job_invoice = getIdData.invoice;
    $scope.vatType = getIdData.invoice.vatType;
    $scope.initialQuoteAmount = parseFloat($scope.job_invoice.initialQuoteAmount);
    $scope.retentionQuoteAmount = parseFloat($scope.job_invoice.retentionQuoteAmount);
    $scope.interimInvoiceAmount = parseFloat($scope.job_invoice.interimInvoiceAmount);
    $scope.grantInvoiceAmount = parseFloat($scope.job_invoice.grantInvoiceAmount);
    $scope.invoiceSelectedTab = 'Invoices';

    $scope.invoiceTypeId=(getIdData.invoice.type == 'retention' || getIdData.invoice.type == 'initial')?true:false;

    $scope.paymentDetails = getIdData.paymentDetails;
    $scope.amountAllocated = roundFloat(getIdData.amountAllocated,2);
    $scope.printAndPostModule = getIdData.printAndPostModule;
    $scope.invoiceAddressId = getIdData.invoiceAddressId;
    $scope.watermark = getIdData.watermark;

    $scope.allowDeleteInvoice = getIdData.allowDeleteInvoice;
    $scope.deleteInvoiceReasons = getIdData.deleteInvoiceReasons;
    $scope.deleteWarningType = getIdData.deleteWarningType;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'invoiceSelectedTab': $scope.invoiceSelectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', { 'jobId' : getIdData.jobId, 'jobType' : getIdData.jobDetails.type, 'invoiceId' : $scope.invoiceId , 'invoiceNo' : getIdData.invoice.invoice_no, 'draft' : getIdData.invoice.draft});

    $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : true} );

    $scope.invoiceId = $state.params.invoiceId;
    $scope.$on("informationbar:draft_invoice", function (event, message) {
        $scope.convertDraftInvoiceShouldBeOpen =  true;
    });
    $scope.$on("informationbar:job_details", function (event, message) {
        $state.transitionTo("loggedin.customer_list.view.job.details", { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId });
    });
    $scope.fetchInvoiceDetails = function () {
        $scope.flashMessage = 'Converted draft into invoice';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_invoice", {id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId, invoiceId: $scope.invoiceId}, {reload:true});
    }
    $scope.invoiceDefaultSubject = getIdData.invoiceDefaultSubject;
    $scope.invoiceDefaultMessage = getIdData.invoiceDefaultMessage;

    $scope.deleteWarningModalBox = function() {
        warningModal.show($scope.deleteInvoiceReasons, "Delete invoice", "invoice_delete_"+$scope.deleteWarningType);
    }

    $scope.$on("modal:close", function() {
        $scope.deleteInvoiceShouldBeOpen = false;
    })

    $scope.showDeleteBox = function() {
        $scope.deleteInvoiceShouldBeOpen = true;
    }
    $scope.$on('event:sentStatus', function(event, data) {
        var id = data.id;
        var tableType = data.type;
        if(tableType == 59) {
            $http.get(prefix + '/getInfoBarDetails?id=' + id + '&type=' + tableType).success(function(data) {
                $scope.job_invoice.audits = data;
            });
        }
    });

    $scope.triggerAddNewPayment = function (type, id, jobId, invoiceId) {
        $http.get(prefix + '/checkInvoicePayment?id=' + id + "&jobId=" + jobId + "&invoiceId=" + invoiceId).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }
            else {
                $state.transitionTo('loggedin.customer_list.view.job.invoices.view.view_invoice.new_payment', { type : $state.params.type, id : $state.params.id, jobId:$state.params.jobId, invoiceId:$state.params.invoiceId})
            }
        });
    }

    $scope.triggerRaiseCreditNote = function(id, type, jobId, invoiceId) {
        $http.get(prefix + '/checkInvoiceIsDraft?draft=' + getIdData.invoice.draft).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }
            else {
                $state.transitionTo('loggedin.customer_list.view.job.invoices.view_invoice.new_credit_note', {id:$state.params.id, type:$state.params.type, jobId:$state.params.jobId, invoiceId:$state.params.invoiceId});
            }
        });
    }

    $scope.triggerRaiseCreditNote = function(id, type, jobId, invoiceId) {
        $http.get(prefix + '/checkInvoiceIsDraft?draft=' + getIdData.invoice.draft).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }
            else {
                $state.transitionTo('loggedin.customer_list.view.job.invoices.view_invoice.new_credit_note', {id:$state.params.id, type:$state.params.type, jobId:$state.params.jobId, invoiceId:$state.params.invoiceId});
            }
        });
    }

    $scope.deleteInvoiceDetails = function() {
        $scope.flashMessage = 'Invoice deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo('loggedin.customer_list.view.job.invoices', {id: $state.params.id, type: $state.params.type, jobId: $scope.jobId}, {reload: true});
    }

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_invoice_address_email?id=' + $scope.invoiceAddressId + '&propertyId=' + $scope.selectedId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=59&id=' + $scope.selectedId + '&jobId='+ $scope.jobId + '&invoiceId=' + $scope.invoiceId;
        clone_obj.subject = $scope.invoiceDefaultSubject;
        clone_obj.message = $scope.invoiceDefaultMessage;
        clone_obj.relatedObjectType = 59;
        clone_obj.relatedObjectId = $scope.invoiceId;
        clone_obj.attached_files = [
            {
                id: "INVOICE|" + $scope.selectedId + "|" + $scope.invoiceId + "|" + 59,
                file_name: 'Invoice no. ' + getIdData.invoice.invoice_no,
                file_size: 2,
                type: 'generated'
            }
        ];
        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.showPrintInvoice = function() {
        var side_panel_tpl = $compile('<span id="view_print_invoice" ' +
            'sidepanel template="print_and_post" ' +
            'title="Print and post invoice" ' +
            'supports_copy="true"'+
            'remove_on_close="true" ' +
            'print_and_post_message="Your invoice is on its way to the customer"' +
            'pdf_url="' + Routing.generate('print_invoice', { type: $scope.customerMode, id: $state.params.id, jobId: $state.params.jobId, invoiceId: $scope.job_invoice.id}) +'"'+
            'supports_print="true"' +
            'supports_print_and_post="'+$scope.printAndPostModule +'"'+
            'printpost_params="type=print_post&tableType=59&tablePKID='+$scope.job_invoice.id +'"' +
            'watermark='+$scope.watermark +'></span>')($scope);

        angular.element('body').append(side_panel_tpl);
        $timeout(function() {
            angular.element('#view_print_invoice').scope().$$childTail.initialise();
        }, 600);
    }

    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#view_print_invoice')) {
            angular.element('#view_print_invoice').remove();
        };
    });

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    $scope.quotedAmountStringConstruct = function() {
        var string = [];
        if($scope.initialQuoteAmount > 0) {
            string.push($translate('initial'));
        }
        if($scope.interimInvoiceAmount > 0) {
            string.push($translate('interim'));
        }
        if($scope.retentionQuoteAmount > 0) {
            string.push($translate('retention'));
        }
        if($scope.grantInvoiceAmount > 0) {
            string.push($translate('grant'));
        }

        var showString = string.join(', ');
        var n = showString.lastIndexOf(',');
        var quotedAmountInvoices =  showString.slice(0, n) + showString.slice(n).replace(',', ' & ');
        $scope.quotedAmountTranslation = {'quotedInvoices' : quotedAmountInvoices };
        $scope.quotedAmountInvoices =  quotedAmountInvoices;
    }

    $scope.quotedAmountStringConstruct();

    var invoice_line_items = ($scope.job_invoice['breakdown'] === 4) ? _.flatten(_.pluck($scope.job_invoice['items'], 'items')) : $scope.job_invoice['items'];
    if(($scope.job_invoice['breakdown'] === 1 && !$scope.job_invoice['taxDisplayName']) || ($scope.job_invoice['breakdown'] > 1 && _.max(_.pluck(invoice_line_items,'taxItemName')) === null)){
        $scope.job_invoice['isHideVat'] = true;
    }
}

function AddLineItemsToInvoice($scope, $rootScope, $timeout, jobLineItemDecorator, $state, prefix, $http, $q)
{
    $scope.removeChecked = false;

    /*$scope.handleFilter = function() {
        $scope.line_items = [];
        for (var i = 0, x = $scope.timeline_sections.length; i < x; i++) {
            $scope.timeline_sections[i].showing = false;
        }
        for (var i = 0, x = $scope.all_line_items.length; i < x; i++) {
            var line_item = $scope.all_line_items[i],
                description = line_item.engineer_name || line_item.description || line_item.part || line_item.title,
                in_search_filter = description.toLowerCase().search($scope.search_events.toLowerCase()) > -1,
                in_invoiced_filter = (line_item.already_invoiced === true && $scope.filter_events_by_invoice_status === 'invoiced' ||
                line_item.already_invoiced === false && $scope.filter_events_by_invoice_status === 'not_invoiced' ||
                $scope.filter_events_by_invoice_status === 'all'),
                in_milestone_filter = (line_item.milestone === $scope.filter_events_by_milestone) || $scope.filter_events_by_milestone === 'all';

            if (in_search_filter &&
                in_invoiced_filter &&
                in_milestone_filter ||
                line_item.selected === true) {

                // If select the add line item and then change the Invoice Breakdown type without saving, then we need to rest the checked box.
                if($scope.removeChecked == true  && ((line_item.invoiceId == $scope.$parent.$parent.invoiceId) || (line_item.already_invoiced == false))) {
                    line_item.selected = false;
                    line_item.already_invoiced = false;
                }
                line_item.currency = $rootScope.CustomCurrency;
                $scope.line_items.push(line_item);
                if($scope.timeline_sections[line_item.timeline_id]) {
                  $scope.timeline_sections[line_item.timeline_id].showing = true;
                }
            }
        }
        $scope.removeChecked = false;
        $scope.$emit('addLineItemPopupSearch', $scope.search_events);
        $scope.lazyLoadWithFilter();
    }*/

    $scope.line_items = [];
    $scope.totalLineItems = [];
    $scope.curPage = 1;
    $scope.infinitePageLimit=20;
    $scope.handleFilter = function(){
      $scope.line_items=[];
      var $line_items = _.chain($scope.all_line_items)
        .filter(function(item){
          var description = item.engineer_name || item.description || item.part || item.title,
            in_search_filter = description.toLowerCase().search($scope.search_events.toLowerCase()) > -1,
            in_invoiced_filter = (item.already_invoiced === true && $scope.filter_events_by_invoice_status === 'invoiced' ||
              item.already_invoiced === false && $scope.filter_events_by_invoice_status === 'not_invoiced' ||
              $scope.filter_events_by_invoice_status === 'all');
          var isValid = in_search_filter && in_invoiced_filter || item.selected === true;
          return isValid;
        })
        .value();
      $scope.totalLineItems = getFilterByTimelineId($line_items);
      $scope.line_items = paginateLineItems($scope.totalLineItems, 1);
      $scope.curPage = 1;
      //console.log('timeline_sections',$scope.totalLineItems, $scope.paginatedLineItems );
      $scope.$emit('addLineItemPopupSearch', $scope.search_events);
    };

    $scope.lazyLoadingOnScroll = function() {
      //console.log('hi', $scope.totalLineItems.length, $scope.line_items.length);
      if($scope.totalLineItems.length === $scope.line_items.length) {
        return;
      }
      $scope.curPage++;

      var nextItems = paginateLineItems($scope.totalLineItems, $scope.curPage);
      _.forEach(nextItems, function(item){
        $scope.line_items.push(item);
      });
    };

    function paginateLineItems(filteredItems, page_number, page_size) {
      var limit= !page_size ? $scope.infinitePageLimit : page_size, currentPage = !page_number ? 1 : page_number;
      if(filteredItems.length <= limit) {
        return filteredItems;
      }
      --currentPage;
      //console.log('curPage', currentPage)
      return filteredItems.slice(currentPage * limit, (currentPage + 1) * limit);
    }

    function getFilterByTimelineId(lineItems) {
      var _lineItems = !lineItems ? $scope.all_line_items : lineItems;
      var timelineIds = _.pluck($scope.timeline_sections,'id');
      if(!$scope.timeline_sections.length) {
        return [];
      }
      var groupedByTimelineId =  _.chain(_lineItems)
        .filter(function(item){
          item.currency = $rootScope.CustomCurrency;
          return _.contains(timelineIds, item.timeline_id);
        })
        .groupBy(function(item){
          return item.timeline_id;
        })
        .value();
      //pushing timeline section
      var newItems = [];
      _.forEach($scope.timeline_sections,function(section){
        section.showing=true;
        if(groupedByTimelineId[section.id]) {
          section.elementType = 'section';
          section.noFilter = true;
          section.childCount = groupedByTimelineId[section.id].length;
          groupedByTimelineId[section.id].unshift(section);
          //flattern as single line array object
          if(section.childCount > 0) {
            newItems = newItems.concat(groupedByTimelineId[section.id]);
          }
        }
        return;
      });
      return newItems;
    }

    $scope.handleMilestoneFilter = function(selected_milestone) {
        $scope.filter_events_by_milestone = selected_milestone;
        $scope.handleFilter();
    }

    // If select the add line item and then change the Invoice Breakdown type without saving, then we need to rest the checked box.
    $scope.$on('removeCheckedInAddLineItem', function(e,checkedStatus) {
        $scope.removeChecked = checkedStatus;
        $scope.user_has_selected_line_items = false;
        $scope.handleFilter();
    });

    if ($scope.$parent.panel_data !== "" || !_.isEmpty($scope.$parent.maindata) ) {
        $scope.getAllInvoiceItemCategories = [];
        $scope.allInvoiceItemCategories = [];
        var allInvoiceItemCategories = $scope.$parent.$parent.invoiceItemCategories;
        for(var i = 0, j = allInvoiceItemCategories.length; i < j; i++) {
            $scope.allInvoiceItemCategories.push({spreadsheet_categoryId: allInvoiceItemCategories[i].spreadsheet_categoryId, spreadsheet_category: allInvoiceItemCategories[i].spreadsheet_category});
            if(allInvoiceItemCategories[i].chosen === true)
            {
                $scope.getAllInvoiceItemCategories.push({spreadsheet_categoryId: allInvoiceItemCategories[i].spreadsheet_categoryId, spreadsheet_category: allInvoiceItemCategories[i].spreadsheet_category});
            }
        }

        $scope.timeline_sections = $scope.$parent.$parent.timelineSections;
       // $scope.getAllInvoiceItemCategories = $scope.selectLineItemCategory;
        $scope.active_breakdown = $scope.$parent.$parent.active_option;
        $scope.in_category_breakdown = $scope.active_breakdown === 'full_breakdown_by_category' ||
            $scope.active_breakdown === 'category_breakdown';

        $scope.all_line_items = $scope.$parent.maindata;
        $scope.filter_events_by_invoice_status = 'all';
        $scope.filter_events_by_milestone = 'all';
        $scope.search_events = '';
        $scope.handleFilter();
        if ($scope.$parent.$parent.job.type === 'Project') {
            $scope.project_job = true;
            $scope.milestones = _.pluck($scope.$parent.$parent.job.milestones, 'name');
        } else {
            $scope.project_job = false;
        }
    }
    /*==========================================================================================
     Slide panel logic
     ==========================================================================================*/
    $scope.active_slide = 1;
    $scope.user_has_selected_line_items = false;
    $scope.slideInView = function slideInView(slide) {
        if(slide == 2) {

            if($scope.active_breakdown != 'category_breakdown') {
                $scope.getAllInvoiceItemCategories = [];
                var chosenInvoiceItemCategories = $scope.$parent.$parent.chosen_invoice_item_categories;
                for(var y = 0, z = chosenInvoiceItemCategories.length; y < z; y++) {
                    $scope.getAllInvoiceItemCategories.push({spreadsheet_categoryId: chosenInvoiceItemCategories[y].spreadsheet_categoryId, spreadsheet_category: chosenInvoiceItemCategories[y].spreadsheet_category});
                }
            }

            //$scope.getAllInvoiceItemCategories = $scope.selectLineItemCategory;
            $scope.createSelectedLineItemsArray();
            $scope.active_slide = slide;
        } else {
            $scope.active_slide = slide;
        }
    }

    $rootScope.$on('addLineItemCategory', function(e, category, categoryId) {
        // Check whether the categoryId is already added
        var found = $scope.getAllInvoiceItemCategories.some(function (el) {
            return el.spreadsheet_categoryId === categoryId;
        });
        if(!found) {
            $scope.getAllInvoiceItemCategories.push({spreadsheet_categoryId: categoryId, spreadsheet_category: category});
        }
    });

    $rootScope.$on('active_option_changed', function(e, active_option) {
        $scope.active_breakdown = active_option;
        $scope.in_category_breakdown = active_option === 'full_breakdown_by_category' ||
            active_option === 'category_breakdown';
    });

    $scope.handleSelectedLineItem = function(line_item) {
        // Only allow the line item to be selected if it is not alreade invoiced for
        if (line_item.already_invoiced !== true) {
            line_item.selected = !line_item.selected;
        }

        $scope.createSelectedLineItemsArray();
    }

    $scope.createSelectedLineItemsArray = function() {
        $scope.selected_line_items = [];
        $scope.selected_expenses_without_category = [];
        $scope.user_has_selected_line_items = false;
        for (var i = 0, x = $scope.line_items.length; i < x; i ++) {
            var line_item = $scope.line_items[i];
            line_item.currency = $rootScope.CustomCurrency;
            if (line_item.selected === true) {
                $scope.user_has_selected_line_items = true;
                $scope.selected_line_items.push(line_item);

                if ((line_item.type != 'labour') && (line_item.type != 'part') &&
                    $scope.in_category_breakdown === true) {
                    line_item.spreadsheet_category = 'pleasechoose';
                    $scope.selected_expenses_without_category.push(line_item);
                }
            }
        }
    }

    $scope.validateChosenExpenseCategory = function() {
        for (var i = 0, x = $scope.selected_expenses_without_category.length; i < x; i++) {
            var expense = $scope.selected_expenses_without_category[i];

            if (expense.spreadsheet_category === 'pleasechoose') {
                $scope.user_has_selected_categories = false;
                return
            }
        }

        $scope.user_has_selected_categories = true;
    }

    $scope.saveAndAddLineItems = function() {

        // While change the invoice breakdown type we need to update it.
        $scope.active_breakdown = $scope.$parent.$parent.active_option;

        $scope.getSpreadsheet = [];

        for (var i = 0, x = $scope.selected_line_items.length; i < x; i ++) {
            var line_item = $scope.selected_line_items[i];
            var categoryId = 1, hotId = '',category = '',vatRate = line_item.vat_rate;

            if ($scope.active_breakdown === 'full_breakdown_by_category' || $scope.active_breakdown === 'category_breakdown') {
                switch (line_item.type) {
                    case "labour":
                        categoryId = 1;    // LABOUR primary ID is 1
                        break;
                    case "part":
                        categoryId = 2;   // PART primary ID is 2
                        break;
                    default:
                        categoryId = parseInt(line_item.spreadsheet_category);
                }
            }

            if(!$scope.isHideVat && (line_item.type == "labour" || line_item.type == "travel"))
            {
                vatRate = "20.00" ; // for default full breakdown with (labour and part)
            }

            if ($scope.active_breakdown === 'full_breakdown' || $scope.active_breakdown === 'category_breakdown') {
                hotId = $scope.active_breakdown;
            } else if($scope.active_breakdown === 'full_breakdown_by_category'){
               var hotIdObj = _.where($scope.$parent.$parent.chosen_invoice_item_categories,{spreadsheet_categoryId : categoryId});   // handsontable hot-id defines in category
                   hotId = (hotIdObj) ? hotIdObj[0]["spreadsheetHotID"]: '' ;
            }
            category = line_item.type;
            $scope.getSpreadsheet.push($scope.decorateSpreadsheet(line_item, $scope.active_breakdown, categoryId, category,vatRate,hotId));
        }
        $scope.getSpreadsheetData($scope.getSpreadsheet);
    };

    $scope.getSpreadsheetData = function(getSpreadsheet) {

            $scope.$emit('lineItemScope:save', angular.toJson($scope.getSpreadsheet));
            var decorated_line_items = getSpreadsheet,active_breakdown = $scope.active_breakdown;

            if(active_breakdown === "full_breakdown_by_category" || active_breakdown === "full_breakdown")
            {
                $scope.$parent.$parent.updateCustomerSpreadsheet(decorated_line_items);
            }else if(active_breakdown === "category_breakdown")
            {
                if(decorated_line_items)
                {
                    for(var i=0;i < decorated_line_items.length;i++)
                    {
                        decorated_line_items[i].unit_price = decorated_line_items[i].unit_price * decorated_line_items[i].quantity;
                        decorated_line_items[i].quantity = 1 ; // by default category breakdown quantity is 1
                    }
                }
                $scope.$parent.$parent.updateCustomerSpreadsheet(decorated_line_items);
            }else if(active_breakdown === 'no_breakdown')
            {
                $scope.addLineItemsToNoBreakdown();
            }
            $scope.commonExecution();
    };

    $scope.commonExecution = function() {
        $scope.selected_line_items = [];
        for (var i = 0, x = $scope.line_items.length; i < x; i ++) {
            if ($scope.line_items[i].selected === true) {
                $scope.line_items[i].selected = false;
                $scope.line_items[i].already_invoiced = true;
                /*for (var c = 0, v = $scope.all_line_items.length; v < x; c ++) {
                    if ($scope.all_line_items[c].id === $scope.line_items[i].id) {
                        $scope.all_line_items[c].selected = false;
                        $scope.all_line_items[c].already_invoiced = true;
                    }
                }*/
            }
        }

        $scope.active_slide = 1;
        $scope.closePanel();
    }

    $scope.closePanel = function() {
        $scope.search_events='';
        $scope.handleFilter();
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $scope.addLineItemsToNoBreakdown = function() {
        var total_to_add = 0;

        $scope.selected_line_items.map(function(line_item, index) {

            if(line_item.price && line_item.quantity) {
                total_to_add += line_item.price * line_item.quantity;
            } else {
                total_to_add += parseFloat(line_item.total_exc_vat);
            }
        });

        $rootScope.$broadcast('invoice:add_to_total', parseFloat(total_to_add.toFixed(2)));
    }

    $scope.decorateSpreadsheet = function(lineItems, activeBreakdown, categoryId, category, vat_rate,hotId) {

        var defaultTaxItemId = $scope.$parent.$parent.defaultTaxItem;
        var listOfTaxItems   = $scope.$parent.$parent.taxDetails;
        var check            = _.where(listOfTaxItems, {id: lineItems.taxItemId});
        var taxItemId        = check.length ? lineItems.taxItemId : defaultTaxItemId;

        var line_item = {
            lineItemId : lineItems.line_item_id,
            description : lineItems.description,
            part : lineItems.part,
            quantity : lineItems.quantity,
            unit_price : lineItems.price,
            total_exc_vat : lineItems.total_exc_vat,
            vatRate : vat_rate,
            taxItemId : taxItemId,
            type : lineItems.type,
            activeBreakdown : activeBreakdown,
            categoryId : categoryId,
            category : category,
            hotId : hotId
        };
        return line_item;
    }

    $scope.decorateDiaryEvent = function(diary_event) {
        var line_item = {
            description : diary_event.description,
            quantity : diary_event.duration,
            unit_price : 60 // Note this needs to come from the DB for the engineers price per hr
        }

        return jobLineItemDecorator.decorateLineItem($scope.active_breakdown, line_item, 'labour');
    }

    $scope.decoratePart = function(part) {
        var line_item = {
            description : part.part,
            quantity : part.quantity,
            unit_price : part.price
        }

        return jobLineItemDecorator.decorateLineItem($scope.active_breakdown, line_item, 'parts');
    }

    $scope.decorateExpense = function(expense) {
        var line_item = {
            description : expense.description,
            quantity : 1,
            unit_price : expense.total_exc_vat
        }

        return jobLineItemDecorator.decorateLineItem($scope.active_breakdown, line_item, expense.spreadsheet_category);
    }
}

// Call this function in HTML side panel file to add/remove the invoice item category for full breakdown by category spreadsheet.
function AddInvoiceItemCategory($scope, $state, $rootScope, $timeout)
{
    $scope.categories = (typeof  $scope.$parent.panel_content != "undefined") ? $scope.$parent.panel_content : [];
    $scope.categories.map(function(category, index) {
        category.selected = category.chosen;
    });

    $scope.$on('sidepanel:invoice_item_categories',function (e,categoryID) {
        var deleted_category = _.find($scope.categories, function(category) {
            return category.spreadsheet_categoryId === categoryID
        });

        deleted_category.chosen = false;
        deleted_category.selected = false;
    });

    $scope.handleChosenCategories = function(category) {
        if (category.chosen === false) {
            category.selected = !category.selected;
        }

        $scope.chosen_categories = [];

        $scope.categories.map(function(category, index) {
            if (category.selected === true) {
                $scope.chosen_categories.push(category);
            }
        });
    }

    $scope.saveAndAddCategories = function() {
        $scope.categories.map(function(category, index) {
            category.chosen = category.selected;
        });

        if(typeof $scope.chosen_categories != 'undefined') {
            $scope.$emit('new_invoice:update_item_categories', $scope.chosen_categories);
        }
        $scope.chosen_categories = [];
    }
}

// Common ctrl for add / edit invoice screen
function InvoiceAddEditCtrl($scope, $state, $http, prefix, $rootScope, getIdData, $stateParams, warningModal)
{
    $scope.screenName = 'invoice';
    $scope.selectedTab = "Invoices";
    $scope.jobId = $state.params.jobId;
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.milestoneId = '';
    if($state.params.milestoneId) {
        $scope.milestoneId = $state.params.milestoneId;
    }

    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', true);
    $scope.$emit('displayInvoiceScreen', true);
    $scope.jobSubNavStatic = true;
    $scope.job = getIdData.jobDetails;
    $scope.futureDiaryEventCount = getIdData.futureDiaryEventCount;
    if($scope.job.engineernotes){
        $scope.job.engineernotes = $scope.job.engineernotes.replace(/<br\s*[\/]?>/gi, "\n");
    }
    $scope.active_invoice_type = getIdData.invoiceType;

    if((getIdData.invoiceType == 'final' || getIdData.invoiceType == 'partial') && $scope.milestoneId == '') {
        $scope.switchInvoiceType = true;
    } else {
        $scope.switchInvoiceType = false;
    }

    $scope.saveSpreadsheet = false;
    $scope.checkedSpreadsheet = false;
    $scope.job.grand_total = 0;
    $scope.job.balance = 0;
    $scope.quotedAmount = parseFloat(getIdData.quotedAmount);

    $scope.initialQuoteAmount = parseFloat(getIdData.initialQuoteAmount);
    $scope.retentionQuoteAmount = parseFloat(getIdData.retentionQuoteAmount);

    $scope.initialQuoteVatAmount = parseFloat(getIdData.initialQuoteVatAmount);
    $scope.retentionQuoteVatAmount = parseFloat(getIdData.retentionQuoteVatAmount);

    $scope.addLineItems = getIdData.lineItems;
    $scope.timelineSections = getIdData.timelineSections;

    $scope.userGroups = getIdData.userGroups;
    $scope.invoiceAddress = getIdData.invoiceAddress;
    $scope.invoiceCategories = getIdData.invoiceCategories;
    $scope.invoiceItemCategories = getIdData.invoiceItemCategories;
    $scope.invoice_item_categories = getIdData.invoiceItemCategories;
    $scope.copyEngineerQA =  getIdData.engineerFeedback;

    $scope.customisable_spreadsheet_columns = getIdData.customisableSpreadsheetColumns;
    $scope.informations = getIdData.informations;

    $scope.grand_totals = [];
    $scope.show_vat_in_grand_totals = getIdData.showVatGrantTotal.selected;
    $scope.show_cis_in_grand_totals = true;

    $scope.invoice_has_cis = 'No';
    $scope.confirmActiveOptionChange = false;

    // Check whether the invoice had payment or not.
    $scope.amountAllocated = parseFloat(getIdData.amountAllocated.toFixed(2));

    $scope.taxDetails = getIdData.taxDetails['taxItems'];
    $scope.vatType = getIdData.vatType;
    $scope.vatBreakdownType = $rootScope.taxBreakdownType;
    $scope.isHideVat = getIdData.isHideVat;
    $scope.isHideCIS = getIdData.isHideCIS;
    $scope.taxItemId      = getIdData.defaultTaxItemId;
    $scope.taxItemsDropDown = _.groupBy($scope.taxDetails,function (obj) {
        return obj.taxProvinceId;
    });

    $scope.noBreakdownNominalCodeArray = getIdData.noBreakdownNominalCodeArray;
    $scope.noBreakdownCISArray = getIdData.noBreakdownCISArray;
    $scope.noBreakdownCISRate = getIdData.noBreakdownCISRate;
    $scope.firstNominalCode = getIdData.nominalCodeId;

    if($scope.noBreakdownNominalCodeArray.length) {
        $scope.no_breakdown_nominal_code = $scope.firstNominalCode;
    } else {
        $scope.no_breakdown_nominal_code = '';
    }

    $scope.show_engineer_feedback_link = false;
    $scope.user_has_already_confirmed_breakdown = false;
    $scope.disable_final_invoice = getIdData.disableFinalInvoice;

    $scope.total_price = 0;
    $scope.uncategorised_discount_percentage = '50%';
    $scope.uncategorised_commission_percentage = '10%';
    $scope.isChangedInvoiceBreakdownType = false;

    $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
        // clear grandtotals from localstorage
        var pricing_grand_totals_exist = localStorage.getItem('jobs:price:spreadsheetGrandTotals') !== null;
        if (pricing_grand_totals_exist) {
            localStorage.removeItem('jobs:price:spreadsheetGrandTotals');
        }
    });

    //Passed the selected line items into scope value for save.
    $scope.$on('lineItemScope:save', function(event, scopeValue) {

        // Modified logic for COM-9370 logic
        $scope.lineItemSelectedValue = angular.fromJson(scopeValue);
        for(var x=0; x < $scope.lineItemSelectedValue.length; x++) {
            $scope.lineItemScope.push($scope.lineItemSelectedValue[x]);
        }
    });

    $scope.serviceJobUpdate = false;
    $scope.showServiceModalBox = getIdData.showServiceModalBox;

    $scope.$on('freeofchargedata', function(evt,data) {
        $scope.serviceObjects = data;
        var serviceUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+ $scope.jobId + '/service_date_changed';
        $http.post(serviceUrl, "serviceJob[serviceObjects]=" + $scope.serviceObjects).
            success(function (data, status) {
                if (status == 200) {
                    $scope.serviceJobUpdate = true;
                    $scope.handleSave();
                }
            });
    });


    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    if($scope.invoiceId) {
        $scope.jobDetails = { 'jobId': $scope.jobId, 'activeInvoiceType':$scope.active_invoice_type, 'invoiceId': $state.params.invoiceId,
            'invoiceNo':getIdData.invoiceDetailsArray.invoiceNo, 'draft': getIdData.invoiceDetailsArray.isDraft, 'jobType' : $scope.job.type }

        $scope.draft_invoice = getIdData.invoiceDetailsArray.isDraft;
        $scope.opened = getIdData.invoiceDetailsArray.isDraft;
    } else {
        $scope.jobDetails = { 'jobId': $scope.jobId, 'activeInvoiceType':$scope.active_invoice_type, 'jobType' : $scope.job.type }
    }

    $scope.$emit('jobId:selected', $scope.jobDetails);

    $scope.invoice_help_text = getIdData.invoiceHelpText;
    $scope.show_invoice_type = getIdData.showInvoiceType;


    // Scope used for send the spreadsheet data to backend during save option.
    $scope.spreadsheetSave = [];

    $scope.handleCopyLink = function(type) {
        if(type == 'new_invoice:description') {
            $scope.invoiceDescription = $scope.job.description;
        } else if(type == 'new_invoice:notes_for_engineer') {
            var engineerNotes = ($scope.job.engineernotes).replace(/<br ?\/?>/g, '\n').replace(/&nbsp;/g, ' ');
            if($scope.more_details) {
                $scope.more_details += "\n" + engineerNotes;
            } else {
                $scope.more_details = engineerNotes;
            }
        } else if(type == 'new_invoice:more_details') {
            if($scope.more_details) {
                $scope.more_details += "\n" + $scope.copyEngineerFeedback();
            } else {
                $scope.more_details = $scope.copyEngineerFeedback();
            }
        }
    }

    $scope.warningCopyEngineerFeedback = function() {
        var errorMessage = 'Cannot.copy.the.engineers.feedback';
        warningModal.show(errorMessage, 'Copy.engineer.feedback', 'cannot_copy_engineer_feedback');
    }


    $scope.copyEngineerFeedback = function() {
        var copyEngineerFeedback = '';
        for(var i = 0, l = $scope.copyEngineerQA.length; i < l; i++) {

            if(copyEngineerFeedback) {
                copyEngineerFeedback += '\n\n';
            }
            copyEngineerFeedback += 'Diary Event: ' + $scope.copyEngineerQA[i].displayDate +' ' + $scope.copyEngineerQA[i].durationString + '\nStatus: ' + $scope.copyEngineerQA[i].status + '\n';

            // Show the engineer feedback question & answers
            var questions = $scope.copyEngineerQA[i].questions.invoiceQA;
            var displayStatusType = '';
            if(typeof questions != 'undefined') {
                for(var j = 0, m = questions.length; j < m; j++) {
                    if(displayStatusType != questions[j].status) {
                        copyEngineerFeedback += '\n' + 'Job ' + questions[j].status + ' feedback:';
                        displayStatusType = questions[j].status;
                    }
                    copyEngineerFeedback += '\n' + questions[j].question + '\n' + questions[j].answer + '\n';
                }
            }

            // If there is a negative feedback for a diary event then we need to show.
            if($scope.copyEngineerQA[i].diaryEngineerFeedback) {
                if(typeof questions != 'undefined') {
                    copyEngineerFeedback += '\n';
                }
                copyEngineerFeedback += $scope.copyEngineerQA[i].status + ' feedback: \n' + $scope.copyEngineerQA[i].diaryEngineerFeedback;
            }
        }

        return copyEngineerFeedback;
    }

    $scope.handleCancel = function() {
        if ($scope.editing_invoice === true) {
            $state.transitionTo('loggedin.customer_list.view.job.invoices.view.view_invoice', { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId, invoiceId: $scope.invoiceId  });
        } else {
            $state.transitionTo('loggedin.customer_list.view.job.invoices', {id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId});
        }
    };

    $scope.changeInvoiceAddress = function(){
        if($scope.defaultTaxProvince == 3 && typeof $scope.previousInvoiceAddress != "undefined")
        {
            var oldInvoiceAddress  = _.where($scope.invoiceAddress,{id: parseInt($scope.previousInvoiceAddress)});
            var oldProvinceId      =  oldInvoiceAddress[0]['countyId'];

            var newInvoiceAddress  = _.where($scope.invoiceAddress,{id: parseInt($scope.invoiceAddressId)});
            $scope.newProvinceId      =  newInvoiceAddress[0]['countyId'];

            if($scope.newProvinceId && $scope.newProvinceId != oldProvinceId)
            {
                var modal = document.querySelector('#confirm-change-invoice-address');
                $(modal).modal('show');
            }else if(!$scope.newProvinceId && $scope.newProvinceId != oldProvinceId)
            {
                $scope.acceptNewInvoiceAddress();
            }
        }
        $scope.refreshPaymentDueDate();
    };

    $scope.acceptNewInvoiceAddress = function(){
        $scope.previousInvoiceAddress = $scope.invoiceAddressId;
        $http.get(prefix + '/get_single_province_tax?provinceId=' + $scope.newProvinceId).success(function (data) {
            if($scope.active_option == "no_breakdown"){
              $scope.taxDetails       = data.taxLists;
              $scope.taxItemsDropDown= [];
              setTimeout(function(){
                $scope.taxItemsDropDown = _.groupBy($scope.taxDetails,function (obj) {
                  return obj.taxProvinceId;
                });
                $scope.$digest();
              },100);
              if($scope.newProvinceId)
              {
                  $scope.taxItemId = 1;
                  $scope.calculateNoBreakdownGrandTotals();
              }
            }else{
                $scope.updateStateProvinceTaxRates(data.taxLists,!!$scope.newProvinceId);
            }
            $('#confirm-change-invoice-address').modal('hide');
        });
    };

    $scope.closeInvoiceAddressModal = function(){
        $scope.invoiceAddressId = $scope.previousInvoiceAddress;
        $('#confirm-change-invoice-address').modal('hide');
    };

    $scope.refreshPaymentDueDate = function(){
        var IACreditorDays = _.find($scope.invoiceAddress,function(v){ return v.id == $scope.invoiceAddressId; });
        var invoiceDate = moment.isMoment($scope.selectedDate) ? $scope.selectedDate : moment($scope.selectedDate);
        var dueDate = invoiceDate.clone().add(IACreditorDays.creditordays,'day');
        $scope.$broadcast('event:change_specified_date', {scopeName: 'paymentDueDate', date: dueDate});
        $scope.paymentDueDate = dueDate.toDate();
    };

    $scope.$watch("selectedDate", function(newValue, oldValue) {
        $scope.invoiceDate = $scope.selectedDate;
    });
}

// New invoice ctrl
function NewInvoicesCtrl($scope, $state, $http, prefix, $rootScope, $timeout, $stateParams, getIdData, $filter, $q, confirmationBoxHelper, toastBox, $modal, warningModal, $window, $translate)
{
    if(getIdData.findFinalInvoice ==true){
        var warningId =getIdData.jobDetails.completedreason.toLowerCase();
        if(warningId) {
            var warningMessage = $translate("You.cannot.invoice.this.job.because.this.job.has.been") + warningId;
        }else{
            var warningMessage = $translate("You cannot add invoice because final invoice has been created");
            warningId = "completed";
        }
        warningModal.show(warningMessage, "Invoice job", "invoice_job_"+warningId);
        $state.transitionTo("loggedin.customer_list.view.job.invoices", {'id': $state.params.id, 'type': $state.params.type, 'jobId': $state.params.jobId});
    }
    InvoiceAddEditCtrl.call(this, $scope, $state, $http, prefix, $rootScope, getIdData, $stateParams, warningModal);

    $scope.active_option = getIdData.activeOption;
    $scope.invoiceBreakdown = getIdData.invoiceBreakdown;
    $scope.previousBreakdown = getIdData.invoiceBreakdown;
    $scope.draft_invoice = false;
    $scope.maxInvoiceNumber = getIdData.maxInvoiceNumber;
    $scope.userGroupId = getIdData.defaultUserGroupId;
    $scope.help_text = 'A partial invoice will automatically invoice for diary events that the engineer(s) have completed as well as any parts that have been installed at these diary events.';
    $scope.customerReference = getIdData.customerReference;

    $scope.defaultTaxItem = getIdData.defaultTaxItemId;
    $scope.taxItemId      = getIdData.defaultTaxItemId;
    $scope.defaultVatType = getIdData.defaultVatType;
    $scope.defaultTaxProvince = getIdData.defaultTaxProvince;
    $scope.selectedDate = moment();

    $scope.no_breakdown_cis = 'No';
    $scope.no_breakdown_total_cis = 0;
    $scope.editing_invoice = false;


    // Display the invoice no textbox
    $scope.showInvoiceNoBox = getIdData.showInvoiceNoBox;
    $scope.$emit('displayInvoiceScreen', false);

    $scope.$watch('invoiceNumber', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.value = newVal;
        }
    });

    // While the job from estimate then check the default invoice category.
    if(getIdData.defaultCategoryId) {
        $scope.invoiceCategoryId = getIdData.defaultCategoryId;
    }

    // If the quoted amount is less or greater we need to confirm with modal box.
    $scope.isAcceptQuotedAmount = false;

    if($scope.active_option == 'no_breakdown' && $scope.active_invoice_type == 'final') {
        $scope.no_breakdown_total_price = getIdData.invoiceDetailsArray.invoiceTotal;
        if(getIdData.invoiceDetailsArray.taxItemId) {
            $scope.no_breakdown_vat_rate = getIdData.invoiceDetailsArray.vatRate;
            $scope.taxItemId             = getIdData.invoiceDetailsArray.taxItemId;
        }
    }

    $scope.getBreakdownListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId+'/invoices/2/spreadsheet'+ '/invoicetype/' + $scope.active_invoice_type;
    $scope.getFullBreakdownListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId+'/invoices/3/spreadsheet'+ '/invoicetype/' + $scope.active_invoice_type;
    $scope.getFullBreakdownCategoryListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId+'/invoices/4/spreadsheet'+ '/invoicetype/' + $scope.active_invoice_type;
    $scope.addUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/invoicesold/add/' + $scope.active_invoice_type;

    if($scope.milestoneId) {
        $scope.getBreakdownListUrl = $scope.getBreakdownListUrl + '/milestone/' + $scope.milestoneId;
        $scope.getFullBreakdownListUrl = $scope.getFullBreakdownListUrl + '/milestone/' + $scope.milestoneId;
        $scope.getFullBreakdownCategoryListUrl = $scope.getFullBreakdownCategoryListUrl + '/milestone/' + $scope.milestoneId;
        $scope.addUrl = $scope.addUrl + '/milestone/' + $scope.milestoneId;
    }

    if ($scope.active_option === 'no_breakdown') {
        $scope.saveSpreadsheet = true;
    }
    $scope.spreadsheetPageId = "invoices";
    JobPriceCtrl.call(this, $scope, $state, $http, $rootScope, $timeout, $filter, $q, confirmationBoxHelper, toastBox, $modal, warningModal, $window, prefix);
    customersSpreadsheet.call(this,$scope,prefix,$http,$timeout,$rootScope,$state,confirmationBoxHelper,toastBox);

}

function EditCustomerInvoiceCtrl($scope, $state, $http, prefix, $rootScope, $timeout, $stateParams, getIdData, $filter, $q, confirmationBoxHelper, toastBox, $modal, warningModal, $window)
{
    $scope.invoiceId = getIdData.invoiceId;
    InvoiceAddEditCtrl.call(this, $scope, $state, $http, prefix, $rootScope, getIdData, $stateParams, warningModal);

    $scope.defaultVatType = getIdData.defaultVatType;
    $scope.defaultTaxItem = getIdData.defaultTaxItemId;
    $scope.taxItemId      = (getIdData.invoiceDetailsArray.length != 0) ? getIdData.invoiceDetailsArray.taxItemId : 1;
    $scope.defaultTaxProvince = getIdData.defaultTaxProvince;

    $scope.invoiceDescription = getIdData.invoiceDetailsArray.description;
    $scope.more_details = getIdData.invoiceDetailsArray.moreDetails;
    $scope.invoiceAddressId = getIdData.invoiceDetailsArray.addressId;
    $scope.invoiceAddressRecord = getIdData.invoiceDetailsArray.invoiceAddressRecord;
    $scope.invoiceNo = getIdData.invoiceDetailsArray.invoiceNo;
    $scope.selectedDate = moment(getIdData.invoiceDetailsArray.invoiceDate.date);
    $scope.paymentDueDate = moment(getIdData.invoiceDetailsArray.paymentDueDate.date);
    $scope.active_option = getIdData.invoiceDetailsArray.activeOption;
    $scope.draft_invoice = getIdData.invoiceDetailsArray.isDraft;
    $scope.invoiceCategoryId = getIdData.invoiceDetailsArray.categoryId;
    $scope.invoiceBreakdown = getIdData.invoiceDetailsArray.viewOption;
    $scope.previousBreakdown = getIdData.invoiceDetailsArray.viewOption;
    $scope.accountingPackageStatus = parseInt(getIdData.invoiceDetailsArray.accountingPackageStatus);
    $scope.userGroupId = getIdData.invoiceDetailsArray.userGroupId;
    $scope.help_text = 'A partial invoice will automatically invoice for diary events that the engineer(s) have completed as well as any parts that have been installed at these diary events.';
    $scope.customerReference = getIdData.customerReference;
    $scope.$emit('displayInvoiceScreen', true);

    $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );

    // Condition checked for to display the warning modal box, while click on the invoice save button.
    if($scope.accountingPackageStatus == 2 || $scope.accountingPackageStatus == 4) {
        $scope.displayUnderstandModal = true;
    } else {
        $scope.displayUnderstandModal = false;
    }

    if($scope.active_option == 'no_breakdown') {
        var totalVat = getIdData.invoiceDetailsArray.totalVat;

        $scope.no_breakdown_total_price =  parseFloat(getIdData.invoiceDetailsArray.invoiceTotal);
        $scope.no_breakdown_cis = getIdData.invoiceDetailsArray.noBreakdownCIS;
        $scope.no_breakdown_vat_rate = parseFloat(getIdData.invoiceDetailsArray.noBreakdownVatRate);
        $scope.no_breakdown_nominal_code = getIdData.invoiceDetailsArray.noBreakdownNominalCode;
        $scope.taxItemId = getIdData.invoiceDetailsArray.taxItemId;
        $scope.taxItemsDetails = getIdData.invoiceDetailsArray.exitingTaxItemLists;
        $scope.saveSpreadsheet = true;
    }
    $scope.invoice_details_complete = true;
    $scope.choose_jobs_complete = true;
    $scope.invoice_breakdown_complete = true;
    $scope.editing_invoice = true;

    $scope.allow_invoice_details = true;
    $scope.allow_choose_jobs = true;
    $scope.allow_invoice_breakdown = true;

    $scope.include_uncategorised_discount = true;
    $scope.include_uncategorised_commission = true;

    // No need to check if the quoted amount is less or greater for edit screen for other invoice type.
    if(getIdData.invoiceType == 'final') {
        $scope.isAcceptQuotedAmount = false;
    } else {
        $scope.isAcceptQuotedAmount = true;
    }

    $scope.toggleUncategorisedDiscount = function toggleUncategorisedDiscount() {
        $scope.include_uncategorised_discount = !$scope.include_uncategorised_discount;
        $scope.calculateGrandTotals();
    }
    $scope.toggleUncategorisedCommission = function toggleUncategorisedCommission() {
        $scope.include_uncategorised_commission = !$scope.include_uncategorised_commission;
        $scope.calculateGrandTotals();
    }
    $scope.triggerEditInvoice = function (invoiceId) {
        $http.get(prefix + '/isChangableToDraft?id=' + $scope.invoiceId + '&draft=' + $scope.draft_invoice).success(function(data) {
            if (data.warning === true && data.toCheck === false) {
                warningModal.show(data.message, data.title, data.id);
                $scope.draft_invoice = false;
            }else if(data.warning === true && data.toCheck === true){
                warningModal.show(data.message, data.title, data.id);
                $scope.draft_invoice = true;
            }

        });
    }

    $scope.getBreakdownListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId+'/invoices/' + $scope.invoiceId + '/2/spreadsheet';
    $scope.getFullBreakdownListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId+'/invoices/' + $scope.invoiceId + '/3/spreadsheet';
    $scope.getFullBreakdownCategoryListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId+'/invoices/' + $scope.invoiceId + '/4/spreadsheet';
    $scope.editUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/invoices/' + $scope.invoiceId + '/edit';
    $scope.deleteUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/invoices/' + $scope.invoiceId + '/spreadsheet/delete';
    $scope.invoiceItemCategoryDeleteUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/invoices/' + $scope.invoiceId + '/spreadsheet/invoiceItemCategory/delete';

    if($scope.milestoneId) {
        $scope.getBreakdownListUrl = $scope.getBreakdownListUrl + '/milestone/' + $scope.milestoneId;
        $scope.getFullBreakdownListUrl = $scope.getFullBreakdownListUrl + '/milestone/' + $scope.milestoneId;
        $scope.getFullBreakdownCategoryListUrl = $scope.getFullBreakdownCategoryListUrl + '/milestone/' + $scope.milestoneId;
        $scope.editUrl = $scope.editUrl + '/milestone/' + $scope.milestoneId;
    }
    JobPriceCtrl.call(this, $scope, $state, $http, $rootScope, $timeout, $filter, $q, confirmationBoxHelper, toastBox, $modal, warningModal, $window, prefix);
    // handsontable spreadsheet load
    $scope.spreadsheetPageId = "invoices";
    customersSpreadsheet.call(this,$scope,prefix,$http,$timeout,$rootScope,$state,confirmationBoxHelper,toastBox);
}

function ViewCustomerInvoicePaymentCtrl($scope, $state, prefix, $http, getIdData, creditcards, emailService, $rootScope, warningModal, $translate, $compile, $timeout, $location) {
    $scope.jobId = $state.params.jobId;
    $scope.selectedId = $state.params.id;
    $scope.paymentId = $state.params.paymentId;
    $scope.customerTabName = $state.params.type;
    $scope.hideSubNavCustomers = true;
    if($state.current.name == 'loggedin.invoice_address.view.view_multiple_invoice_payment'){
        $scope.$emit('tabInvoice:selected', getIdData);
    }else{
    $scope.$emit('tabCustomer:selected', getIdData);
    }
    $scope.jobDetails = getIdData.jobDetails;
    $scope.paymentRecord = getIdData.paymentRecord;
    $scope.invoices = getIdData.invoices;
    $scope.prefix = prefix;
    $scope.printAndPostModuleAccess = getIdData.printAndPostModuleAccess;
    $scope.invoiceAddressId = getIdData.invoiceAddressId;
    $scope.audits = getIdData.audits;
    $scope.aiAccountStatus = getIdData.aiAccountStatus;
    $scope.isUsingCardCharges = getIdData.isUsingCardCharges;
    $scope.processingPaymentUsingOurApp = getIdData.processingPaymentUsingOurApp;
    $scope.paymentReceiptMessage = getIdData.paymentReceiptMessage;

    $scope.show_tabs = false;
    $scope.job_invoice = getIdData.invoice;

    $scope.tabRouteCustomer = {
        'customerTabName': $scope.customerTabName,
        'jobSubNavStatic': $scope.jobSubNavStatic,
        'selectedTab': $scope.selectedTab
    };

    if($state.params.jobId){
        $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
        var whichId= $state.params.jobId;
        var whichUrl = '/jobs/';
    }else if ($state.params.contractId){
        $scope.$emit('contractInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
        var whichId= $state.params.contractId;
        var whichUrl = '/customer-contracts/';
    }else if ($state.params.opportunityId){
        $scope.$emit('opportunityInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
        var whichId= $state.params.opportunityId;
        var whichUrl = '/opportunities/';
    }else {
        var whichId= '';
        var whichUrl = '';
    }

    if (getIdData.deleteOrNull == 'Deleted') {
        var message = getIdData.deleteOrNull;
        warningModal.show('customer.payment.deleted', 'Payments');
        if ($state.params.jobId) {
            $state.transitionTo('loggedin.customer_list.view.job.invoices.view.view_invoice', {
                id: $state.params.id,
                type: $state.params.type,
                jobId: $state.params.jobId,
                invoiceId: $state.params.invoiceId
            }, {reload: true});
        } else if ($state.params.contractId) {
            $state.transitionTo("loggedin.customer_list.view.invoice_view.view_invoice_contract", {
                id: $state.params.id,
                type: $state.params.type,
                contractId: $state.params.contractId,
                invoiceId: $state.params.invoiceId
            }, {reload: true});
        } else if ($state.params.opportunityId) {
            $state.transitionTo("loggedin.customer_list.view.opportunity_invoice_view.view_invoice", {
                id: $state.params.id,
                type: $state.params.type,
                opportunityId: $state.params.opportunityId,
                invoiceId: $state.params.invoiceId
            }, {reload: true});
        }
        return;
    }

    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', {
        'jobId': getIdData.jobId,
        'jobType': getIdData.jobDetails.type,
        'invoiceId': $state.params.invoiceId,
        'invoiceNo' : getIdData.invoice.invoice_no
    });
    $scope.paymentMethodId = getIdData.paymentRecord.pamentMethodId;
    $scope.paymentType = getIdData.paymentType;
    $scope.handleCustomerInvoicePaymentEdit = function(){
        if($scope.isUsingCardCharges == true){
            if($scope.paymentType != '') {
                $scope.type = $scope.paymentType;
                $scope.typeId = 'warning_edit_invoice_payment_'+$scope.paymentMethodId;
            }
            var message= $translate('Edit.card.invoice.payment.warning.message') + $scope.type;
            warningModal.show(message, 'Edit.customer.invoice.payment', $scope.typeId);

        }
        else if($scope.paymentRecord.description == "Deferred amount capture"){
            if($scope.paymentType != '') {
                $scope.typeId = 'warning_edit_invoice_payment_' + $scope.paymentMethodId;
            }
            warningModal.show('Edit.customer.invoice.deferred.payment.warning.message', 'Edit.customer.invoice.payment',$scope.typeId);
        }else {
            if(typeof $state.params.jobId !== 'undefined'){
            $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_invoice.view_payment.edit", { id : $state.params.id, type : $state.params.type, jobId : $state.params.jobId, invoiceId:$state.params.invoiceId, paymentId:$state.params.paymentId });
            }else if($state.params.contractId){
                $state.transitionTo("loggedin.customer_list.view.invoice_view.edit_invoice_payment", { id : $state.params.id, type : $state.params.type, contractId : $state.params.contractId, invoiceId:$state.params.invoiceId, paymentId:$state.params.paymentId });
            }else if($state.params.opportunityId){
                $state.transitionTo("loggedin.customer_list.view.opportunity_invoice_view.edit_invoice_payment", { id : $state.params.id, type : $state.params.type, opportunityId : $state.params.opportunityId, invoiceId:$state.params.invoiceId, paymentId:$state.params.paymentId });
            }else if(typeof  $state.params.type !== 'undefined'){
             $state.transitionTo("loggedin.customer_list.view.edit_multiple_invoice_payment",{id : $state.params.id, type : $state.params.type, invoiceId:$state.params.invoiceId, paymentId:$state.params.paymentId })
            }else{
                $state.transitionTo("loggedin.invoice_address.view.edit_multiple_invoice_payment",{id : $state.params.id, invoiceId:$state.params.invoiceId, paymentId:$state.params.paymentId })
            }
        }
    }

    $scope.deletePayment = function () {
        if($scope.aiAccountStatus == 2 || $scope.aiAccountStatus == 4 ) {
            warningModal.show('Delete.customer.invoice.payment.warning.message', 'Delete.customer.invoice.payment', 'warning_delete_credit_note');
        }else if($scope.processingPaymentUsingOurApp){
            var message= $translate('Delete.card.invoice.payment.warning.message');
                message = message.replace('%s',$scope.paymentType);
            var typeId = 'warning_edit_invoice_payment_'+$scope.paymentMethodId;
            warningModal.show(message, 'Delete.customer.invoice.payment', 'warning_delete_credit_note_'+typeId);
        }
        else if($scope.paymentRecord.description == "Deferred amount capture"){
            warningModal.show('Delete.customer.invoice.deferred.payment.warning.message', 'Delete.customer.invoice.payment', 'warning_delete_credit_note');
        }
        else {
            $http.get(prefix + '/check_customer_payment/' + $scope.paymentId).success(function(data) {
                if (data.warning === true) {
                    warningModal.show(data.message, data.title, data.id);
                }  else {
                    $scope.shouldBeOpen = true;
                }
            });
        }

    }

    $scope.redirectToInvoicePage = function(page) {
        if($state.params.jobId){
            $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_invoice", {id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId, invoiceId: $state.params.invoiceId}, {reload:true});
        }else if($state.params.contractId){
            $state.transitionTo("loggedin.customer_list.view.invoice_view.view_invoice_contract", {id: $state.params.id, type: $state.params.type, contractId: $state.params.contractId, invoiceId: $state.params.invoiceId}, {reload:true});
        }else if($state.params.opportunityId){
            $state.transitionTo("loggedin.customer_list.view.opportunity_invoice_view.view_invoice", {id: $state.params.id, type: $state.params.type, opportunityId: $state.params.opportunityId, invoiceId: $state.params.invoiceId}, {reload:true});
        }else if(!$state.params.opportunityId && !$state.params.jobId && !$state.params.contractId && $state.params.type){
            $location.path('/customers/' + $state.params.type + '/' + $state.params.id + '/multiple_invoice/' + $state.params.invoiceId + '/view');
        }else if(!$state.params.opportunityId && !$state.params.jobId && !$state.params.contractId && !$state.params.type){
            $location.path('/invoice_address/' + $state.params.id + '/multiple_invoice/' + $state.params.invoiceId + '/view');
        }
    }

    $scope.$on('event:sentStatus', function(event, data) {
        var id = data.id;
        var tableType = data.type;
        if(tableType == 54) {
            $http.get(prefix + '/getInfoBarDetails?id=' + id + '&type=' + tableType).success(function(data) {
                $scope.audits = data;
            });
        }
    });

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_invoice_address_email?id=' + $scope.invoiceAddressId + '&propertyId=' + $scope.selectedId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=31&id=' + whichId;
        clone_obj.subject = ($scope.paymentReceiptMessage['subject'] != '')?$scope.paymentReceiptMessage['subject']:'';
        clone_obj.message = ($scope.paymentReceiptMessage['message'] != '')?$scope.paymentReceiptMessage['message']:'';
        clone_obj.relatedObjectType = 54;        // For Customer Invoice Payment
        clone_obj.relatedObjectId = $scope.paymentId;
        clone_obj.attached_files = [
            {
                id: "IP|" + $scope.paymentId + "|" + 54,
                file_name: 'Invoice Payment No. ' + $scope.paymentId,
                file_size: 0,
                type: 'generated'
            }
        ];

        $rootScope.$broadcast('email:createNew', clone_obj);
    }
    $scope.showPdf = function showPdf(){
        var side_panel_tpl = $compile('<span id="view_pdf" ' +
            'sidepanel template="print_and_post" ' +
            'supports_print="true"' +
            'supports_print_and_post="'+ $scope.printAndPostModuleAccess +'"' +
            'print_and_post_message="Your payment is on its way to the customer"' +
            'pdf_url="' +prefix +'/customers/' + $state.params.id +whichUrl +whichId +'/invoices/' + $state.params.invoiceId +'/payment/'+ $state.params.paymentId +'/printPayment"' +
            'title="Print and post payment"'+
            'printpost_params="tableType=54&tablePKID='+ $state.params.paymentId+ '&type=print_post"></span>')($scope);

        angular.element('body').append(side_panel_tpl);
        $timeout(function() {
            angular.element('#view_pdf').scope().$$childTail.initialise();
        }, 600);
    }

    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#view_pdf')) {
            angular.element('#view_pdf').remove();
        };
    });
}

function NewCustomerCreditNoteCtrl($scope, $state, $http, prefix, $rootScope, $timeout, $stateParams, getIdData, $filter, $q, confirmationBoxHelper, toastBox, $modal, warningModal, $window)
{
    $scope.screenName = 'credit note';
    $scope.spreadsheetPageId = 'creditNote';
    $scope.invoiceId = getIdData.invoiceId;
    $scope.invoiceDetails = getIdData.invoiceDetails;
    $scope.active_option = 'no_breakdown';
    InvoiceAddEditCtrl.call(this, $scope, $state, $http, prefix, $rootScope, getIdData, $stateParams, warningModal);
    $scope.$emit('tabCustomer:selected', getIdData.customerSubNavDetails);
    $scope.active_option = getIdData.invoiceDetailsArray.activeOption;
    $scope.invoiceCategoryId = getIdData.invoiceDetailsArray.categoryId;
    $scope.invoiceBreakdown = getIdData.invoiceDetailsArray.viewOption;
    $scope.previousBreakdown = getIdData.invoiceDetailsArray.viewOption;

    $scope.defaultVatType = getIdData.defaultVatType;
    $scope.defaultTaxItem = getIdData.defaultTaxItemId;
    $scope.taxItemId      = (getIdData.invoiceDetailsArray.length != 0) ? getIdData.invoiceDetailsArray.taxItemId : 1;
    $scope.defaultTaxProvince = getIdData.defaultTaxProvince;

    $scope.invoice_details_complete = true;
    $scope.choose_jobs_complete = true;
    $scope.invoice_breakdown_complete = true;

    $scope.allow_invoice_details = true;
    $scope.allow_choose_jobs = true;
    $scope.allow_invoice_breakdown = true;

    $scope.include_uncategorised_discount = true;
    $scope.include_uncategorised_commission = true;

    $scope.toggleUncategorisedDiscount = function toggleUncategorisedDiscount() {
        $scope.include_uncategorised_discount = !$scope.include_uncategorised_discount;
        $scope.calculateGrandTotals();
    }
    $scope.toggleUncategorisedCommission = function toggleUncategorisedCommission() {
        $scope.include_uncategorised_commission = !$scope.include_uncategorised_commission;
        $scope.calculateGrandTotals();
    }

    $scope.getBreakdownListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId+'/invoices/' + $scope.invoiceId + '/2/spreadsheet';
    $scope.getFullBreakdownListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId+'/invoices/' + $scope.invoiceId + '/3/spreadsheet';
    $scope.getFullBreakdownCategoryListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId+'/invoices/' + $scope.invoiceId + '/4/spreadsheet';
    $scope.editUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/invoices/' + $scope.invoiceId + '/add_credit_note';

    if($scope.milestoneId) {
        $scope.getBreakdownListUrl = $scope.getBreakdownListUrl + '/milestone/' + $scope.milestoneId;
        $scope.getFullBreakdownListUrl = $scope.getFullBreakdownListUrl + '/milestone/' + $scope.milestoneId;
        $scope.getFullBreakdownCategoryListUrl = $scope.getFullBreakdownCategoryListUrl + '/milestone/' + $scope.milestoneId;
        $scope.editUrl = $scope.editUrl + '/milestone/' + $scope.milestoneId;
    }

    $scope.$emit('displayInvoiceScreen', true);

    if($state.params.jobId){
        $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }else if($state.params.contractId){
        $scope.$emit('contractInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }


    // If credit note amount is greater than invoice total
    $scope.isAcceptCreditNoteAmount = false;
    JobPriceCtrl.call(this, $scope, $state, $http, $rootScope, $timeout, $filter, $q, confirmationBoxHelper, toastBox, $modal, warningModal, $window, prefix);

    $scope.no_breakdown_cis = getIdData.invoiceDetailsArray.noBreakdownCIS;
    $scope.no_breakdown_vat_rate = getIdData.invoiceDetailsArray.noBreakdownVatRate;
    $scope.no_breakdown_nominal_code = getIdData.invoiceDetailsArray.noBreakdownNominalCode;
    $scope.no_breakdown_total_cis = getIdData.invoiceDetailsArray.CISTotal;

    $scope.no_breakdown_total_price = roundFloat(getIdData.invoiceDetailsArray.invoiceTotal,2);

    $scope.invoiceAmount = getIdData.invoiceDetailsArray.grandTotal;
    $scope.calculateGrandTotals();

    $scope.cancelCreditNoteAdd = function() {
        if($state.params.jobId){
            $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_invoice", {id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId, invoiceId: $state.params.invoiceId});
        }else if($state.params.contractId){
            $state.transitionTo("loggedin.customer_list.view.invoice_view.view_invoice_contract", {id: $state.params.id, type: $state.params.type, contractId: $state.params.contractId, invoiceId: $state.params.invoiceId});
        }

    };
    $scope.help_text = 'The breakdown below has come from the original estimate.';
    customersSpreadsheet.call(this,$scope,prefix,$http,$timeout,$rootScope,$state,confirmationBoxHelper,toastBox);

    if($scope.invoiceBreakdown === 1 && $scope.taxItemId === null){
        $scope.isHideVat = true;
        $scope.default_tax_item_id = null;
        $scope.defaultVatType = 'exc_vat';
    }
}

// vat internalization
function RaiseCustomerCreditNoteCtrl($scope, $state, $http, prefix, $rootScope, $timeout, $stateParams, getIdData, $filter, $q, confirmationBoxHelper, toastBox, $modal, warningModal, $window){
    $scope.screenName = 'credit note';
    $scope.spreadsheetPageId = 'creditNote';
    $scope.showCardDetails = false;
    $scope.show_tabs = false;
    $scope.customerSubNavStatic = true;
    $scope.hideCustomerInfo = true;
    $scope.active_option = 'no_breakdown';
    $scope.invoiceBreakdown = 1;
    $scope.previousBreakdown = 1;

    $scope.invoiceCategories = getIdData.invoiceCategories;
    $scope.invoiceItemCategories = getIdData.invoiceItemCategories;
    $scope.invoice_item_categories = getIdData.invoiceItemCategories;

    $scope.customisable_spreadsheet_columns = getIdData.customisableSpreadsheetColumns;
    $scope.informations = getIdData.informations;

    $scope.grand_totals = [];
    $scope.show_vat_in_grand_totals = getIdData.showVatGrantTotal.selected;
    $scope.show_cis_in_grand_totals = true;

    $scope.invoice_has_cis = 'Yes';

    $scope.vatType = getIdData.vatType;
    $scope.defaultVatType = getIdData.defaultVatType;
    $scope.taxDetails = getIdData.taxDetails['taxItems'];
    $scope.taxItemId = getIdData.defaultTaxItemId;
    $scope.vatBreakdownType = $rootScope.taxBreakdownType;
    $scope.isHideVat = getIdData.isHideVat;
    $scope.isHideCIS = getIdData.isHideCIS;
    $scope.noBreakdownNominalCodeArray = getIdData.noBreakdownNominalCodeArray;
    $scope.noBreakdownCISArray = getIdData.noBreakdownCISArray;
    $scope.noBreakdownCISRate = getIdData.noBreakdownCISRate;
    $scope.initialQuoteAmount = getIdData.initialQuoteAmount;
    $scope.retentionQuoteAmount = getIdData.retentionQuoteAmount;

    $scope.taxItemsDropDown = _.groupBy($scope.taxDetails,function (obj) {
        return obj.taxProvinceId;
    });

    var additionalParams = '';
    var stateToRedirect = 'loggedin.customer_list.view.property';
    $scope.isFromInvoiceAddress = false;
    var paramsToRedirect = {id: $state.params.id, type: $state.params.type};
    if($state.current.name === 'loggedin.invoice_address.view.new_invoice_address_credit_note') {
        var stateToRedirect = 'loggedin.invoice_address.view.details';
        var paramsToRedirect = {id: $state.params.id};
        additionalParams = '?mode=invoice_address';
        $scope.customerTabName = 'invoice_address';
        $scope.isFromInvoiceAddress = true;
        $scope.$emit('tabInvoice:selected', getIdData.invoiceAddressValues);
        $scope.$emit('tabRouteInvoice:selected', {'selectedTab': '', 'invoiceSubNavStatic': true });
    } else {
        $scope.tabRouteCustomer = { 'customerTabName': getIdData.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab, customerSubNavStatic:$scope.customerSubNavStatic };
        $scope.$emit('tabCustomer:selected', getIdData.customerSubNavDetails);
        $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    }

    $scope.active_option = 'no_breakdown';
    $scope.invoiceCategoryId = '';

    $scope.getBreakdownListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/2/spreadsheet';
    $scope.getFullBreakdownListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/3/spreadsheet';
    $scope.getFullBreakdownCategoryListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/4/spreadsheet';
    $scope.addUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/add_credit_note' + additionalParams;

    // If credit note amount is greater than invoice total
    $scope.isAcceptCreditNoteAmount = false;
    JobPriceCtrl.call(this, $scope, $state, $http, $rootScope, $timeout, $filter, $q, confirmationBoxHelper, toastBox, $modal, warningModal, $window, prefix);

    $scope.no_breakdown_cis = "No";
    //$scope.no_breakdown_vat_rate = ($scope.noBreakdownVatArray.length) ? $scope.noBreakdownVatArray[0].description : 0;
    $scope.no_breakdown_nominal_code = $scope.firstNominalCode = ($scope.noBreakdownNominalCodeArray.length) ? $scope.noBreakdownNominalCodeArray[0].id:"";
    $scope.total_price = $scope.no_breakdown_total_price = 0;
    $scope.handleSelectedBreakdown($scope.active_option);
    $scope.cancelCreditNoteAdd = function() {
        $state.transitionTo(stateToRedirect, paramsToRedirect);
    }

    customersSpreadsheet.call(this,$scope,prefix,$http,$timeout,$rootScope,$state,confirmationBoxHelper,toastBox);
}

function EditCustomerCreditNoteCtrl($scope, $state, $http, prefix, $rootScope, $timeout, $stateParams, getIdData, $filter, $q, confirmationBoxHelper, toastBox, $modal, warningModal, $window){
    $scope.screenName = 'credit note';
    $scope.spreadsheetPageId = 'creditNote';
    $scope.showCardDetails = false;
    $scope.show_tabs = false;
    $scope.customerSubNavStatic = true;
    $scope.hideCustomerInfo = true;
    $scope.editing_credit_note = true;
    $scope.creditNoteId = $state.params.creditNoteId;

    $scope.vatType = getIdData.vatType;
    $scope.defaultVatType = getIdData.defaultVatType;

    $scope.creditNoteDetail = getIdData.creditNoteDetail;
    $scope.totalAmountAllocatedFromCreditNote = parseFloat((parseFloat($scope.creditNoteDetail.total) - parseFloat($scope.creditNoteDetail.remainingCredit)).toFixed(2));
    $scope.active_option = $scope.creditNoteDetail.breakDownSelected;
    $scope.invoiceBreakdown = $scope.creditNoteDetail.viewoption;
    $scope.previousBreakdown = $scope.creditNoteDetail.viewoption;
    $scope.invoiceDescription = $scope.creditNoteDetail.description;
    $scope.no_breakdown_total_price = $scope.creditNoteDetail.subtotal;
    $scope.invoiceCategoryId = getIdData.creditNoteDetail.invoiceCategoryId;

    $scope.invoiceCategories = getIdData.invoiceCategories;
    $scope.invoiceItemCategories = getIdData.invoiceItemCategories;
    $scope.invoice_item_categories = getIdData.invoiceItemCategories;

    if($scope.creditNoteDetail.columnsToHide) {
        $scope.customisable_spreadsheet_columns = $scope.creditNoteDetail.columnsToHide.columns;
    } else {
        $scope.customisable_spreadsheet_columns = getIdData.customisableSpreadsheetColumns;
    }
    $scope.informations = getIdData.informations;

    $scope.grand_totals = [];
    $scope.show_vat_in_grand_totals = getIdData.showVatGrantTotal.selected;
    $scope.show_cis_in_grand_totals = true;

    $scope.invoice_has_cis = 'Yes';

    $scope.noBreakdownVatArray = getIdData.noBreakdownVatRateArray;
    $scope.isHideVat = getIdData.isHideVat;
    $scope.isHideCIS = getIdData.isHideCIS;
    $scope.noBreakdownNominalCodeArray = getIdData.noBreakdownNominalCodeArray;
    $scope.noBreakdownCISArray = getIdData.noBreakdownCISArray;
    $scope.noBreakdownCISRate = getIdData.noBreakdownCISRate;
    $scope.initialQuoteAmount = getIdData.initialQuoteAmount;
    $scope.retentionQuoteAmount = getIdData.retentionQuoteAmount;

    $scope.taxDetails = getIdData.taxDetails['taxItems'];
    $scope.taxItemId = (getIdData.creditNoteDetail) ? getIdData.creditNoteDetail.taxItemId : null;
    $scope.vatBreakdownType = $rootScope.taxBreakdownType;
    $scope.defaultTaxItem = getIdData.defaultTaxItemId;

    $scope.taxItemsDropDown = _.groupBy($scope.taxDetails,function (obj) {
        return obj.taxProvinceId;
    });

    $scope.tabRouteCustomer = { 'customerTabName': getIdData.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab, customerSubNavStatic:$scope.customerSubNavStatic };
    $scope.$emit('tabCustomer:selected', getIdData.customerSubNavDetails);
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.getBreakdownListUrl = Routing.generate('get_edit_credit_note_spreadsheet', {id:$state.params.id, type:$state.params.type, creditNoteId:$state.params.creditNoteId, invoiceBreakdownType:2});
    $scope.getFullBreakdownListUrl = Routing.generate('get_edit_credit_note_spreadsheet', {id:$state.params.id, type:$state.params.type, creditNoteId:$state.params.creditNoteId, invoiceBreakdownType:3});
    $scope.getFullBreakdownCategoryListUrl = prefix + '/customers/' + $state.params.type + '/' + $scope.selectedId + '/credit_note/'+$state.params.creditNoteId+'/4/spreadsheet';
    $scope.addUrl = prefix + '/customers/' + $state.params.type + '/' + $scope.selectedId + '/credit_note/' + $state.params.creditNoteId + '/save';
    $scope.deleteUrl = prefix + '/customers/' + $state.params.id + '/credit_notes/' + $state.params.creditNoteId + '/delete';

    // If credit note amount is greater than invoice total
    $scope.isAcceptCreditNoteAmount = false;

    JobPriceCtrl.call(this, $scope, $state, $http, $rootScope, $timeout, $filter, $q, confirmationBoxHelper, toastBox, $modal, warningModal, $window, prefix);
    $rootScope.$broadcast('event:changeCustomerCreditNoteScope', {name:'show_tabs', value: false});
    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: $scope.creditNoteDetail.date});
        $scope.selectedDate = $scope.creditNoteDetail.date.date;
    });
    $scope.no_breakdown_cis = $scope.creditNoteDetail.cisrequired;
    $scope.no_breakdown_vat_rate = $scope.creditNoteDetail.vatrate;
    $scope.no_breakdown_nominal_code = $scope.firstNominalCode = $scope.creditNoteDetail.nominalCodeId;
    $scope.total_price = $scope.no_breakdown_total_price = parseFloat($scope.creditNoteDetail.subtotal);

    $scope.handleSelectedBreakdown($scope.active_option);
    $scope.cancelCreditNoteAdd = function() {
        $state.transitionTo("loggedin.customer_list.view.credit_note.details", {id: $state.params.id, type: $state.params.type, creditNoteId: $state.params.creditNoteId});
    }

    $scope.grandTotalToCheck = 0;
    $scope.$watchCollection('[total_price,total_tax,total_cis,no_breakdown_cis,no_breakdown_vat_rate]', function() {
        if($scope.active_option == 'no_breakdown') {
            $scope.grandTotalToCheck = roundFloat($scope.no_breakdown_grand_total,2);
        } else {
            $scope.grandTotalToCheck = roundFloat((parseFloat($scope.total_price) + parseFloat($scope.total_tax) - parseFloat($scope.total_cis)), 2);
        }
    });


    if($scope.active_option == 'no_breakdown') {
        $timeout(function() {
            var totalPriceExc = ($scope.vatType != "inc_vat") ? parseFloat($scope.creditNoteDetail.subtotal) : (parseFloat($scope.creditNoteDetail.subtotal) + parseFloat($scope.creditNoteDetail.tax)) ;
            $scope.total_price = $scope.no_breakdown_total_price = roundFloat(totalPriceExc,2);
            $scope.total_tax = parseFloat($scope.creditNoteDetail.tax);
            $scope.total_cis = parseFloat($scope.creditNoteDetail.cisrate);
            $scope.calculateNoBreakdownGrandTotals();
        }, 100);
    }
    // handsontable spreadsheet load
    customersSpreadsheet.call(this,$scope,prefix,$http,$timeout,$rootScope,$state,confirmationBoxHelper,toastBox);
}

function NewCustomerCashAllocationCtrl($scope, $state, prefix, $http, $rootScope ,$timeout , confirmationBoxHelper,getIdData, creditcards, warningModal, cardExpiryMonths, cardExpiryYears, $interval, canLoad, $location) {
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.customerName = getIdData.customerName;
    $scope.hideSubNavCustomers = true;
    $scope.nominalAccounts = getIdData.nominalAccounts;
    $scope.paymentMethods = getIdData.paymentMethods;
    $scope.cardCharges = getIdData.cardCharges;
    $scope.enableCardIntegration = getIdData.enableCardIntegration;
    $scope.paymentIntegration = getIdData.paymentIntegration;
    $scope.isFromInvoiceAddress = false;
    $scope.shouldBeOpen = false;

    $scope.cardVendorSupportsStoredCards = getIdData.cardVendorSupportsStoredCards;
    $scope.settingCreditCard = getIdData.settingCreditCard;
    $scope.creditCardModule = getIdData.creditCardModule;
    $scope.storedCards = getIdData.storedCards;
    $scope.showStoredCards = false;

    var validCard = _.where($scope.storedCards, {expired: false});
    $scope.storedCardsCount = validCard.length;
    if ($scope.storedCardsCount == 0) {
        $scope.showStoredCards = true;
    }

    $scope.cardExpiryMonths = cardExpiryMonths.getCardExpiryMonths();
    $scope.cardExpiryYears = cardExpiryYears.getCardExpiryYears();

    var additionalParams = '';
    if($state.current.name === 'loggedin.invoice_address.view.cash_allocation') {
        additionalParams = '?mode=invoice_address';
        $scope.isFromInvoiceAddress = true;
        $scope.$emit('tabInvoice:selected', getIdData.invoiceAddressValues);
        $scope.$emit('tabRouteInvoice:selected', {'selectedTab': '', 'invoiceSubNavStatic': true });
    }

    $scope.showCardDetails = false;
    $scope.show_tabs = false;
    $scope.customerSubNavStatic = true;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab, customerSubNavStatic:$scope.customerSubNavStatic };
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.show_invoice_spreadsheet = false;
    $scope.spreadSheetTitle = 'Invoices';
    $scope.allocationHotId = "customerAllocationSpreadsheet";

    $scope.getListUrl = prefix + '/customers/'+$scope.selectedId+'/get_cash_allocation_data/new_customer_cash_allocation_invoices' + additionalParams;
    $scope.saveAllocationUrl = prefix + '/customers/'+$scope.selectedId+'/save_cash_allocation' + additionalParams;
    handleCustomerCashAllocations.call(this, $scope, creditcards, $http, $state, warningModal);
    customerAllocationSpreadsheet.call(this,$scope, prefix, $http, $rootScope, $state, confirmationBoxHelper,$timeout);

    handleCardCharges.call(this, $scope, creditcards)

    HandlePayFortPaymentControl.call(this, $scope, getIdData, $interval, $http, canLoad, $timeout, $location, prefix, $state, true);
}

function handleCustomerCashAllocations($scope, creditcards, $http, $state, warningModal) {
    $scope.submitAllocationData = function(formData, spreadsheetData, additionalParams) {
        var urlToSend = $scope.saveAllocationUrl;
        if(additionalParams) {
            if($scope.saveAllocationUrl.indexOf('?') != -1) {
                urlToSend = $scope.saveAllocationUrl + '&' + additionalParams;
            } else {
                urlToSend = $scope.saveAllocationUrl + '?' + additionalParams;
            }
        }
        $http.post(urlToSend, formData +'&invoices='+spreadsheetData).
        success(function (data, status) {
            $scope.saving = false;
            if (data['status'] == 'failure') {
                $scope.error = data['response']['message'];
                $scope.saving = false;
            }else if(data.error && data.error === true) {
                warningModal.clean('cash_allocation_warning');
                warningModal.show(data.errorMessage, 'Cash.Allocation.Save', 'cash_allocation_warning');
            }else if(data.payment && data.payment.status === 'requires_action'){
                $scope.shouldBeOpen=true;
            } else {
                if($scope.isFromInvoiceAddress) {
                    $state.transitionTo("loggedin.invoice_address.view.details", {id: $state.params.id});
                } else {
                    $state.transitionTo("loggedin.customer_list.view.property", {id: $state.params.id, type: $state.params.type});
                }
            }
        });
    }
    $scope.formSubmit = function (formElem) {
        $scope.submitAllocationData(formElem.serialize(), encodeURIComponent(angular.toJson($scope.spreadSheetInvoice)));
    }
}

function HandleCustomerAllocationSpreadSheet($scope){
    $scope.new_customer_cash_allocation_invoices = [];
    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
    });
    $scope.$on('update:customer:new_cash_allocation:spreadsheetGrandTotals', function() {
        $scope.amount_allocated = 0;
        $scope.remaining_balance = 0;
        $scope.outstanding_payments = 0;

        var grand_totals_str = localStorage.getItem('customer:new_cash_allocation:spreadsheetGrandTotals');
        var grand_totals = JSON.parse(grand_totals_str);
        $scope.show_grand_totals = grand_totals.length > 1;

        for(var i = 0, l = grand_totals.length; i < l; i++) {
            var key = Object.keys(grand_totals[i]);
            $scope.amount_allocated += grand_totals[i][key]["Amount to allocate"];
            $scope.outstanding_payments += grand_totals[i][key]["Remainder to pay"];
        }

        $scope.amount_allocated = parseFloat($scope.amount_allocated.toFixed(2));

            $scope.payment.amount = $scope.amount_allocated;
            $scope.handleBreakdown();

        $scope.outstanding_payments = parseFloat($scope.outstanding_payments.toFixed(2));
    });
}

function EditCustomerInvoicePaymentCtrl($scope, $state, prefix, $http, $rootScope, $timeout ,getIdData, creditcards, warningModal, confirmationBoxHelper) {
    $scope.jobId = $state.params.jobId;
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.hideSubNavCustomers = true;
    if($state.current.name == 'loggedin.invoice_address.view.edit_multiple_invoice_payment'){
        $scope.$emit('tabInvoice:selected', getIdData);
    }else {
    $scope.$emit('tabCustomer:selected', getIdData);
    }
    $scope.nominalAccounts = getIdData.nominalAccounts;
    $scope.paymentMethods = getIdData.paymentMethods;
    $scope.cardCharges = getIdData.cardCharges;
    $scope.enableCardIntegration = getIdData.enableCardIntegration;
    $scope.settingCreditCard = getIdData.settingCreditCard;

    var paymentDetails = $scope.paymentDetails = getIdData.paymentDetails;
    $scope.aiStatus = paymentDetails.sendtoaccounts;
    $scope.description = paymentDetails.description;
    $scope.reference = paymentDetails.reference;
    $scope.nominalAccount = paymentDetails.nominalAccount;
    $scope.pamentMethodId = paymentDetails.pamentMethodId;
    $scope.AIAccountSetup = paymentDetails.AIAccountSetup;

    /*if(paymentDetails.pamentMethodId == 2) {
        $scope.payment = {};
        $scope.addressLine1 = (paymentDetails.addressline1) ? paymentDetails.addressline1 : '';
        $scope.addressLine2 = (paymentDetails.addressline2) ? paymentDetails.addressline2 : '';
        $scope.addressLine3 = (paymentDetails.addressline3) ? paymentDetails.addressline3 : '';
        $scope.town = (paymentDetails.town) ? paymentDetails.town : '';
        $scope.county = (paymentDetails.county) ? paymentDetails.county : '';
        $scope.postcode = (paymentDetails.postcode) ? paymentDetails.postcode : '';
        $scope.nameOnCard = (paymentDetails.nameOnCard) ? paymentDetails.nameOnCard : '';
        $scope.telephone = (paymentDetails.telephone) ? paymentDetails.telephone : '';
        $scope.email = (paymentDetails.email) ? paymentDetails.email : '';
        $scope.expiryDate = (paymentDetails.expiryDate) ? moment(paymentDetails.expiryDate.date).format('YYYY-MM-DD') : '';
        $scope.expiryDateToView = (paymentDetails.expiryDate) ? moment(paymentDetails.expiryDate.date).format('YYYY-MM-DD') : '';
    }*/

    $scope.showCardDetails = false;
    $scope.job_invoice = getIdData.invoice;

    if($state.params.jobId){
        $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }else if ($state.params.contractId){
        $scope.$emit('contractInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', { 'jobId' : getIdData.jobId, 'jobType' : getIdData.jobDetails.type, 'invoiceId' : $state.params.invoiceId, 'invoiceNo' : getIdData.invoice.invoice_no});

    $scope.show_invoice_spreadsheet = false;
    $scope.spreadSheetTitle = 'Invoices';
    $scope.allocationHotId = "customerAllocationSpreadsheet";

    $scope.getListUrl = prefix + '/customers/'+$scope.selectedId+'/get_cash_allocation_data/new_customer_cash_allocation_invoices?paymentId=' + $state.params.paymentId;
    $scope.saveAllocationUrl = prefix + '/customers/'+$scope.selectedId+'/save_cash_allocation';

    handleCustomerCashAllocations.call(this, $scope, creditcards, $http, $state, warningModal);
    HandleCustomerAllocationSpreadSheet.call(this, $scope);
    HandleDeleteRowFromSpreadsheet.call(this, $scope);
    customerAllocationSpreadsheet.call(this,$scope, prefix, $http, $rootScope, $state, confirmationBoxHelper,$timeout);

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: $scope.paymentDetails.date});
        $scope.selectedDate = $scope.paymentDetails.date.date;
        $scope.payment.method = paymentDetails.pamentMethodId;
        if(paymentDetails.pamentMethodId == 2) {
            $scope.payment.number = parseInt(paymentDetails.cardNumber);
            $scope.payment.cvc = (paymentDetails.cvcCode) ? paymentDetails.cvcCode : '';
            $scope.handleCardValidation();
        }
    });

    if($state.params.jobId) {
        var saveUrl = prefix + '/customers/' + $state.params.id + '/jobs/' + $state.params.jobId + '/invoices/' + $state.params.invoiceId + '/payment/' + $state.params.paymentId + '/update';
    }else if($state.params.contractId) {
        var saveUrl = prefix + '/customers/' + $state.params.id + '/customer-contracts/' + $state.params.contractId + '/invoices/' + $state.params.invoiceId + '/payment/' + $state.params.paymentId + '/update';
    }else if($state.params.opportunityId) {
        var saveUrl = prefix + '/customers/' + $state.params.id + '/opportunities/' + $state.params.opportunityId + '/invoices/' + $state.params.invoiceId + '/payment/' + $state.params.paymentId + '/update';
    }else{
        var saveUrl = prefix + '/customers/' + $state.params.id + '/invoices/' + $state.params.invoiceId + '/payment/' + $state.params.paymentId + '/update';
    }

    $scope.formSubmit = function (formElem) {
        var rowsToDelete = ($scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
        $http.post(saveUrl, formElem.serialize()+'&rowsToDelete=' + rowsToDelete + '&invoices='+encodeURIComponent(angular.toJson($scope.spreadSheetInvoice))).
            success(function (data, status) {
                $scope.saving = false;
                if(data.error && data.error === true) {
                    warningModal.clean('cash_allocation_warning');
                    warningModal.show(data.errorMessage, 'Cash.Allocation.Save', 'cash_allocation_warning');
                } else {
                    $scope.newPaymentForm.$setPristine();
                    if($state.params.jobId) {
                          $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_invoice.view_payment", {id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId, invoiceId: $state.params.invoiceId, paymentId: $state.params.paymentId}, {reload:true});
                    }else if($state.params.contractId){
                        $state.transitionTo("loggedin.customer_list.view.invoice_view.view_invoice_payment", {id: $state.params.id, type: $state.params.type, contractId: $state.params.contractId, invoiceId: $state.params.invoiceId, paymentId: $state.params.paymentId}, {reload:true});
                    }else if($state.params.opportunityId){
                        $state.transitionTo("loggedin.customer_list.view.opportunity_invoice_view.view_invoice_payment", {id: $state.params.id, type: $state.params.type, opportunityId: $state.params.opportunityId, invoiceId: $state.params.invoiceId, paymentId: $state.params.paymentId}, {reload:true});
                    }else if(!$state.params.jobId && !$state.params.contractId && $state.params.type){
                        $state.transitionTo("loggedin.customer_list.view.view_multiple_invoice_payment", {id: $state.params.id, type: $state.params.type, invoiceId: $state.params.invoiceId, paymentId: $state.params.paymentId}, {reload:true});
                    }else if(!$state.params.jobId && !$state.params.contractId && !$state.params.type){
                        $state.transitionTo("loggedin.invoice_address.view.view_multiple_invoice_payment", {id: $state.params.id, invoiceId: $state.params.invoiceId, paymentId: $state.params.paymentId}, {reload:true});
                    }
                }
            });
    }
    handleCardCharges.call(this, $scope, creditcards);
}

function handleCardCharges($scope, creditcards) {
    // This will need to check that the client's integrated
    // payment provider supports storing cards. For now it's
    // set to true to demonstrate the stored cards dropdown
    $scope.showCardDropdown = false;
    $scope.showCardForm = false;
    $scope.selectedMethodIsCard = false;
    $scope.isCheckCVC= false;

    $scope.onPaymentMethodChange = function () {
        // This needs to check if the method is card
        // this means that the payment method ID for card
        // needs to be locked so that this check will work
        // For now it's always going to show the card dropdown
        if($scope.enableCardIntegration[this.payment.method] == true && $scope.cardCharges[this.payment.method] != undefined && $scope.creditCardModule && $scope.settingCreditCard){
            $scope.selectedMethodIsCard = true;
            $scope.showCardDetails = true;
            $scope.show_card_charge_breakdown = false;
            $scope.card_charge = 0;
            $scope.cardChargepaymentId = this.payment.method;
            $scope.handleBreakdown();
            $scope.handlePayFortPayment();
        }
        else {
            $scope.selectedMethodIsCard = false;
            $scope.payment.storedCard = '';
            $scope.isCheckCVC= false;
            $scope.payment.cardName = $scope.payment.cardNumber = $scope.payment.cardExpiryMonth = $scope.payment.cardExpiryYear = $scope.payment.cardCvc = $scope.payment.saveCard = $scope.payment.cardAddressLine1 = $scope.payment.cardAddressLine2 = $scope.payment.cardAddressLine3 = $scope.payment.cardTown = $scope.payment.cardCounty =$scope.payment.cardPostcode = '';
            $scope.card_type = '';
            $scope.show_card_charge_breakdown = false;
            $scope.card_charge = 0;
            $scope.cardChargepaymentId = this.payment.method;
            $scope.error = false;
            $scope.newPaymentForm.$setPristine();
            $scope.handleBreakdown();
        }

        $scope.showCardDropdown = false;
        $scope.cardDropdownRequired = false;
        $scope.showCardForm = false;
        $scope.showFormDefault = false;
        if ($scope.selectedMethodIsCard && $scope.cardVendorSupportsStoredCards) {
            $scope.showCardDropdown = true;
            $scope.cardDropdownRequired = true;
            $scope.showCardForm = false;
        }
        if ($scope.selectedMethodIsCard && (!$scope.cardVendorSupportsStoredCards || $scope.storedCards.length == 0 || $scope.showStoredCards)) {
            $scope.showCardForm = true;
            $scope.showFormDefault = true;
            $scope.cardDropdownRequired = false;
        }
    };

    $scope.handlePayFortPayment = function() {
        if($scope.paymentType == 'Payfort') {
            $('#payfort_payment_form input[type=submit]').click();
        }
    }

    $scope.addNewCard = function(){
        $scope.isCheckCVC= false;
        $scope.showCardForm = true;
        $scope.payment.storedCard = '';
        $scope.payment.cardName = $scope.payment.cardNumber = $scope.payment.cardExpiryMonth = $scope.payment.cardExpiryYear = $scope.payment.cardCvc = $scope.payment.saveCard = $scope.payment.cardAddressLine1 = $scope.payment.cardAddressLine2 = $scope.payment.cardAddressLine3 = $scope.payment.cardTown = $scope.payment.cardCounty =$scope.payment.cardPostcode = '';
        $scope.cardDropdownRequired = false;
        $scope.card_type = '';
        $scope.newPaymentForm.$setPristine();
    }
    $scope.onPaymentCardChange = function() {
        var selectedStoredCardId = this.payment.storedCard ? this.payment.storedCard : 0;
            if(selectedStoredCardId ===0){
                $scope.isCheckCVC= false;
            }else{
                var selectedPaymentCard = _.findWhere($scope.storedCards, {
                    id: parseInt(selectedStoredCardId, 10)
                });

                $scope.card_type = selectedPaymentCard.card_type.replace(' ', '').toLowerCase();
                var cardIsInDate = !selectedPaymentCard.expired;
                if(cardIsInDate && selectedPaymentCard) {
                    $scope.showCardForm = false;
                    $scope.cardDropdownRequired = true;
                    $scope.isCheckCVC= true;
                }
                this.newPaymentForm.storedCard.$setValidity('expired', cardIsInDate);
            }
    }

    $scope.show_card_charge_breakdown = false;
    $scope.payment = {
        expiration_month: (!$scope.expiryDateToView) ? '':$scope.expiryDateToView.split(' - ')[1],
        expiration_year: (!$scope.expiryDateToView) ? '':$scope.expiryDateToView.split(' - ')[0],
        cardNumber: (!$scope.payment) ? '':$scope.payment.cardNumber,
        cvc: (!$scope.payment) ? '':$scope.payment.cvc
    }

    $scope.resetValidations = function() {
        $scope.card_number_valid = true;
        $scope.expiry_valid = true;
        $scope.cvc_valid = true;
    }

    $scope.resetValidations();

    $scope.handleCardValidation = function() {
        $scope.card_type = undefined;
        $scope.resetValidations();

        var validation = creditcards.validate({
            'number' : $scope.payment.cardNumber.toString(),
            'expirationMonth' : parseFloat($scope.payment.expiration_month),
            'expirationYear' : parseFloat($scope.payment.expiration_year),
            'cvc' : $scope.payment.cvc.toString()
        })

        if (validation.card.type !== undefined) {
            $scope.card_type = validation.card.type;
        }

    }

    $scope.payment_amount = 0;
    $scope.handleBreakdown = function() {
        var payment_amount = $scope.payment.amount ? parseFloat($scope.payment.amount): parseFloat($scope.amount_allocated);
        var consolidatedInvoiceAmount = ($scope.payment.consolidatedInvoiceAmount) ? parseFloat($scope.payment.consolidatedInvoiceAmount) : 0;
        payment_amount = payment_amount - consolidatedInvoiceAmount;
        $scope.payment_amount = payment_amount;
        if (payment_amount !== undefined && payment_amount > 0 && $scope.cardCharges[this.payment.method] != undefined) {
            var cardTypeValue = $scope.cardChargepaymentId;
            var card_charge = 0;

            if($scope.cardCharges && $scope.cardCharges.hasOwnProperty(cardTypeValue)) {
                card_charge = $scope.cardCharges[cardTypeValue];
                $scope.nominal_AC_id = $scope.paymentIntegration[cardTypeValue];
                $scope.updateNominalAccount($scope.nominal_AC_id);
            }
            $scope.card_percentage = card_charge + '%';
            $scope.card_charge = parseFloat(((payment_amount / 100) * card_charge).toFixed(2));
            $scope.show_card_charge_breakdown = card_charge > 0;
        } else {
            $scope.show_card_charge_breakdown = false;
            if($scope.paymentIntegration) {
                var cardTypeValue = $scope.cardChargepaymentId;
                $scope.nominal_AC_id = $scope.paymentIntegration[cardTypeValue];
                $scope.updateNominalAccount($scope.nominal_AC_id);
            }
        }
    }

    $scope.updateNominalAccount = function(id = 0) {
        if(id){
            var selectedAccount = $scope.nominalAccounts.find(account => account.id == id);
            if (selectedAccount) {
                $scope.nominalAccount = selectedAccount.id;
            }
            else{
                $scope.nominalAccount = '';
            }
        }
        else{
            $scope.nominalAccount = '';
        }
    };
}

function handleJobCardCharges($scope, creditcards, formName) {
    $scope.addNewCard = function () {
        $scope.showCardForm = true;
        $scope.payment.cardName = $scope.payment.cardNumber = $scope.payment.cardExpiryMonth = $scope.payment.cardExpiryYear = $scope.payment.cardCvc = $scope.payment.saveCard = $scope.payment.cardAddressLine1 = $scope.payment.cardAddressLine2 = $scope.payment.cardAddressLine3 = $scope.payment.cardTown = $scope.payment.cardCounty = $scope.payment.cardPostcode = "";
        $scope.cardDropdownRequired = false;
        $scope.card_type = '';
        $scope.error = false;
        $scope[formName].$setPristine();
        $scope.payment.storedCard = '';
        if($scope.cardId == 4 || $scope.cardId == 3 ||  $scope.cardId == 5 ) {
            $scope.isDeferred = true;
            $scope.isRequired = false;
        }
    }

    $scope.hideNewCard = function () {
        $scope.showCardForm = false;
        if($scope.cardId == 4 || $scope.cardId == 3 || $scope.cardId == 5) {
            $scope.isDeferred = false;
            $scope.isRequired = true;
        }
    }
    $scope.handleCopyLink = function() {
        $scope.payment.cardName = $scope.name;
        $scope.payment.cardAddressLine1 = $scope.addressline1;
        $scope.payment.cardAddressLine2 = $scope.addressline2;
        $scope.payment.cardAddressLine3 = $scope.addressline2;
        $scope.payment.cardTown = $scope.town;
        $scope.payment.cardCounty = $scope.county;
        $scope.payment.cardPostcode = $scope.postcode;
    }
    $scope.$watchCollection('[payment.cardName,payment.cardNumber,payment.cardExpiryMonth,payment.cardExpiryYear,payment.cardCvc]', function(newValue,oldvalue)
    {

        var creditcardfields=[$scope.payment.cardName , $scope.payment.cardNumber , $scope.payment.cardExpiryMonth , $scope.payment.cardExpiryYear ,$scope.payment.cardCvc];
        var  initialcreditcardfields = creditcardfields.filter(Boolean);
        if($scope.jobexpiryCard && $scope.cardId == 3 && initialcreditcardfields.length != 5)
        {
            $scope[formName].$invalid = true;
        }else if(initialcreditcardfields.length == 0 || initialcreditcardfields.length == 5)
        {
            $scope.validation = false;
            $scope[formName].$invalid = false;
        }else
        {
            $scope.validation = true;
            $scope[formName].$invalid = true;
        }
    });
    $scope.onPaymentCardChange = function () {
        var selectedStoredCardId = this.payment.storedCard ? this.payment.storedCard : 0;
        if (selectedStoredCardId != 0) {
            var selectedPaymentCard = _.findWhere($scope.storedCards, {
                id: parseInt(selectedStoredCardId, 10)
            });
            $scope.card_type = selectedPaymentCard.card_type.replace(' ', '').toLowerCase();

            var cardIsInDate = !selectedPaymentCard.expired;
            if (cardIsInDate && selectedPaymentCard) {
                $scope.cardDropdownRequired = true;
            }
            this[formName].storedCard.$setValidity('expired', cardIsInDate);

            if($scope.cardId == 4 || $scope.cardId == 3 || $scope.cardId == 5) {
                $scope.showCardForm = false;
                $scope.payment.cardName = $scope.payment.cardNumber = $scope.payment.cardExpiryMonth = $scope.payment.cardExpiryYear = $scope.payment.cardCvc = $scope.payment.saveCard = $scope.payment.cardAddressLine1 = $scope.payment.cardAddressLine2 = $scope.payment.cardAddressLine3 = $scope.payment.cardTown = $scope.payment.cardCounty = $scope.payment.cardPostcode = "";
                $scope.isDeferred = false;
                $scope.isRequired = true;
            }
        }
    }

    $scope.payment = {
        expiration_month: (!$scope.expiryDateToView) ? '' : $scope.expiryDateToView.split(' - ')[1],
        expiration_year: (!$scope.expiryDateToView) ? '' : $scope.expiryDateToView.split(' - ')[0],
        cardNumber: (!$scope.payment) ? '' : $scope.payment.cardNumber,
        cardCvc: (!$scope.payment) ? '' : $scope.payment.cvc

    }
    $scope.resetValidations = function () {
        $scope.card_number_valid = true;
        $scope.expiry_valid = true;
        $scope.cvc_valid = true;
    }

    $scope.resetValidations();

    $scope.handleCardValidation = function () {
        $scope.card_type = undefined;
        $scope.resetValidations();
        var validation = creditcards.validate({
            'number': $scope.payment.cardNumber,
            'expirationMonth': parseFloat($scope.payment.expiration_month),
            'expirationYear': parseFloat($scope.payment.expiration_year),
            'cvc': $scope.payment.cardCvc
        })
        if (validation.card.type !== undefined) {
            $scope.card_type = validation.card.type;
        }
    }
}

function NewCustomerInvoicePaymentCtrl($rootScope, $scope, $state, prefix, $http, getIdData, creditcards, formPanelCollection, cardExpiryMonths, cardExpiryYears, warningModal, $q, canLoad, $location, $timeout, $interval) {
    $scope.jobId = $state.params.jobId;
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.hideSubNavCustomers = true;
    if($state.current.name == 'loggedin.invoice_address.view.multiple_invoice_payment'){
        $scope.$emit('tabInvoice:selected', getIdData);
    }else{
    $scope.$emit('tabCustomer:selected', getIdData);
    }
    $scope.shouldBeOpen = false;

    $scope.nominalAccounts = getIdData.nominalAccounts;
    $scope.paymentMethods = getIdData.paymentMethods;
    $scope.cardCharges = getIdData.cardCharges;
    $scope.enableCardIntegration = getIdData.enableCardIntegration;
    $scope.paymentIntegration = getIdData.paymentIntegration;
    $scope.cardVendorSupportsStoredCards = getIdData.cardVendorSupportsStoredCards;
    $scope.settingCreditCard = getIdData.settingCreditCard;
    $scope.creditCardModule = getIdData.creditCardModule;
    $scope.storedCards = getIdData.storedCards;
    $scope.showStoredCards = false;
    $scope.paymentType=getIdData.paymentType;
    var validCard = _.where($scope.storedCards, {expired: false});
    $scope.storedCardsCount = validCard.length;
    if ($scope.storedCardsCount == 0) {
        $scope.showStoredCards = true;
    }

    $scope.cardExpiryMonths = cardExpiryMonths.getCardExpiryMonths();
    $scope.cardExpiryYears = cardExpiryYears.getCardExpiryYears();

    $scope.showCardDetails = false;
    $scope.job_invoice = getIdData.invoice;
    $scope.remainingAmount = parseFloat($scope.job_invoice.grand_total) - parseFloat($scope.job_invoice.paidAmount);

    $scope.remainingToPay = parseFloat($scope.remainingAmount.toFixed(2));

    $scope.$emit('jobId:selected', { 'jobId' : getIdData.jobId, 'jobType' : getIdData.jobDetails.type, 'invoiceId' : getIdData.invoice.invoice_no, 'draft' : getIdData.invoice.draft});

    if($state.params.jobId){
        $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }else if($state.params.contractId){
        $scope.$emit('contractInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }else if($state.params.opportunityId){
        $scope.$emit('opportunityInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }

    if($state.params.jobId) {
        var addUrl = prefix + '/customers/' + $state.params.id + '/jobs/' + $state.params.jobId + '/invoices/' + $state.params.invoiceId + '/payment/add';
    }else if($state.params.contractId){
        var addUrl = prefix + '/customers/' + $state.params.id + '/customer-contracts/' + $state.params.contractId + '/invoices/' + $state.params.invoiceId + '/payment/add';
    }else if($state.params.opportunityId){
        var addUrl = prefix + '/customers/' + $state.params.id + '/opportunities/' + $state.params.opportunityId + '/invoices/' + $state.params.invoiceId + '/payment/add';
    }else{
        var addUrl = prefix + '/customers/' + $state.params.id + '/invoices/' + $state.params.invoiceId + '/payment/add';
    }
    $scope.savePaymentDetails = function(formData, additionalParams) {
        var urlToSend = addUrl;
        if(additionalParams) {
            urlToSend = addUrl + '?' + additionalParams;
        }
        $scope.error = false;
        $scope.isDisable = false;
        $http.post(urlToSend, formData).
        success(function (data, status) {
            if (data['status'] == 'failure') {
                $scope.error = (data['response']['message'])?data['response']['message']:data['response'];
                $scope.saving = false;
            }else if(data.payment && data.payment.status== 'Pre-authorized'){
                var payment = data.payment;
                if(payment.status === 'Pre-authorized') {
                    $scope.propertyEmail = data.emailAddresses;
                    $scope.proproeprtySms = data.smsNumber;
                    $scope.saving = false;
                    $scope.invoiceId = $state.params.invoiceId;
                    $scope.shouldBeOpen = true;
                }else if(payment.status === 'failure') {
                    scope.saving = false;
                    $scope.error = 'Require payment method for initialing payment';
                }
            }
            else {
                if(data['deferredError'] == true) {
                    var errorMsg = "Authorization.cancellation.failed";
                    warningModal.show(errorMsg, 'Invoices');
                }
                var formData = localStorage.getItem('newPaymentForm');
                if(formData) {
                    localStorage.removeItem('newPaymentForm');
                }
                $scope.newPaymentForm.$setPristine();
                $scope.isDisable = true;
                if($state.params.jobId){
                    $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_invoice", {id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId, invoiceId: $state.params.invoiceId}, {reload:true});
                }else if($state.params.contractId){
                    $state.transitionTo("loggedin.customer_list.view.invoice_view.view_invoice_contract", {id: $state.params.id, type: $state.params.type, contractId: $state.params.contractId, invoiceId: $state.params.invoiceId}, {reload:true});
                }else if($state.params.opportunityId){
                    $state.transitionTo("loggedin.customer_list.view.opportunity_invoice_view.view_invoice", {id: $state.params.id, type: $state.params.type, opportunityId: $state.params.opportunityId, invoiceId: $state.params.invoiceId}, {reload:true});
                }else{
                    if($state.params.type == 'customer') {
                        $location.path('/customers/' + $state.params.type + '/' + $state.params.id + '/multiple_invoice/' + $state.params.invoiceId + '/view');
                    }else{
                        $location.path('/invoice_address/' + $state.params.id + '/multiple_invoice/' + $state.params.invoiceId + '/view');
                    }
                }
                $scope.saving = false;
            }
        });
    }

    $scope.formSubmit = function (formElem) {
        if(getIdData.paymentType.toLowerCase() === 'stripe' &&  $scope.selectedMethodIsCard=== true) {
          HandleStripePaymentControl.call(this, $rootScope, $scope, getIdData, $interval, $http, canLoad, $timeout, $location, prefix, $state, formElem, addUrl, warningModal);
          $scope.makeScripePayment();
        }
        else {
          $scope.savePaymentDetails(formElem.serialize());
        }
    };
    handleCardCharges.call(this, $scope, creditcards);
    HandlePayFortPaymentControl.call(this, $scope, getIdData, $interval, $http, canLoad, $timeout, $location, prefix, $state, false);

    $scope.redirectLink = function (){
        if($state.params.jobId){
            $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_invoice", {id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId, invoiceId: $state.params.invoiceId}, {reload:true});
        }else if($state.params.contractId){
            $state.transitionTo("loggedin.customer_list.view.invoice_view.view_invoice_contract", {id: $state.params.id, type: $state.params.type, contractId: $state.params.contractId, invoiceId: $state.params.invoiceId}, {reload:true});
        }else if($state.params.opportunityId){
            $state.transitionTo("loggedin.customer_list.view.opportunity_invoice_view.view_invoice", {id: $state.params.id, type: $state.params.type, opportunityId: $state.params.opportunityId, invoiceId: $state.params.invoiceId}, {reload:true});
        }else{
            if($state.params.type == 'customer') {
                $location.path('/customers/' + $state.params.type + '/' + $state.params.id + '/multiple_invoice/' + $state.params.invoiceId + '/view');
            }else{
                $location.path('/invoice_address/' + $state.params.id + '/multiple_invoice/' + $state.params.invoiceId + '/view');
            }
        }
    }
}

function HandleStripePaymentControl($rootScope, $scope, getIdData, $interval, $http, canLoad, $timeout, $location, prefix, $state, formElem, addUrl, warningModal) {
  $scope.publicKey = atob(getIdData.pk_token);
  $scope.paymentMethodId = null;
  $scope.error = false;
  $scope.isDisable = false;
  var confirmUrl = prefix + '/customers/' + $state.params.id + '/jobs/' + $state.params.jobId + '/invoices/' + $state.params.invoiceId + '/payment/confirmation';
  var createPaymentMethodUrl = prefix + '/customers/' + $state.params.id + '/jobs/' + $state.params.jobId + '/invoices/' + $state.params.invoiceId + '/payment/create_payment_method';
  var urlToSend = addUrl;
  var formDataObj = formDatatoJson(formElem);
  var formData = formElem.serialize();

  //first level assertion
  if(_.has($rootScope.loadedScript, 'stripeJs')) {
    $rootScope.loadedScript.stripeJs.then(function(isLoaded){
      if( !isLoaded && _.isEmpty($scope.publicKey) ) {
        throw "StripeJs or Public key not found....";
      }
    });
  }
  $scope.stripe = Stripe($scope.publicKey);

  function stripeHandleServerResponse (data) {
    if(data.status && data.status==='failure') {
      $scope.error = (data.message) ? data.message : data.response;
      $scope.saving = false;
    }
    else if(data.status && data.status === 'success' && _.has(data,'payment')) {
      var payment = data.payment;
      if(payment.status === 'requires_action') {
          $scope.propertyEmail = data.emailAddresses;
          $scope.proproeprtySms=data.smsNumber;
          $scope.saving = false;
          $scope.invoiceId=$state.params.invoiceId;
          $scope.shouldBeOpen = true;
        /*$scope.stripe.handleCardAction(payment.payment_intent_client_secret)
          .then(function(result){
            //console.log('result', result);
            if( result.error ) {
              $scope.saving = false;
              $scope.error = result.error.message;
            }
            else {
              fetch(confirmUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  payment_intent_id: result.paymentIntent.id,
                  data: data.data,
                  invoiceAddressId: data.invoiceAddressId,
                  invoices: data.invoices,
                  paymentId: data.paymentId,
                  deleteParams: data.deleteParams
                })
              }).then(function(confirmResult){
                return confirmResult.json();
              }).then(stripeHandleServerResponse)
            }
          });*/
      }
      else if(payment.status === 'failure') {
        scope.saving = false;
        $scope.error = 'Require payment method for initialing payment';
      }
      else {
        nextActionAfterPayment(data);
      }
    }
    else {
      nextActionAfterPayment(data);
    }
  }

  $scope.makeScripePayment = function(additionalParams) {
    if( (!_.has(formDataObj, 'saveCard') && !_.has(formDataObj, 'storedCard') || (_.has(formDataObj, 'storedCard') && formDataObj.storedCard === '' && !_.has(formDataObj, 'saveCard') ))) {
      $scope.createPaymentMethod(additionalParams);
    }
    else {
      $scope.makeScripePaymentIntent(additionalParams);
    }
  };

  $scope.createPaymentMethod = function() {
    $http.post( createPaymentMethodUrl, formElem.serialize() ).success(function(data){
      if (data.status === 'failure') {
        $scope.error = (data.response.message) ? data.response.message : data.response;
        $scope.saving = false;
      }
      if (data.status === 'success')  {
        var q = '&pm=' + btoa(data.response.id);
        $scope.makeScripePaymentIntent(q);
      }
    });
  };

  $scope.makeScripePaymentIntent = function(additionalParams) {
    if(additionalParams) {
      //urlToSend = addUrl + '?' + additionalParams;
      formData = formData + additionalParams
    }
    $http.post(urlToSend, formData).success(function(data, status){
      // var payment = _.has(data, 'payment') ? data.payment : {};
      if (data.status === 'failure') {
        $scope.error = (data.response.message) ? data.response.message : data.response;
        $scope.saving = false;
      }
      else {
        stripeHandleServerResponse(data);
      }
    });
  };

  function nextActionAfterPayment(data) {
      $scope.newPaymentForm.$setPristine();
      $scope.isDisable = true;
      if($state.params.jobId){
          $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_invoice", {id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId, invoiceId: $state.params.invoiceId}, {reload:true});
      }else if($state.params.contractId){
          $state.transitionTo("loggedin.customer_list.view.invoice_view.view_invoice_contract", {id: $state.params.id, type: $state.params.type, contractId: $state.params.contractId, invoiceId: $state.params.invoiceId}, {reload:true});
      }
      $scope.saving = false;
  }

  function formDatatoJson(data) {
    var serializeArray = $(data).serializeArray(), dataObj={};
    _.forEach(serializeArray, function(x){
      dataObj[x.name] = x.value;
    });
    return dataObj;
  }
}

function HandlePayFortPaymentControl($scope, getIdData, $interval, $http, canLoad, $timeout, $location, prefix, $state, isFromAllocation) {
    $scope.paymentType = getIdData.paymentType;
    $scope.vendorDetails = getIdData.vendorDetails;

    $scope.localStorageStarted = false;
    var saveFormValuesToLocalStorage = function () {
        if ($scope.paymentType == 'Payfort') {
            var description = $scope.description ? $scope.description : '';
            var reference = $scope.reference ? $scope.reference : '';
            var newPaymentForm = angular.element('#newPaymentForm').serialize();
            localStorage.setItem('newPaymentForm', newPaymentForm);
            localStorage.setItem('payment_description', description);
            localStorage.setItem('payment_reference', reference);
            localStorage.setItem('newPaymentForm', newPaymentForm);
            localStorage.setItem('payFortRedirect', window.location.href.split('?')[0]);
            if(isFromAllocation) {
                localStorage.setItem('spreadSheetInvoice', encodeURIComponent(angular.toJson($scope.spreadSheetInvoice)));
            }
        }
    }

    $scope.updateLocalStorage = function () {
        if ($scope.paymentType == 'Payfort') {
            if (!$scope.localStorageStarted) {
                $scope.localStorageStarted = true;
                $interval(saveFormValuesToLocalStorage, 1000);
                $scope.showPayFortError = false;
                $scope.showCardForm = false;
            }
            $scope.updateIFrameData();
        }
    }

    if(isFromAllocation) {
        $scope.$watch('amount_allocated', function(oldVal, newVal) {
            if(newVal) {
                $scope.updateIFrameData();
            }
        });
    }

    $scope.iFrameLoaded = false;
    var payFortCanceler = null;
    $scope.updateIFrameData = function () {
        var customerType = $state.current.name == "loggedin.invoice_address.view.cash_allocation" ? "invoice_address" : "property";
        var allocatedAmount = typeof $scope.amount_allocated != 'undefined' ? $scope.amount_allocated : 0;
        var totalAmount;
        if(isFromAllocation) {
            totalAmount = allocatedAmount;
        } else {
            totalAmount = $scope.payment_amount;
        }
        if ($scope.card_charge) {
            totalAmount += $scope.card_charge;
        }

        totalAmount = totalAmount.toFixed(2);

        canLoad.setLoadValue(false);
        if (payFortCanceler) {
            payFortCanceler.resolve();
        }
        payFortCanceler = $q.defer();
    }

    $scope.setFormValues = function (formData) {
        $timeout(function () {
            try {
                $scope.$apply(function () {
                    $.each(formData.split('&'), function (index, elem) {
                        var inputData = elem.split('=');
                        switch (inputData[0]) {
                            case 'paymentMethod': {
                                //$scope.payment.method = inputData[1];
                            }
                            case 'amount': {
                                $scope.payment.amount = inputData[1];
                            }
                            case 'description': {
                                $scope.description = localStorage.getItem('payment_description');
                            }
                            case 'reference': {
                                $scope.reference = localStorage.getItem('payment_reference');
                            }
                            default: {
                                if (inputData && inputData.length) {
                                    $scope[inputData[0]] = inputData[1] ? inputData[1] : '';
                                }
                            }
                        }
                    });
                });
            } catch (e) {
                console.log(e);
            }

        }, 500);
    }

    var urlParams = $location.search();
    $scope.showPayFortError = false;
    $scope.payFortErrorMessage = '';
    if (urlParams.hasOwnProperty('status')) {
        var formData = localStorage.getItem('newPaymentForm');
        var status = urlParams.status;
        if (formData) {
            if (status == '02') {
                $scope.saving = true;
                $scope.setFormValues(formData);

                if(isFromAllocation) {
                    $scope.submitAllocationData(formData, localStorage.getItem('spreadSheetInvoice'), window.location.href.split('?')[1]);
                } else {
                    $scope.savePaymentDetails(formData, window.location.href.split('?')[1]);
                }

            } else if (status == 'failed') {
                $scope.showPayFortError = true;
                $scope.payFortErrorMessage = urlParams.reason;
                $scope.setFormValues(formData);
                $scope.updateIFrameData();
            }
        } else {
            window.location.href = window.location.href.split('?')[0];
        }

        localStorage.removeItem('newPaymentForm');
    }

}
function JobInvoiceCommunicationsCtrl($scope, $state, prefix, getIdData, $rootScope, dateRange, emailService) {
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.invoiceId = $state.params.invoiceId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.hideSubNavCustomers = true;
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.searchText = '';
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.contacts = getIdData.contacts;
    $scope.prefix = prefix;
    $scope.job = getIdData.jobDetails;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.invoiceSelectedTab = 'Notes';

    if($state.params.jobId){
        $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : true} );
        var ulrMode='/jobs/';
        var whichId = $state.params.jobId;
    }else if($state.params.contractId){
        $scope.$emit('contractInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : true} );
        var ulrMode='/customer-contracts/';
        var whichId = $state.params.contractId;
    }else if($state.params.opportunityId){
        $scope.$emit('opportunityInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : true} );
        var ulrMode='/opportunities/';
        var whichId = $state.params.opportunityId;
    }
    $scope.$emit('jobId:selected', { 'jobId' : getIdData.jobId, 'jobType' : getIdData.jobDetails.type, 'invoiceNo' : getIdData.invoice.invoiceno, 'draft' : getIdData.invoice.isdraft, 'invoiceId' : $scope.invoiceId});

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.selectedId + '&type=all_email';
        clone_obj.attachments_url = '/get_attached_files_list?tableType=31&id=' + whichId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 128;        // For Job invoice
        clone_obj.relatedObjectId = $scope.invoiceId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }
    $scope.action = "";
    
    $scope.handleActionFilter = function(action) {
      if( $scope.action == action ) {
          $scope.action = "";
      } else {
          $scope.action = action;
      }
      
      $scope.$broadcast('event:communicationNotesFilter',{'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action});
    }

    $scope.$on("event:communicationNotesFilter", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.action = '';
        if( message.action ) {
            $scope.action = message.action;
        }
        var filters = {'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action};

        $scope.$broadcast('filterValue', filters);
    });

    var baseRoute = prefix + '/customers/' + $state.params.id + ulrMode + whichId + '/invoices/' + $state.params.invoiceId;
    handlePrintAndDownloadOptions.call(this, $scope, baseRoute);
    handleNotesAndCommunicationFilter.call(this, $scope);
}

function JobInvoiceNotesCtrl($scope, $state, $http, prefix, getIdData, $rootScope, defaultPagingValue, canLoad, $q, $sce, emailService) {
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.invoiceId = $state.params.invoiceId;
    $scope.customerId = getIdData.navBarDetails.id;
    $scope.invoiceSelectedTab = 'Notes';
    $scope.hideSubNavCustomers = true;
    $scope.hideSubNavCustomerName = true;
    $scope.jobSubNavStatic = true;
    $scope.$emit('displayInvoiceScreen', true);

    $scope.jobInvoiceSubNav = true;
    $scope.show_tabs = true;
    if($state.params.jobId){
        $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : $scope.jobInvoiceSubNav, 'show_tabs' : $scope.show_tabs});
        var whichId= $state.params.jobId;
        var modeChange='/jobs/';
    }else if($state.params.contractId){
        $scope.$emit('contractInvoiceTab:selected', { 'jobInvoiceSubNav' : $scope.jobInvoiceSubNav, 'show_tabs' : $scope.show_tabs});
        var whichId= $state.params.contractId;
        var modeChange= '/customer-contracts/';

    }else if($state.params.opportunityId){
        $scope.$emit('opportunityInvoiceTab:selected', { 'jobInvoiceSubNav' : $scope.jobInvoiceSubNav, 'show_tabs' : $scope.show_tabs});
        var whichId= $state.params.opportunityId;
        var modeChange= '/opportunities/';
    }


    $scope.$emit('jobId:selected', { 'jobId' : $scope.jobId, 'jobType' : getIdData.jobDetails.type, 'invoiceNo' : getIdData.invoice.invoiceno, 'draft' : getIdData.invoice.isdraft, 'invoiceId' : $scope.invoiceId});
    $scope.shouldBeOpen = false;
    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.printAndPostModule = getIdData.printAndPostModule;
    $scope.contacts = getIdData.contacts;

    var scope = {name: 'notesCount', value: getIdData.notesDetails.notesCount};
    $rootScope.$emit('event:changeJobInvoiceScope', scope);

    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;
    $scope.shouldBeOpen = false;
    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.notes = getIdData.notesDetails.notesDetails;
        $scope.notesCount = getIdData.notesDetails.notesCount;
        $scope.notesCountMain = getIdData.notesDetails.notesCountMain;
        $scope.smsMessages = getIdData.smsMessages;
        $scope.smsMessagesCount = getIdData.smsMessagesCount;
        $scope.phoneCalls = getIdData.phoneCalls;
        $scope.phoneCallsCount = getIdData.phoneCallsCount;
        $scope.emailMessages = getIdData.emailMessages;
        $scope.emailMessagesCount = getIdData.emailMessagesCount;
        $scope.printLetters = getIdData.printLetters;
        $scope.printLettersCount = getIdData.printLettersCount;
    }

    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("Jobinvoicenotesandcommunications", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });
    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.recordId = $scope.selectedId;
            $scope.pageName = 'Job Notes List';
            $scope.excelRouteName = 'downloadnotes';
            var download = prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.recordId = $scope.selectedId;
            $scope.pageName = 'Job Notes List';
            $scope.pdfRouteName = 'printnotes';
            var print = prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }

    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    if ($state.current.data.limitPhoneCalls) {
        $scope.limitPhoneCalls = parseInt($state.current.data.limitPhoneCalls);
    } else {
        $scope.limitPhoneCalls = defaultPagingValue;
    }

    if ($state.current.data.pageNumPhoneCalls) {
        $scope.currentPagePhoneCalls = parseInt($state.current.data.pageNumPhoneCalls);
    } else {
        $scope.currentPagePhoneCalls = 1;
    }

    if ($state.current.data.limitSmsMessages) {
        $scope.limitSmsMessages = parseInt($state.current.data.limitSmsMessages);
    } else {
        $scope.limitSmsMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumSmsMessages) {
        $scope.currentPageSmsMessages = parseInt($state.current.data.pageNumSmsMessages);
    } else {
        $scope.currentPageSmsMessages = 1;
    }

    if ($state.current.data.limitEmailMessages) {
        $scope.limitEmailMessages = parseInt($state.current.data.limitEmailMessages);
    } else {
        $scope.limitEmailMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumEmailMessages) {
        $scope.currentPageEmailMessages = parseInt($state.current.data.pageNumEmailMessages);
    } else {
        $scope.currentPageEmailMessages = 1;
    }

    if ($state.current.data.limitPrintLetters) {
        $scope.limitPrintLetters = parseInt($state.current.data.limitPrintLetters);
    } else {
        $scope.limitPrintLetters = defaultPagingValue;
    }

    if ($state.current.data.pageNumPrintLetters) {
        $scope.currentpagePrintLetters = parseInt($state.current.data.pageNumPrintLetters);
    } else {
        $scope.currentpagePrintLetters = 1;
    }

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchEmailMessages($scope.currentPageEmailMessages);
    });

    // Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.customerId +'&type=all_email';
        clone_obj.attachments_url = '/get_attached_files_list?tableType=31&id=' + whichId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 128;        // For Job invoice
        clone_obj.relatedObjectId = $scope.invoiceId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.searchCommunications(1);
    });

    var canceler = null;
    $scope.fetchNotes = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = (typeof $scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $state.params.id + modeChange + whichId + '/invoices/' + $scope.invoiceId + '/listNotes?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.notes = data.notesDetails;
            $scope.notesCount = data.notesCount;
            $scope.notesCountMain = data.notesCountMain;
            $scope.$parent.$parent['notesCount'] = data.notesCount;
            if (searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var phoneCallCanceler = null;
    $scope.fetchPhoneCalls = function (pageNumPhoneCalls) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (phoneCallCanceler) {
            phoneCallCanceler.resolve();
        }
        phoneCallCanceler = $q.defer();
        var searchText = (typeof $scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $state.params.id + modeChange + whichId + '/invoices/' + $scope.invoiceId + '/listPhoneCalls?pagePhoneCalls=' + pageNumPhoneCalls + '&limitPhoneCalls=' + $scope.limitPhoneCalls + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: phoneCallCanceler.promise}).success(function (data) {
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            if (searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var smsCanceler = null;
    $scope.fetchSmsMessages = function (pageNumSmsMessages) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (smsCanceler) {
            smsCanceler.resolve();
        }
        smsCanceler = $q.defer();
        var searchText = (typeof $scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $state.params.id + modeChange + whichId + '/invoices/' + $scope.invoiceId + '/listSms?pageSmsMessages=' + pageNumSmsMessages + '&limitSmsMessages=' + $scope.limitSmsMessages + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: smsCanceler.promise}).success(function (data) {
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            if (searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var emailCanceller = null;
    $scope.fetchEmailMessages = function (pageNumEmailMessages) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (emailCanceller) {
            emailCanceller.resolve();
        }
        emailCanceller = $q.defer();
        var searchText = (typeof $scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $state.params.id + modeChange + whichId + '/invoices/' + $scope.invoiceId + '/listEmail?pageEmailMessages=' + pageNumEmailMessages + '&limitEmailMessages=' + $scope.limitEmailMessages + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + $scope.contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: emailCanceller.promise}).success(function (data) {
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            if (searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var printCanceller = null;
    $scope.fetchPrintLetters = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (printCanceller) {
            printCanceller.resolve();
        }
        printCanceller = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $state.params.id + modeChange + whichId + '/invoices/' + $scope.invoiceId + '/listPrint?pagePrintLetters=' + pageNum + '&limitPrintLetters=' + $scope.limitPrintLetters + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: printCanceller.promise}).success(function (data) {
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
        })
    }

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    var searchCanceller = null;
    $scope.searchCommunications = function (pageNum) {
        canLoad.setLoadValue(false);
        if (searchCanceller) {
            searchCanceller.resolve();
        }
        searchCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $state.params.id + modeChange + whichId + '/invoices/' + $scope.invoiceId + '/notes/view/table?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: searchCanceller.promise}).success(function (data) {
            $scope.notesDetails = data.notesDetails;
            $scope.notes = data.notesDetails.notesDetails;
            $scope.notesCount = data.notesDetails.notesCount;
            $scope.notesCountMain = data.notesDetails.notesCountMain;
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
            $scope.selectedId = data.navBarDetails.id;
            var scope = {name: 'notesCount', value: data.notesDetails.notesCount};
            $rootScope.$emit('event:changeJobInvoiceScope', scope);

            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }
    $scope.downloadCallRecording = function(fileLink){
        let link = document.createElement('a');
        link.href = fileLink;

        if (link.download !== undefined) {
            link.download = 'call-recording.wav';
        }
        if (document.createEvent) {
            let e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true);
            link.dispatchEvent(e);
        }
    };
    EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
}

function JobInvoiceTimeLineViewCtrl($scope, $state, $http, prefix, getIdData, $timeout, $rootScope, canLoad, $q, $sce, emailService)
{
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.invoiceId = $state.params.invoiceId;
    $scope.invoiceSelectedTab = "Notes";
    $scope.hideSubNavCustomers = true;
    $scope.hideSubNavCustomerName = true;
    if($state.params.jobId){
        $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : true} );
         var whichMode=$state.params.jobId;
        var url = '/customers/customer_list/' + $state.params.id + '/jobs/' + $state.params.jobId + '/invoices/' + $scope.invoiceId + '/notes/view/timeline';
    }else if($state.params.contractId){
        var whichMode=$state.params.contractId;
        $scope.$emit('contractInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : true} );
        var url = '/customers/customer_list/' + $state.params.id + '/customer-contracts/' + $state.params.contractId + '/invoices/' + $scope.invoiceId + '/notes/view/timeline';
    }else if($state.params.opportunityId){
        var whichMode=$state.params.opportunityId;
        $scope.$emit('opportunityInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : true} );
        var url = '/customers/customer_list/' + $state.params.id + '/opportunities/' + $state.params.opportunityId + '/invoices/' + $scope.invoiceId + '/notes/view/timeline';
    }

    $scope.$emit('jobId:selected', { 'jobId' : $scope.jobId, 'jobType' : getIdData.jobDetails.type, 'invoiceNo' : getIdData.invoice.invoiceno, 'draft' : getIdData.invoice.isdraft, 'invoiceId' : $scope.invoiceId});

    var scope = {name: 'notesCount', value: getIdData.notesCount};
    $rootScope.$emit('event:changeJobInvoiceScope', scope);
    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);
    $scope.$emit('displayInvoiceScreen', true);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("Jobinvoicenotesandcommunications", function (event, action, data) {
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    // Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.selectedId + '&type=all_email';
        clone_obj.attachments_url = '/get_attached_files_list?tableType=31&id=' +  whichMode;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 128;        // For Job invoice
        clone_obj.relatedObjectId = $scope.invoiceId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    HandleAllTimeLineViews.call(this, $scope, canLoad, $http, $q, getIdData, $sce, $timeout, $rootScope, url, prefix);
}
function JobInvoicesNewNotesCtrl($scope, $state, $http, $rootScope, getIdData) {
    $scope.notes = getIdData.customerOffices;
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.invoiceId = $state.params.invoiceId || getIdData.invoiceId;
    $scope.invoiceSelectedTab = "Notes";
    $scope.modeChange = $state.params.mode;

    if($state.params.jobId){
        $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }else if($state.params.contractId){
        $scope.$emit('contractInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }else if($state.params.opportunityId){
        $scope.$emit('opportunityInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }
    $scope.$emit('jobId:selected', { 'jobId' : $scope.jobId, 'jobType' : getIdData.jobDetails.type, 'invoiceNo' : getIdData.invoice.invoiceno, 'draft' : getIdData.invoice.isdraft, 'invoiceId' : $scope.invoiceId });

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.saving = false;
                $rootScope.flashMessage = data.success;
            if($state.params.jobId){
                $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_notes." + $state.params.mode, { 'id': $state.params.id, 'type': $state.params.type, 'jobId' : $state.params.jobId, 'invoiceId' : $state.params.invoiceId });
            }else if($state.params.contractId){
                $state.transitionTo("loggedin.customer_list.view.invoice_view.view_notes." + $state.params.mode, { 'id': $state.params.id, 'type': $state.params.type, 'contractId' : $state.params.contractId, 'invoiceId' : $state.params.invoiceId });
            }else if($state.params.opportunityId){
                $state.transitionTo("loggedin.customer_list.view.opportunity_invoice_view.view_notes." + $state.params.mode, { 'id': $state.params.id, 'type': $state.params.type, 'opportunityId' : $state.params.opportunityId, 'invoiceId' : $state.params.invoiceId });
            }
            })
    }
}

function JobInvoiceEditNotesCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.invoiceId = $state.params.invoiceId || getIdData.invoiceId;
    $scope.noteDetails = getIdData.notesDetails;
    $scope.title = $scope.noteDetails.title;
    $scope.notesid = $scope.noteDetails.notesid;
    $scope.customerOffices = getIdData.navBarDetails;
    $scope.customerId = $scope.customerOffices.id;
    $scope.jobSubNavStatic = true;
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.selectedStopwork = $scope.noteDetails.stopwork;
    $scope.selectedImportant = $scope.noteDetails.important;
    $scope.selectedSendtomobile = $scope.noteDetails.sendtomobile;
    $scope.selectedNote = $scope.noteDetails.note;
    $scope.selectedTilte = $scope.noteDetails.title;
    $scope.modeChange = $state.params.mode;
    $scope.invoiceSelectedTab = "Notes";
    $scope.$emit('displayInvoiceScreen', true);

    NotesCheckBoxCtrl.call(this, $scope);
    $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    $scope.$emit('jobId:selected', { 'jobId' : $scope.jobId, 'jobType' : getIdData.jobDetails.type, 'invoiceNo' : getIdData.invoice.invoiceno, 'draft' : getIdData.invoice.isdraft, 'invoiceId' : $scope.invoiceId});

    $scope.editNotes = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_note', "notes[id]=" + $scope.notesid +
                "&notes[title]=" + encodeURIComponent(this.selectedTilte) +
                "&notes[note]=" + encodeURIComponent(this.selectedNote) +
                "&notes[selectedStopwork]=" + encodeURIComponent(this.selectedStopwork) +
                "&notes[selectedImportant]=" + encodeURIComponent(this.selectedImportant) +
                "&notes[selectedSendtomobile]=" + this.selectedSendtomobile).success(function (data, status) {
            if (status == 200) {
                $scope.editNoteForm.$setPristine();
                $rootScope.flashMessage = data;
                if ($scope.modeChange == 'timeline') {
                    $scope.jobInvoiceNotesTimeLineList($scope.selectedId, $scope.customerTabName, $scope.jobId, $scope.invoiceId);
                } else if ($scope.modeChange == 'table') {
                    $scope.jobInvoiceNotesTableList($scope.selectedId, $scope.customerTabName, $scope.jobId, $scope.invoiceId);
                }
                $scope.updating = false;
            }
        });
    }
}
function JobInvoiceNewSmsMessageCtrl($scope, $http, $state, datasets,  smsTemplates, $rootScope, getIdData, $sce) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    $scope.notes = getIdData.customerOffices;
    smsTemplates.setTemplatesValue(getIdData.templateDetails);
    $scope.smsTemplates = getIdData.templateDetails;
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.invoiceId = $state.params.invoiceId || getIdData.invoiceId;
    $scope.invoiceSelectedTab = "Notes";
    $scope.jobSubNavStatic = true;
    $scope.modeChange = $state.params.mode;
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);

    if($state.params.jobId){
        $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
        }else if($state.params.contractId){
        $scope.$emit('contractInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }else if($state.params.opportunityId){
        $scope.$emit('opportunityInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }
    $scope.$emit('jobId:selected', { 'jobId' : $scope.jobId, 'jobType' : getIdData.jobDetails.type, 'invoiceNo' : getIdData.invoice.invoiceno, 'draft' : getIdData.invoice.isdraft, 'invoiceId' : $scope.invoiceId});

    $scope.$on('templateAccordion', function(event, message) {
        tinyMCE.activeEditor.execCommand('mceInsertContent', false, message);
    });
    SMSMessageControllers.call(this, $scope);
    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.saving = false;
                $rootScope.flashMessage = data.success;
                if ($scope.modeChange == 'timeline' && $state.params.jobId) {
                    $scope.jobInvoiceNotesTimeLineList($scope.selectedId, $scope.customerTabName, $scope.jobId, $scope.invoiceId);
                } else if ($scope.modeChange == 'table' && $state.params.jobId) {
                    $scope.jobInvoiceNotesTableList($scope.selectedId, $scope.customerTabName, $scope.jobId, $scope.invoiceId);
                }else if($state.params.contractId && $scope.modeChange == 'timeline'){
                    $scope.contractInvoiceNotesTimeLineList($scope.selectedId, $scope.customerTabName, $state.params.contractId, $scope.invoiceId);
                }else if($state.params.contractId && $scope.modeChange == 'table'){
                    $scope.contractInvoiceNotesTableList($scope.selectedId, $scope.customerTabName, $state.params.contractId, $scope.invoiceId);
                }else if($state.params.opportunityId && $scope.modeChange == 'timeline'){
                    $scope.opportunityInvoiceNotesTimeLineList($scope.selectedId, $scope.customerTabName, $state.params.opportunityId, $scope.invoiceId);
                }else if($state.params.opportunityId && $scope.modeChange == 'table'){
                    $scope.opportunityInvoiceNotesTableList($scope.selectedId, $scope.customerTabName, $state.params.opportunityId, $scope.invoiceId);
                }
            })
    }
    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
}

function JobInvoiceNewPhoneCallCtrl($scope, $state, $http, $rootScope, getIdData) {
    $scope.notes = getIdData.customerOffices;
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.invoiceId = $state.params.invoiceId || getIdData.invoiceId;
    $scope.invoiceSelectedTab = "Notes";
    $scope.jobSubNavStatic = true;
    $scope.modeChange = $state.params.mode;
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);

    if($state.params.jobId){
        $scope.$emit('jobInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }else if($state.params.contractId){
        $scope.$emit('contractInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }else if($state.params.opportunityId){
        $scope.$emit('opportunityInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : false} );
    }

    $scope.$emit('jobId:selected', { 'jobId' : $scope.jobId, 'jobType' : getIdData.jobDetails.type, 'invoiceNo' : getIdData.invoice.invoiceno, 'draft' : getIdData.invoice.isdraft, 'invoiceId' : $scope.invoiceId});

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.newPhoneCallForm.$setPristine();
                $rootScope.flashMessage = data.success;
                if ($scope.modeChange == 'timeline' && $state.params.jobId) {
                    $scope.jobInvoiceNotesTimeLineList($scope.selectedId, $scope.customerTabName, $scope.jobId, $scope.invoiceId);
                } else if ($scope.modeChange == 'table' && $state.params.jobId) {
                    $scope.jobInvoiceNotesTableList($scope.selectedId, $scope.customerTabName, $scope.jobId, $scope.invoiceId);
                }else if($state.params.contractId && $scope.modeChange == 'timeline'){
                    $scope.contractInvoiceNotesTimeLineList($scope.selectedId, $scope.customerTabName, $state.params.contractId, $scope.invoiceId);
                }else if($state.params.contractId && $scope.modeChange == 'table'){
                    $scope.contractInvoiceNotesTableList($scope.selectedId, $scope.customerTabName, $state.params.contractId, $scope.invoiceId);
                }else if($state.params.opportunityId && $scope.modeChange == 'timeline'){
                    $scope.opportunityInvoiceNotesTimeLineList($scope.selectedId, $scope.customerTabName, $state.params.opportunityId, $scope.invoiceId);
                }else if($state.params.opportunityId && $scope.modeChange == 'table'){
                    $scope.opportunityInvoiceNotesTableList($scope.selectedId, $scope.customerTabName, $state.params.opportunityId, $scope.invoiceId);
                }
                $scope.saving = false;
            })
    }
}

csmodule.controller('allocateToInvoices', function($scope, $rootScope, $filter, $http, prefix, $state) {
    $scope.invoices = [];
    $scope.total_allocated = 0;
    $scope.valid = false;
    var balance = parseFloat($scope.sidepanelData.Balance.replace(/,/g, ''));
    $scope.remainingBalance = balance;

    var getUrl = prefix + '/get_customer_invoices/' + $scope.sidepanelData.id;
    $http.post(getUrl).
        success(function (data, status) {
            $scope.invoices = data.invoices;
        });

    $scope.initInvoice = function(invoice_index) {
        var invoiceObject = $scope.invoices[invoice_index];
        var balanceInvoiceAmount = parseFloat(invoiceObject.grandtotal) - parseFloat(invoiceObject.paidAmount);
        //var amountToEdit = parseFloat($scope.invoices[invoice_index].amountToEdit);
        //var allocationVal = (amountToEdit > 0) ? amountToEdit : 0;
        $scope.invoices[invoice_index].allocation = (!$scope.invoices[invoice_index].allocation) ? 0 : $scope.invoices[invoice_index].allocation;
        $scope.invoices[invoice_index].valid = true;
        $scope.invoices[invoice_index].balanceInvoiceAmount = parseFloat(balanceInvoiceAmount.toFixed(2));
    }
    $scope.CustomCurrency = $rootScope.CustomCurrency;

    $scope.handleInvoiceAllocation = function(invoice_id) {
        var invoiceObj = _.where($scope.invoices, {invoiceId: invoice_id}),
            invoice = invoiceObj[0],
            input_is_number = !isNaN(invoice.allocation);

        if(input_is_number) {
            $scope.calculateAllAllocations();
            var amountAllocated = parseFloat(invoice.allocation);
            var decimalError = false;

            if(amountAllocated > 0) {
                var amountAsStr = amountAllocated + '';
                var decimalCount = amountAsStr.split('.');
                if(decimalCount.length > 1 && decimalCount[1] && decimalCount[1].length > 2) {
                    decimalError= true;
                }
            }

            $scope.remainingBalance = (balance - $scope.total_allocated);

            if (amountAllocated > (balance - $scope.total_allocated + amountAllocated)) {
                invoice.valid = false;
                invoice.validation_message = "The remaining balance is " + $filter('customCurrency')((balance - $scope.total_allocated + parseFloat(invoice.allocation)), $scope.CustomCurrency);
            } else if(invoice.allocation < 0) {
                invoice.valid = false;
                invoice.validation_message = "Amount cannot be less than zero";
            } else if(invoice.balanceInvoiceAmount < invoice.allocation) {
                invoice.valid = false;
                invoice.validation_message = "Amount cannot be greater than invoice amount";
            } else if(decimalError) {
                invoice.valid = false;
                invoice.validation_message = "Please enter a valid number eg:2500.50";
            } else {
                invoice.valid = true;
            }
        } else {
            invoice.valid = false;
            invoice.validation_message = "Please enter a valid number";
            $scope.valid = false;
        }

        if (invoice.allocation.length === 0) {
            invoice.valid = true;
        }

        $scope.handleAllValidation();
    }

    $scope.calculateAllAllocations = function() {
        $scope.total_allocated = 0;
        for (var i = 0, x = $scope.invoices.length; i < x; i ++) {
            $scope.total_allocated += parseFloat($scope.invoices[i].allocation) || 0;
        }
    }

    $scope.handleAllValidation = function() {
        $scope.valid = true;

        if ($scope.total_allocated === 0) {
            $scope.valid = false;
        } else {
            for (var i = 0, x = $scope.invoices.length; i < x; i ++) {
                if ($scope.invoices[i].valid === false) {
                    $scope.valid = false;
                    return
                }
            }
        }
    }

    $scope.close = function() {
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $scope.addAllocation = function() {
        var saveUrl = prefix + '/account_credit/' + $scope.sidepanelData.id +'/allocate';
        var allocationData = $scope.getAllocationData();
        $scope.saving = true;
        $http.post(saveUrl, '&invoices='+encodeURIComponent(angular.toJson(allocationData))).
            success(function (data, status) {
                $scope.$emit('smarttable_and_sidepanel:filtervalues', false);
                $scope.saving = false;
                $scope.close();
            });
    }

    $scope.getAllocationData = function() {
        var allocationArray = [];
        for (var i = 0, x = $scope.invoices.length; i < x; i ++) {
            var invoiceObj = $scope.invoices[i];
            if(invoiceObj.allocation > 0) {
                allocationArray.push({id: invoiceObj.id, invoiceId: invoiceObj.invoiceId, amount:invoiceObj.allocation});
            }
        }
        return allocationArray;
    }
});

function JobInvoiceSubNavCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate, emailService) {
    if(getIdData.deleteOrNull== 'Deleted' ) {
        var message = getIdData.deleteOrNull;
        warningModal.show('This Customer Invoice has been ' + message, 'Invoices', 'Deleted');
        $state.transitionTo('loggedin.customer_list.view.job.invoices', {'id': $state.params.id,'type': $state.params.type,'jobId':$state.params.jobId },{reload: true});
    }
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.invoiceId = $state.params.invoiceId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.hideSubNavCustomers = true;
    $scope.customerInvoiceDesign = true;
    $scope.$emit('displayInvoiceScreen', true);
    $scope.prefix = prefix;
    $scope.jobInvoiceSubNav = true;
    $scope.show_tabs = true;
    $scope.job = getIdData.jobDetails;

    $scope.allowDeleteInvoice = getIdData.allowDeleteInvoice;
    $scope.deleteInvoiceReasons = getIdData.deleteInvoiceReasons;
    $scope.deleteWarningType = getIdData.deleteWarningType;

    $scope.type = getIdData.customerType;
    $scope.tabCustomerSurname = getIdData.customerName;
    $scope.parentPropertyName = getIdData.parentPropertyName;
    $scope.notesCount = getIdData.notesCount;

    $scope.tabAddressline1 = $scope.tabAddressline2 = $scope.tabAddressline3 = $scope.tabTown = $scope.tabCounty = $scope.tabPostcode = '';
    if(getIdData.addressline1){ $scope.tabAddressline1 = getIdData.addressline1; }
    if(getIdData.addressline2){ $scope.tabAddressline2 = ', '+ getIdData.addressline2; }
    if(getIdData.addressline3){ $scope.tabAddressline3 = ', '+ getIdData.addressline3; }
    if(getIdData.town){ $scope.tabTown = ', '+ getIdData.town; }
    if(getIdData.county){ $scope.tabCounty = ', '+ getIdData.county; }
    if(getIdData.postcode){ $scope.tabPostcode = ', '+ getIdData.postcode; }

    $scope.customerAddress = $scope.tabAddressline1 + $scope.tabAddressline2 + $scope.tabAddressline3 + $scope.tabTown + $scope.tabCounty + $scope.tabPostcode;

    $scope.$on('jobInvoiceTab:selected', function(event, data) {
        $scope.jobInvoiceSubNav = data.jobInvoiceSubNav;
        $scope.show_tabs = data.show_tabs;
    });
    $rootScope.$on("event:changeJobInvoiceScope", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope[name] = value;
    });
    $scope.deleteWarningModalBox = function() {
        warningModal.show($scope.deleteInvoiceReasons, "Delete invoice", "invoice_delete_"+$scope.deleteWarningType);
    }

    $scope.$on("modal:close", function() {
        $scope.deleteInvoiceShouldBeOpen = false;
    })

    $scope.showDeleteBox = function() {
        $scope.deleteInvoiceShouldBeOpen = true;
    }

    $scope.triggerAddNewPayment = function (type, id, jobId, invoiceId) {
        $http.get(prefix + '/checkInvoicePayment?id=' + id + "&jobId=" + jobId + "&invoiceId=" + invoiceId).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }
            else {
                $state.transitionTo('loggedin.customer_list.view.job.invoices.view.view_invoice.new_payment', { type : $state.params.type, id : $state.params.id, jobId:$state.params.jobId, invoiceId:$state.params.invoiceId})
            }
        });
    }

    $scope.triggerRaiseCreditNote = function(id, type, jobId, invoiceId) {
        $http.get(prefix + '/checkInvoiceIsDraft?draft=' + getIdData.invoice.draft).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }
            else {
                $state.transitionTo('loggedin.customer_list.view.job.invoices.view.view_invoice.new_credit_note', {id:$state.params.id, type:$state.params.type, jobId:$state.params.jobId, invoiceId:$state.params.invoiceId});
            }
        });
    }

    $scope.deleteInvoiceDetails = function() {
        $scope.flashMessage = 'Invoice deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo('loggedin.customer_list.view.job.invoices', {id: $state.params.id, type: $state.params.type, jobId: $scope.jobId}, {reload: true});
    }
}

function ContractInvoiceSubNavCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate, emailService,$location) {
    $scope.contractId = $state.params.contractId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.invoiceId = $state.params.invoiceId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.hideSubNavCustomers = true;
    $scope.customerInvoiceDesign = true;
    $scope.$emit('displayInvoiceScreen', true);
    $scope.prefix = prefix;
    $scope.jobInvoiceSubNav = true;
    $scope.show_tabs = true;
    $scope.job = getIdData.jobDetails;

    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.allowDeleteInvoice = getIdData.allowDeleteInvoice;
    $scope.deleteInvoiceReasons = getIdData.deleteInvoiceReasons;
    $scope.deleteWarningType = getIdData.deleteWarningType;

    $scope.type = getIdData.customerType;
    $scope.tabCustomerSurname = getIdData.customerName;
    $scope.parentPropertyName = getIdData.parentPropertyName;
    $scope.notesCount = getIdData.notesCount;

    $scope.tabAddressline1 = $scope.tabAddressline2 = $scope.tabAddressline3 = $scope.tabTown = $scope.tabCounty = $scope.tabPostcode = '';
    if(getIdData.addressline1){ $scope.tabAddressline1 = getIdData.addressline1; }
    if(getIdData.addressline2){ $scope.tabAddressline2 = ', '+ getIdData.addressline2; }
    if(getIdData.addressline3){ $scope.tabAddressline3 = ', '+ getIdData.addressline3; }
    if(getIdData.town){ $scope.tabTown = ', '+ getIdData.town; }
    if(getIdData.county){ $scope.tabCounty = ', '+ getIdData.county; }
    if(getIdData.postcode){ $scope.tabPostcode = ', '+ getIdData.postcode; }

    $scope.customerAddress = $scope.tabAddressline1 + $scope.tabAddressline2 + $scope.tabAddressline3 + $scope.tabTown + $scope.tabCounty + $scope.tabPostcode;
    $scope.$emit('tabRouteCustomer:selected', { 'customerTabName': $scope.customerTabName });

    $scope.$on('contractInvoiceTab:selected', function(event, data) {
        $scope.jobInvoiceSubNav = data.jobInvoiceSubNav;
        $scope.show_tabs = data.show_tabs;
    });
    $rootScope.$on("event:changeJobInvoiceScope", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope[name] = value;
    });
    $scope.deleteWarningModalBox = function() {
        warningModal.show($scope.deleteInvoiceReasons, "Delete invoice", "invoice_delete_"+$scope.deleteWarningType);
    }

    $scope.$on("modal:close", function() {
        $scope.deleteInvoiceShouldBeOpen = false;
    })

    $scope.showDeleteBox = function() {
        $scope.deleteInvoiceShouldBeOpen = true;
    }

    $scope.triggerAddNewPayment = function (type, id, contractId, invoiceId) {
        $http.get(prefix + '/checkInvoicePayment?id=' + id + "&contractId=" + contractId + "&invoiceId=" + invoiceId).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }
            else {
                $state.transitionTo('loggedin.customer_list.view.invoice_view.contract_new_payment', { type : $state.params.type, id : $state.params.id, contractId:$state.params.contractId, invoiceId:$state.params.invoiceId})
            }
        });
    }

    $scope.triggerRaiseCreditNote = function(id, type, contractId, invoiceId) {
        $http.get(prefix + '/checkInvoiceIsDraft?draft=' + getIdData.invoice.draft).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }
            else {
                $state.transitionTo('loggedin.customer_list.view.invoice_view.new_credit_note', {id:$state.params.id, type:$state.params.type, contractId:$state.params.contractId, invoiceId:$state.params.invoiceId});
            }
        });
    }

    $scope.deleteInvoiceDetails = function() {
        $scope.flashMessage = 'Invoice deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $location.path('/customers/' + $state.params.type +'/'+ $state.params.id+'/contracts/'+$state.params.contractId +'/setup/basic-details');
    }
}

function ContractInvoiceViewCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate, emailService, $compile, $timeout, $sce) {
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.invoiceId = $state.params.invoiceId || getIdData.invoiceId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.hideSubNavCustomers = true;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.prefix = prefix;
    $scope.show_tabs = true;
    $scope.contractDetails = getIdData.jobDetails;
    $scope.job_invoice = getIdData.invoice;
    $scope.vatType = getIdData.invoice.vatType;
    $scope.initialQuoteAmount = parseFloat($scope.job_invoice.initialQuoteAmount);
    $scope.retentionQuoteAmount = parseFloat($scope.job_invoice.retentionQuoteAmount);
    $scope.interimInvoiceAmount = parseFloat($scope.job_invoice.interimInvoiceAmount);
    $scope.invoiceSelectedTab = 'Invoices';

    $scope.invoiceTypeId=(getIdData.invoice.type == 'retention' || getIdData.invoice.type == 'initial')?true:false;

    $scope.paymentDetails = getIdData.paymentDetails;
    $scope.amountAllocated = roundFloat(getIdData.amountAllocated,2);
    $scope.printAndPostModule = getIdData.printAndPostModule;
    $scope.invoiceAddressId = getIdData.invoiceAddressId;
    $scope.watermark = getIdData.watermark;

    $scope.allowDeleteInvoice = getIdData.allowDeleteInvoice;
    $scope.deleteInvoiceReasons = getIdData.deleteInvoiceReasons;
    $scope.deleteWarningType = getIdData.deleteWarningType;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'invoiceSelectedTab': $scope.invoiceSelectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.$emit('contractInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : true} );

    $scope.$emit('jobId:selected', {
        'jobId': getIdData.jobId,
        'jobType': getIdData.jobDetails.type,
        'invoiceId': $state.params.invoiceId,
        'invoiceNo' : getIdData.invoice.invoice_no
    });

    $scope.invoiceId = $state.params.invoiceId;
    $scope.$on("informationbar:draft_invoice", function (event, message) {
        $scope.convertDraftInvoiceShouldBeOpen =  true;
    });

    $scope.fetchInvoiceDetails = function () {
        $scope.flashMessage = 'Converted draft into invoice';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo("loggedin.customer_list.view.invoice_view.view_invoice_contract", {id: $state.params.id, type: $state.params.type, contractId: $state.params.contractId, invoiceId: $scope.invoiceId}, {reload:true});
    }
    $scope.invoiceDefaultSubject = getIdData.invoiceDefaultSubject;
    $scope.invoiceDefaultMessage = getIdData.invoiceDefaultMessage;

    $scope.deleteWarningModalBox = function() {
        warningModal.show($scope.deleteInvoiceReasons, "Delete invoice", "invoice_delete_"+$scope.deleteWarningType);
    }

    $scope.$on("modal:close", function() {
        $scope.deleteInvoiceShouldBeOpen = false;
    })

    $scope.showDeleteBox = function() {
        $scope.deleteInvoiceShouldBeOpen = true;
    }
    $scope.$on('event:sentStatus', function(event, data) {
        var id = data.id;
        var tableType = data.type;
        if(tableType == 59) {
            $http.get(prefix + '/getInfoBarDetails?id=' + id + '&type=' + tableType).success(function(data) {
                $scope.job_invoice.audits = data;
            });
        }

    });

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_invoice_address_email?id=' + $scope.invoiceAddressId + '&propertyId=' + $scope.selectedId;
        clone_obj.attachments_url = '';
        clone_obj.subject = $scope.invoiceDefaultSubject;
        clone_obj.message = $scope.invoiceDefaultMessage;
        clone_obj.relatedObjectType = 59;
        clone_obj.relatedObjectId = $scope.invoiceId;
        clone_obj.attached_files = [
            {
                id: "CONTRACTINVOICE|" + $scope.selectedId + "|" + $scope.invoiceId + "|" + 59,
                file_name: 'Contract Invoice no. ' + getIdData.invoice.invoice_no,
                file_size: 2,
                type: 'generated'
            }
        ];
        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.showPrintInvoice = function() {
        var side_panel_tpl = $compile('<span id="view_print_invoice" ' +
            'sidepanel template="print_and_post" ' +
            'title="Print and post invoice" ' +
            'supports_copy="true"'+
            'remove_on_close="true" ' +
            'print_and_post_message="Your invoice is on its way to the customer"' +
            'pdf_url="' + Routing.generate('print_contract_invoice', { type: $scope.customerMode, id: $state.params.id, contractId: $state.params.contractId, invoiceId: $scope.job_invoice.id}) +'"'+
            'supports_print="true"' +
            'supports_print_and_post="'+$scope.printAndPostModule +'"'+
            'printpost_params="type=print_post&tableType=59&tablePKID='+$scope.job_invoice.id +'"' +
            'watermark='+$scope.watermark +'></span>')($scope);

        angular.element('body').append(side_panel_tpl);
        $timeout(function() {
            angular.element('#view_print_invoice').scope().$$childTail.initialise();
        }, 600);
    }

    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#view_print_invoice')) {
            angular.element('#view_print_invoice').remove();
        };
    });

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
}

function OpportunityInvoiceSubNavCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate, emailService, $location) {
    if(getIdData.deleteOrNull== 'Deleted' ) {
        var message = getIdData.deleteOrNull;
        warningModal.show('This Customer Invoice has been ' + message, 'Invoices', 'Deleted');
        $location.path('/customers/' + $state.params.type +'/'+ $state.params.id+'/opportunity/'+$state.params.opportunityId +'/view');
        //$state.transitionTo('loggedin.customer_list.view.job.invoices', {'id': $state.params.id,'type': $state.params.type,'jobId':$state.params.jobId },{reload: true});
    }
    $scope.opportunityId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.invoiceId = $state.params.invoiceId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.hideSubNavCustomers = true;
    $scope.customerInvoiceDesign = true;
    $scope.$emit('displayInvoiceScreen', true);
    $scope.prefix = prefix;
    $scope.jobInvoiceSubNav = true;
    $scope.show_tabs = true;
    $scope.opportunity = getIdData.jobDetails;

    $scope.allowDeleteInvoice = getIdData.allowDeleteInvoice;
    $scope.deleteInvoiceReasons = getIdData.deleteInvoiceReasons;
    $scope.deleteWarningType = getIdData.deleteWarningType;

    $scope.type = getIdData.customerType;
    $scope.tabCustomerSurname = getIdData.customerName;
    $scope.parentPropertyName = getIdData.parentPropertyName;
    $scope.notesCount = getIdData.notesCount;

    $scope.tabAddressline1 = $scope.tabAddressline2 = $scope.tabAddressline3 = $scope.tabTown = $scope.tabCounty = $scope.tabPostcode = '';
    if(getIdData.addressline1){ $scope.tabAddressline1 = getIdData.addressline1; }
    if(getIdData.addressline2){ $scope.tabAddressline2 = ', '+ getIdData.addressline2; }
    if(getIdData.addressline3){ $scope.tabAddressline3 = ', '+ getIdData.addressline3; }
    if(getIdData.town){ $scope.tabTown = ', '+ getIdData.town; }
    if(getIdData.county){ $scope.tabCounty = ', '+ getIdData.county; }
    if(getIdData.postcode){ $scope.tabPostcode = ', '+ getIdData.postcode; }

    $scope.customerAddress = $scope.tabAddressline1 + $scope.tabAddressline2 + $scope.tabAddressline3 + $scope.tabTown + $scope.tabCounty + $scope.tabPostcode;

    $scope.$on('opportunityInvoiceTab:selected', function(event, data) {
        $scope.opportunityInvoiceSubNav = data.jobInvoiceSubNav;
        $scope.show_tabs = data.show_tabs;
    });

    $rootScope.$on("event:changeJobInvoiceScope", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope[name] = value;
    });
    $scope.deleteWarningModalBox = function() {
        warningModal.show($scope.deleteInvoiceReasons, "Delete invoice", "invoice_delete_"+$scope.deleteWarningType);
    }

    $scope.$on("modal:close", function() {
        $scope.deleteInvoiceShouldBeOpen = false;
    })

    $scope.showDeleteBox = function() {
        $scope.deleteInvoiceShouldBeOpen = true;
    }

    // Need to include Add new payment and raise credit note

    $scope.deleteInvoiceDetails = function() {
        $scope.flashMessage = 'Invoice deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $location.path('/customers/' + $state.params.type +'/'+ $state.params.id+'/opportunity/'+$state.params.opportunityId +'/view');

        //$window.location.href = '/customers/' + $state.params.type +'/'+ $state.params.id+'/opportunity/'+$state.params.opportunityId +'/view';
    }

    $scope.triggerAddNewPayment = function (type, id, opportunityId, invoiceId) {
        $http.get(prefix + '/checkInvoicePayment?id=' + id + "&opportunityId=" + opportunityId + "&invoiceId=" + invoiceId).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }
            else {
                $state.transitionTo('loggedin.customer_list.view.opportunity_invoice_view.opportunity_new_payment', { type : $state.params.type, id : $state.params.id, opportunityId:$state.params.opportunityId, invoiceId:$state.params.invoiceId})
            }
        });
    }
}

function OpportunityInvoiceViewCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate, emailService, $compile, $timeout, $sce, $location) {
    if(getIdData.deleteOrNull== 'Deleted' ) {
        var message = getIdData.deleteOrNull;
        //$state.transitionTo('loggedin.customer_list.view.invoices', {'id': $state.params.id, 'type': $state.params.type},{reload: true});
        $location.path('/customers/' + $state.params.type +'/'+ $state.params.id+'/opportunity/'+$state.params.opportunityId +'/view');
    }
    $scope.opportunityId = $state.params.opportunityId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.invoiceId = $state.params.invoiceId || getIdData.invoiceId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.hideSubNavCustomers = true;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.prefix = prefix;
    $scope.show_tabs = true;
    $scope.opportunity = getIdData.jobDetails;
    $scope.job_invoice = getIdData.invoice;
    $scope.vatType = getIdData.invoice.vatType;
    $scope.initialQuoteAmount = parseFloat($scope.job_invoice.initialQuoteAmount);
    $scope.retentionQuoteAmount = parseFloat($scope.job_invoice.retentionQuoteAmount);
    $scope.interimInvoiceAmount = parseFloat($scope.job_invoice.interimInvoiceAmount);
    $scope.grantInvoiceAmount = parseFloat($scope.job_invoice.grantInvoiceAmount);
    $scope.invoiceSelectedTab = 'Invoices';

    $scope.invoiceTypeId=(getIdData.invoice.type == 'retention' || getIdData.invoice.type == 'initial')?true:false;

    $scope.paymentDetails = getIdData.paymentDetails;
    $scope.amountAllocated = roundFloat(getIdData.amountAllocated,2);
    $scope.printAndPostModule = getIdData.printAndPostModule;
    $scope.invoiceAddressId = getIdData.invoiceAddressId;
    $scope.watermark = getIdData.watermark;

    $scope.allowDeleteInvoice = getIdData.allowDeleteInvoice;
    $scope.deleteInvoiceReasons = getIdData.deleteInvoiceReasons;
    $scope.deleteWarningType = getIdData.deleteWarningType;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'invoiceSelectedTab': $scope.invoiceSelectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', { 'jobId' : getIdData.jobId, 'jobType' : getIdData.jobDetails.type, 'invoiceId' : $scope.invoiceId , 'invoiceNo' : getIdData.invoice.invoice_no, 'draft' : getIdData.invoice.draft});

    $scope.$emit('opportunityInvoiceTab:selected', { 'jobInvoiceSubNav' : true, 'show_tabs' : true} );

    $scope.invoiceId = $state.params.invoiceId;
    $scope.$on("informationbar:draft_invoice", function (event, message) {
        $scope.convertDraftInvoiceShouldBeOpen =  true;
    });
    $scope.$on("informationbar:job_details", function (event, message) {
        $state.transitionTo("loggedin.customer_list.view.job.details", { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId });
    });
    $scope.fetchInvoiceDetails = function () {
        $scope.flashMessage = 'Converted draft into invoice';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_invoice", {id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId, invoiceId: $scope.invoiceId}, {reload:true});
    }
    $scope.invoiceDefaultSubject = getIdData.invoiceDefaultSubject;
    $scope.invoiceDefaultMessage = getIdData.invoiceDefaultMessage;

    $scope.deleteWarningModalBox = function() {
        warningModal.show($scope.deleteInvoiceReasons, "Delete invoice", "invoice_delete_"+$scope.deleteWarningType);
    }

    $scope.$on("modal:close", function() {
        $scope.deleteInvoiceShouldBeOpen = false;
    })

    $scope.showDeleteBox = function() {
        $scope.deleteInvoiceShouldBeOpen = true;
    }
    $scope.$on('event:sentStatus', function(event, data) {
        var id = data.id;
        var tableType = data.type;
        if(tableType == 59) {
            $http.get(prefix + '/getInfoBarDetails?id=' + id + '&type=' + tableType).success(function(data) {
                $scope.job_invoice.audits = data;
            });
        }

    });

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_invoice_address_email?id=' + $scope.invoiceAddressId + '&propertyId=' + $scope.selectedId;
        clone_obj.attachments_url = '';
        clone_obj.subject = $scope.invoiceDefaultSubject;
        clone_obj.message = $scope.invoiceDefaultMessage;
        clone_obj.relatedObjectType = 59;
        clone_obj.relatedObjectId = $scope.invoiceId;
        clone_obj.attached_files = [
            {
                id: "OPPORTUNITYINVOICE|" + $scope.selectedId + "|" + $scope.invoiceId + "|" + 59,
                file_name: 'Invoice no. ' + getIdData.invoice.invoice_no,
                file_size: 2,
                type: 'generated'
            }
        ];
        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.showPrintInvoice = function() {
        var side_panel_tpl = $compile('<span id="view_print_invoice" ' +
            'sidepanel template="print_and_post" ' +
            'title="Print and post invoice" ' +
            'supports_copy="true"'+
            'remove_on_close="true" ' +
            'print_and_post_message="Your invoice is on its way to the customer"' +
            'pdf_url="' + Routing.generate('print_opportunity_invoice', { type: $scope.customerMode, id: $state.params.id, opportunityId: $state.params.opportunityId, invoiceId: $scope.job_invoice.id}) +'"'+
            'supports_print="true"' +
            'supports_print_and_post="'+$scope.printAndPostModule +'"'+
            'printpost_params="type=print_post&tableType=59&tablePKID='+$scope.job_invoice.id +'"' +
            'watermark='+$scope.watermark +'></span>')($scope);

        angular.element('body').append(side_panel_tpl);
        $timeout(function() {
            angular.element('#view_print_invoice').scope().$$childTail.initialise();
        }, 600);
    }

    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#view_print_invoice')) {
            angular.element('#view_print_invoice').remove();
        };
    });

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    $scope.quotedAmountStringConstruct = function() {
        var string = [];
        if($scope.initialQuoteAmount > 0) {
            string.push($translate('initial'));
        }
        if($scope.interimInvoiceAmount > 0) {
            string.push($translate('interim'));
        }
        if($scope.retentionQuoteAmount > 0) {
            string.push($translate('retention'));
        }
        if($scope.retentionQuoteAmount > 0) {
            string.push($translate('grant'));
        }

        var showString = string.join(', ');
        var n = showString.lastIndexOf(',');
        var quotedAmountInvoices =  showString.slice(0, n) + showString.slice(n).replace(',', ' & ');
        $scope.quotedAmountTranslation = {'quotedInvoices' : quotedAmountInvoices };
        $scope.quotedAmountInvoices =  quotedAmountInvoices;
    }

    $scope.quotedAmountStringConstruct();

    var invoice_line_items = ($scope.job_invoice['breakdown'] === 4) ? _.flatten(_.pluck($scope.job_invoice['items'], 'items')) : $scope.job_invoice['items'];
    if(($scope.job_invoice['breakdown'] === 1 && !$scope.job_invoice['taxDisplayName']) || ($scope.job_invoice['breakdown'] > 1 && _.max(_.pluck(invoice_line_items,'taxItemName')) === null)){
        $scope.job_invoice['isHideVat'] = true;
    }
}
