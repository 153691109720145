commusoftCommon.service('tableCollection', function($rootScope, $translate) {
    var taxLabel = '{TAX_LABEL}';
    /*====================================================
     Tabular data
     ====================================================*/
    // TODO: i18n this
    this.jobTodosHeader = ['Type', 'Description', 'Certificate', 'Part', 'Appliance', 'Estimated Hours', 'Quantity', 'Diary Event', 'Engineer Name'];
    this.jobTodosClosedHeader = ['Status', 'Type', 'Description', 'Certificate', 'Part', 'Appliance', 'Estimated Hours', 'Quantity', 'Diary Event', 'Engineer Name'];

    this.tableHeaders = {
        'viewAllServiceReminder': ['Service date', 'Reminder date', 'Service type', 'Number of days until service due', 'Processing type', 'Method', 'Last communication date', 'Last communication detail', 'Customer name', 'Customer contact telephone', 'Customer contact email', 'Property name', 'Property contact telephone', 'Property contact email', 'Property address line 1', 'Property address line 2', 'Property address line 3', 'Property.Town', 'Property.County', 'Property.Postcode'],
        'serviceReminderDueDays': ['Service date', 'Service type', 'Number of days until service due', 'Processing type', 'Method', 'Last communication date', 'Last communication detail', 'Customer name', 'Service contact', 'Customer contact telephone', 'Customer contact email', 'Property name', 'Property contact telephone', 'Property contact email', 'Property address line 1', 'Property address line 2', 'Property address line 3', 'Property.Town', 'Property.County', 'Property.Postcode'],
        'service_reminders_phone_call_list': ['Customer_name', 'Address_line_1', 'Address_line_2', 'Address_line_3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Service_type', 'Service_date', 'Number_of_days_until_service_due', 'Service_reminder_contact_name', 'Contact_telephone', 'Contact_mobile', 'Number_of_phone_calls_made'],
        'appliances': ['Appliance group', 'Appliance type', 'Fuel type', 'Make', 'Model', 'Location', 'Reference', 'Gc number', 'Serial number', 'In warranty', 'Warranty start date', 'Warranty end date', 'Warranty duration'],
        'job_certificates': ['Name', 'Number', 'Type' ,'Created user', 'Created date/time', 'Sent to customer on', 'Last updated user', 'Last updated date/time'],
        'job_estimate_costs': ['Description', 'Supplier', 'Category', 'Cost', 'Quantity', 'Total (exc VAT)', 'Vat', 'Total'],
        'serviceRemindersStatement': ['Service status', 'Service due date','Communication method', 'Contact name', 'Contact telephone', 'Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Service type', 'Job booked', 'Job reported to us', 'Job no', 'Job description', 'Last diary event', 'Last engineer name', 'Last diary status', 'Number of diary events', 'Number of no access events', 'Number of aborted events', 'Number of rejected events', 'Completion date', 'Completed description', 'Completed notes', 'On hold status', 'On hold description'],
        'serviceReminderStatementOverDue': ['Number of days overdue', 'Contact name','Communication method', 'Contact telephone', 'Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Job date', 'Job no', 'Job description', 'Diary date', 'Engineer name', 'Service due date', 'Last diary status', 'Number of diary events', 'Number of no access events', 'Number of aborted events', 'Number of rejected events', 'On hold status', 'On hold description'],
        'serviceReminderStatementOverNot': ['Number of days overdue', 'Service due date','Communication method', 'Contact name', 'Contact telephone', 'Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Job date', 'Job no', 'Job description', 'On hold status', 'On hold description'],
        'serviceReminderStatementDueBooked': ['Contact name', 'Contact telephone', 'Communication method','Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Job date', 'Job no', 'Job description', 'Diary date', 'Engineer name', 'Service due date', 'Last diary status', 'Number of diary events', 'Number of no access events', 'Number of aborted events', 'Number of rejected events', 'On hold status', 'On hold description'],
        'serviceReminderStatementDueNotBooked': ['Service due date', 'Contact name','Communication method', 'Contact telephone', 'Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Job date', 'Job no', 'Job description', 'On hold status', 'On hold description'],
        'serviceRemindersStatementCompleted': ['Contact name', 'Contact telephone','Communication method', 'Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Job date', 'Job no', 'Job description', 'Diary date', 'Engineer name', 'Service due date', 'Complete date', 'Completed description', 'Completed notes'],
        'work_reminders_view_pending': ['Due_date', 'Name', 'Address_line_1', 'Address_line_2', 'Address_line_3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'Service_type'],
        'work_reminders_view_authorised': ['Due_date', 'Name', 'Address_line_1', 'Address_line_2', 'Address_line_3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'Service_Type', 'Job Booked', 'Job Reported To Us', 'Job No', 'Job Description', 'Last Diary Event', 'Last Engineer Name', 'Last Diary Status', 'Number of Diary Events', 'Number of No Access Events', 'Number of Aborted Events', 'Number of Rejected Events'],
        'work_reminders_view_waiting_customer': ['Due_date', 'Name', 'Address_line_1', 'Address_line_2', 'Address_line_3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'Service_Type', 'Job Booked', 'Job Reported To Us', 'Job No', 'Job Description'],
        'notifications': ['Title', 'Date', 'Time', 'Status'],
        'stock_current': ['Available stock', 'Total stock', 'Location'],
        'supplier_returns': ['Reference ID', 'Part name','Quantity','Unit cost', 'Total (exc '+taxLabel+')', taxLabel   , 'Total (inc '+taxLabel+')', 'Date', 'Credit received', 'Job number', 'Job date', 'Customer name', 'Customer address line 1', 'Customer address line 2', 'Customer town', 'Customer county', 'Customer postcode', 'Supplier invoice no', 'Supplier invoice date', 'Purchase order no', 'Created by', 'Created on'],
        'stock_due_in': ['Quantity', 'Supplier', 'Date ordered', 'Delivery due date', 'Price per unit', 'Purchase order no'],
        'stock_history': ['Part name', 'Quantity', 'Action', 'Value change','Stock location','Reserved location', 'Date parts arrived', 'Created on time', 'Created by', 'Manufacture part number', 'Date of transaction', 'Time of transaction', 'Unit cost', 'Customer name', 'Customer address line 1', 'Customer address line 2', 'Customer address line 3', 'Customer town', 'Customer county', 'Customer postcode', 'Job no', 'Job description', 'Purchase order no', 'Supplier name'],
        'stock_location_history': ['Part name', 'Quantity', 'Action', 'Value change', 'Date parts arrived', 'Created on time', 'Created by', 'Manufacture part number', 'Date of transaction', 'Time of transaction', 'Unit cost', 'Customer name', 'Customer address line 1', 'Customer address line 2', 'Customer address line 3','Customer town', 'Customer county', 'Customer postcode', 'Job no', 'Job description', 'Purchase order no', 'Supplier name','Original stock location','New stock location','Reserved location'],
        'stock_suppliers': ['Name', 'Highest price paid', 'Lowest price paid', 'Average price paid', 'Last price paid', 'Landline number'],
        'stock_reserve_list': ['Stock location', 'Quantity', 'Date reserved', 'Job number', 'Job date', 'Customer name', 'Address line 1', 'Address line 2', 'Address line 3', 'Town', 'County', 'Postcode'],
        'reporting_customers_customers': ['Name', 'Telephone', 'Mobile', 'Email', 'Customer.Account.Number', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Town', 'County', 'Postcode', 'Building.Type', 'Marketing', 'Vacant', 'Status', 'Date.Created', 'Created.By', 'Customer.Type', 'Automatic.Sales.Care.turned.on', 'Price.Books'],
        'reporting_customers_contacts': ['Contact.Name', 'Customer.Name', 'Branch Name', 'Property.Address.Line1', 'Property.Address.Line2', 'Property.Address.Line3', 'Town', 'County', 'Postcode', 'Contact.Position', 'Main.Telephone', 'Main.Mobile', 'Email', 'Date.Created', 'Created.By'],
        'reporting_service_reminders_service_reminders': ['Service Type', 'Service Due On', 'Communication Type', 'Communications Sent', 'Phone Calls Made', 'Follow-up Communication Sent', 'Customer Name','Customer.Account.Number','Work.Address.Account.Number', 'Property Name', 'Property Address Line 1', 'Property Address Line 2', 'property Address Line 3', 'Property.Town', 'Property.County', 'Property.Postcode', 'Contact Telephone', 'Contact Email', 'Job Created', 'Job Date', 'Booked into Diary', 'First Engineer Name', 'Job Invoiced/FOC/Aborted'],
        'reporting_customers_work_addresses': ['Customer.Name','Customer.Account.Number','Work.Address.Account.Number', 'Branch.Name', 'Landlord.Name', 'Work Address Name','Work.Address.Line1', 'Work.Address.Line2', 'Work.Address.Line3', 'Work.Address.Town', 'Work.Address.County', 'Work.Address.Postcode', 'Work.Address.Telephone', 'Work.Address.Mobile', 'Work.Address.Email', 'Building.Type', 'Vacant', 'Status', 'Date.Created', 'Created.By'],
        'reporting_customers_branches': ['Customer.Name','Branch Name', 'Branch.Address.Line1', 'Branch.Address.Line2', 'Branch.Address.Line3', 'Branch.Town', 'Branch.County', 'Branch.Postcode'],
        'reporting_customers_landlords': ['Landlord Name', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Address.Town', 'Address.County', 'Address.Postcode', 'Number.of.Linked.Properties'],
        'reporting_customers_notes': ['Customer.Name', 'Work.Address.Name', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Town', 'County', 'Postcode', 'Telephone Number', 'Mobile Number', 'Email Address', 'Note.Created.On', 'Note.Created.By', 'Note.Title', 'Note', 'Important', 'Do.not.work.with', 'Send.to.mobile'],
        'reporting_customers_reminders': ['Reminder.Date', 'Notes', 'Created.On', 'Created.By', 'User', 'Customer.Name', 'Property.Name', 'Property.Address.Line1', 'Property.Address.Line2', 'Property.Address.Line3', 'Property.Town', 'Property.County', 'Property.Postcode', 'Property.Telephone', 'Property.Mobile', 'Property.Email'],
        'reporting_customers_phone_calls': ['Customer.Name', 'Address.Line.1', 'Address.Line.2', 'Address.Line.3', 'Town', 'County', 'Postcode', 'Telephone Number', 'Email Address', 'Contact.Name','Number','Call.Type','Note', 'Created.By', 'Date.Created'],
        'reporting_suppliers_suppliers': ['Name', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Town', 'County', 'Postcode', 'Telephone', 'Email', 'VAT.Registration', 'Credit.Terms', 'Third.Party.Accounting.Ref', 'Default.Nominal.Code', 'Number.of.Contacts', 'Number.of.Notes', 'Created.On', 'Created.By'],
        'reporting_suppliers_branches': ['Name', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Town', 'County', 'Postcode'],
        'reporting_suppliers_notes': ['Supplier.Name', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Town', 'County', 'Postcode', 'Telephone', 'Email', 'Note.Created.On', 'Note.Created.By', 'Note.Title', 'Note', 'Important', 'Do.not.work.with', 'Send.to.mobile'],
        'reporting_estimates_notes': ['Customer Name', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'Estimate No', 'Estimate Date', 'Estimate Description', 'Status', 'Note Created On', 'Note Created By', 'Note Title', 'Note', 'Important', 'Do Not Work With', 'Send to mobile'],
        'reporting_suppliers_contacts': ['Supplier.Name', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Contact.Name', 'Contact.Position', 'Contact Telephone', 'Contact Mobile', 'Contact Email', 'Date.Created', 'Created.By'],
        'reporting_suppliers_attached_files': ['Supplier.Name', 'Address.Line.1', 'Address.Line.2', 'Address.Line.3', 'Town', 'County', 'Postcode', 'Main.Telephone', 'Email', 'File.Created.On', 'Created.By','File name', 'File type', 'File size (MB)'],
        'reporting_suppliers_phone_calls': ['Supplier.Name', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Main.Telephone', 'Email Address', 'Date.Created', 'Created.By','Call Type', 'Contact name','Number','Note'],
        'reporting_customers_appliance_details':['Customer Name','Customer.Account.Number','Work.Address.Account.Number', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Landline', 'Mobile', 'Email', 'Appliance Group', 'Appliance Type', 'Make', 'Model', 'Fuel', 'Location', 'Flue', 'Serial Number', 'Reference', 'Ventilation', 'Status', 'Used For', 'Did you install', 'Have you notified the manufacturer', 'Installed on', 'Installed By', 'Commissioned On', 'Warranty Expiry', 'GC Number', 'Gas Safe Notification No', 'Fuel Type', 'Air Setting', 'Oil Pump Pressure Setting', 'Draught', 'Co2', 'Smoke No', 'Flue Gas Temperature', 'Fire Valve Type', 'Fire Valve Temperature', 'Oil Filter Type', 'Oil Filter Size', 'First Burner Type', 'First Burner Make', 'First Burner Model', 'First Burner Serial No', 'First Burner Code', 'First Nozzle Type', 'First Nozzle Size', 'First Nozzle Angle', 'First Nozzle Spray Pattern', 'Total No Burners', 'Total No Nozzles'],
        'reporting_customers_attached_files': ['Customer.Name', 'Address.Line.1', 'Address.Line.2', 'Address.Line.3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'File.Created.On', 'Created.By','File name', 'File type', 'File size (MB)'],
        'user_attached_files': ['Date added', 'File name', 'Size (MB)', 'Type'],
        'reporting_service_reminders_properties_without_services': ['Customer.Name','Customer.Account.Number','Work.Address.Account.Number', 'Work.Address.Name', 'Address.Line1', 'Address.Line2' , 'Address.Line3','Town' , 'County' , 'Postcode','Telephone Number', 'Email Address' ],
        'reporting_purchase_orders_purchase_orders': ['Date', 'Number', 'Supplier Name', 'Supplier Address Line 1', 'Supplier Address Line 2' ,'Supplier Address Line 3' , 'Supplier Town', 'Supplier County', 'Supplier Postcode' , 'Supplier Telephone' ,'Supplier Email', 'Customer Name','Customer.Account.Number','Work.Address.Account.Number', 'Customer Landline' , 'Customer Email', 'Job Address Name','Job Address Line 1' , 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County' , 'Job Address Postcode', 'Job Address Telephone', 'Job Number' , 'Job Description', 'Job Purchase Order Number', 'Created On', 'Created By', 'Purchase Order Sent', 'Total exc '+taxLabel, taxLabel, 'Total', 'Status'],
        'reporting_stock_control_stock_items' : ['Part name', 'Industry', 'Category', 'Subcategory', 'Type', 'Manufacturer number', 'Unit cost price', 'Mark up', 'VAT rate', 'Total stock', 'Reserved stock', 'Balance available', 'Reserved for return', 'Scheduled for supplier pickup', 'Stock value', 'Stock location'],
        'job_parts': ['Created by', 'Created on', 'Part', 'Supplier.Part.Number', 'MPN', 'Quantity', 'Status', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT'],
        'job_parts_requested': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Supplier.Part.Number', 'Quantity', 'Status'],
        'job_parts_on_order': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Supplier.Part.Number', 'Quantity', 'Status'],
        'job_parts_available': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Supplier.Part.Number', 'Quantity', 'Status'],
        'job_parts_available_with_stock': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Supplier.Part.Number', 'Quantity', 'Quantity Delivered', 'Status','Stock location'],
        'job_parts_scheduled': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Supplier.Part.Number', 'Quantity', 'Status', 'Engineer name', 'Diary time', 'Picked up status', 'Picked up at', 'Quantity scheduled'],
        'job_parts_progress': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Supplier.Part.Number', 'Quantity', 'Quantity Installed', 'Quantity Left to Install', 'Status'],
        'job_parts_installed': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Supplier.Part.Number', 'Quantity', 'Status'],
        'job_parts_cancelled': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Supplier.Part.Number', 'Quantity', 'Status'],
        'job_parts_returned': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Supplier.Part.Number', 'Quantity', 'Status'],
        'job_parts_returned_to_stock': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Supplier.Part.Number', 'Quantity', 'Status'],
        'reporting_users_user_list': ['Name', 'Date Created', 'Created By', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'Access Level', 'Username','Status', 'User Group', 'Associated Group', 'Appear on Diary', 'Colour on Diary', 'Employment Type', 'Date Joined', 'Cost rate', 'Sale rate', 'NI Number', 'Gas Safe card number','Branch', 'Last login date'],
        'job_assigned_todos': this.jobTodosHeader,
        'job_completed_todos_all': this.jobTodosClosedHeader,
        'job_completed_todos_not_done': this.jobTodosClosedHeader,
        'job_completed_todos_done': this.jobTodosClosedHeader,
        'customer_account_credit' : ['Date', 'Method', 'Reference', 'Amount', 'Amount allocated', 'Amount paid back', 'Balance'],
        'invoice_address_account_credit' : ['Date', 'Method', 'Reference', 'Amount', 'Amount allocated', 'Amount paid back', 'Balance'],
        'reporting_expenses_supplier_invoices': ['Date', 'Number', 'Supplier Name', 'Supplier Address Line 1', 'Supplier Address Line 2' ,'Supplier Address Line 3' , 'Supplier Town', 'Supplier County', 'Supplier Postcode' , 'Supplier Telephone' ,'Supplier Email', 'Customer Name','Customer.Account.Number','Work.Address.Account.Number', 'Customer Landline' , 'Customer Email', 'Job Address Name','Job Address Line 1' , 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County' , 'Job Address Postcode', 'Job Address Telephone', 'Job Number' , 'Job Description', 'Job purchase order number', 'Created On', 'Created By', 'Purchase Order Number', 'Total exc '+taxLabel, taxLabel, 'Total', 'Payment Date', 'Total Paid', 'Total Credited', 'Remainder To Pay'],
        'reporting_expenses_payments': ['Date', 'Supplier Name', 'Supplier Address Line 1', 'Supplier Address Line 2' ,'Supplier Address Line 3' , 'Supplier Town', 'Supplier County', 'Supplier Postcode' , 'Supplier Telephone' ,'Supplier Email', 'Created On', 'Created By', 'Method', 'Nominal Code', 'Reference', 'Total Paid'],
        'reporting_estimates_estimates' : ['Estimate Date', 'Estimate Number', 'Contact Name','Customer.Account.Number','Work.Address.Account.Number', 'Contact Telephone','Contact Mobile', 'Contact Email', 'Estimate Description', 'Estimate Status', 'Number of Planned Todos', 'Total Estimated Labour Cost', 'Total Estimated Labour Price', 'Total Estimated Parts Cost', 'Total Estimated Parts Price', 'Estimate Total', 'Expected Profit', 'Profit Margin', 'Number of Options', 'Number of Notes', 'Number of Communications', 'Customer Name', 'Estimate Address Name', 'Estimate Address Line 1', 'Estimate Address Line 2', 'Estimate Address Line 3', 'Estimate Town', 'Estimate County', 'Estimate Postcode','Property Telephone','Property Mobile','Open office task count', 'Total office task count', 'Customer reference'],
        'reporting_estimates_accepted_vs_rejected_estimates' : ['User','Number of Accepted Estimates', 'Number of Rejected Estimates', 'Percentage of Accepted Estimates', 'Value of Accepted Estimates','Value of Rejected Estimates'],
        'reporting_estimates_rejected_reasons': ['Estimate Date', 'Estimate Number', 'Contact Name', 'Contact Telephone', 'Contact Email', 'Estimate Description', 'Estimate Status', 'Number of Planned Todos', 'Total Estimated Labour Cost', 'Total Estimated Parts Cost', 'Estimate Total', 'Expected Profit', 'Profit Margin', 'Number of Notes', 'Number of Communications', 'Customer Name','Customer.Account.Number','Work.Address.Account.Number', 'Estimate Address Name', 'Estimate Address Line 1', 'Estimate Address Line 2', 'Estimate Address Line 3', 'Estimate Town', 'Estimate County', 'Estimate Postcode','Rejected By','Rejected On','Rejected Reason'],
        'reporting_jobs_additional_works':['Job No','Job Description','Customer reference', 'Additional Works Description', 'Additional Works Status', 'Total Cost', 'Total Price', 'Profit', 'Customer Name','Customer.Account.Number','Work.Address.Account.Number',  'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Town', 'Job County', 'Job Postcode', 'Contact Name', 'Contact Telephone', 'Contact Email','Created On', 'Created By'],
        'reporting_debtors_debtors': ['Invoice Address Name', 'Invoice Address Address Line 1', 'Invoice Address Address Line 2' ,'Invoice Address Address Line 3' , 'Invoice Address Town', 'Invoice Address County', 'Invoice Address Postcode' , 'Invoice Address Credit Days', 'Current' ,'1 to 30 Days', '31 to 60 Days', '61 to 90 Days', 'More than 90 Days', 'Total Monies Owed','Last statement sent on'],
        'reporting_users_time_sheets':['Name', 'Date', 'Scheduled time', 'Customer name','Customer.Account.Number','Job Address Account Number', 'Job address name', 'Job address line 1', 'Job address line 2', 'Job address line 3', 'Job address town','Job address county','Job address postcode', 'Job address telephone', 'Job address mobile', 'Job address email', 'Job/Quotation number', 'Job description', 'Event description', 'Job Notes', 'Access information', 'Access notes','Travel time', 'Arrive time', 'Total travel time', 'Total travel time (decimal hours)', 'Leave time', 'Total time on site', 'Total time on site (decimal hours)', 'Total travel time and time on site', 'Total travel time and time on site (decimal hours)', 'Final Status'],
        'reporting_diary_events_diary_events':['Customer Name','Customer.Account.Number','Work.Address.Account.Number', 'Customer Landline', 'Customer Mobile', 'Customer Email', 'Customer Address Line 1', 'Type of Event', 'Record number', 'Record created date', 'Contact Name', 'Record description', 'Customer reference', 'Quoted Amount', 'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Job Address Telephone', 'Job Address Mobile', 'Job Address Email', 'Diary Created By', 'Diary Created On', 'Diary Date', 'Event Description', 'Event Notes', 'Access Method', 'Access Notes', 'Engineer Name', 'Time', 'Confirmation sent to Customer', 'Confirmation sent to Work Address', 'Engineer Status', 'Total Travel Time', 'Total Time on Site', 'Number of To-Dos', 'Number of To-Dos left to complete', 'Total Labour Cost', 'Total Travel Cost', 'Business unit'],
        'reporting_jobs_jobs':['Customer Name','Customer.Account.Number','Work.Address.Account.Number', 'Customer Landline', 'Customer Mobile', 'Customer Email', 'Customer Address Line 1', 'Landlord Name', 'Job Number', 'Job Date', 'Contact Name', 'Job Description', 'Job Notes', 'Customer reference', 'Quoted Amount', 'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Job Address Telephone', 'Job Address Mobile', 'Job Address Email', 'Number of Diary Events', 'Priority', 'Business unit', 'Additional Contact', 'Last Diary Event', 'Next Diary Event', 'Last Engineer Name On Site', 'Next Engineer Name On Site', 'Last Engineer Mobile Status', 'Is Job Complete', 'Is a Recall', 'Number of Invoices Raised', 'Created From', 'Preferred date', 'Completed Date', 'Job on hold reasons','Customer contract','Job contract','Number of certificates'],
        'reporting_jobs_aborted_jobs':['Abort date/time', 'Abort created by', 'Abort reason', 'Abort note', 'Customer Name','Customer.Account.Number','Work.Address.Account.Number', 'Customer Landline', 'Customer Mobile', 'Customer Email', 'Customer Address Line 1', 'Landlord Name', 'Job Number', 'Job Date', 'Contact Name', 'Job Description', 'Job Notes', 'Customer reference', 'Quoted Amount', 'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Job Address Telephone', 'Job Address Mobile', 'Job Address Email', 'Number of Diary Events', 'Priority', 'Business unit', 'Additional Contact', 'Last Diary Event', 'Next Diary Event', 'Last Engineer Name On Site', 'Next Engineer Name On Site', 'Last Engineer Mobile Status', 'Number of To-Dos', 'Total Labour Cost', 'Total Travel Cost', 'Total Parts Costs', 'Total Supplier Cost', 'Number of Open Reminders', 'Is a Recall',  'Number of Communications', 'Number of Notes'],
        'reporting_jobs_free_of_charge_jobs':['Free of charge date/time', 'Free of charge created by', 'Free of charge reason','Free of charge note', 'Customer Name','Customer.Account.Number','Work.Address.Account.Number', 'Customer Landline', 'Customer Mobile', 'Customer Email', 'Customer Address Line 1', 'Landlord Name', 'Job Number', 'Job Date', 'Contact Name', 'Job Description', 'Job Notes', 'Customer reference', 'Quoted Amount', 'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Job Address Telephone', 'Job Address Mobile', 'Job Address Email', 'Number of Diary Events', 'Priority', 'Business unit', 'Additional Contact', 'Last Diary Event', 'Next Diary Event', 'Last Engineer Name On Site', 'Next Engineer Name On Site', 'Last Engineer Mobile Status', 'Number of To-Dos', 'Total Labour Cost', 'Total Travel Cost', 'Total Parts Costs', 'Total Supplier Cost', 'Number of Open Reminders', 'Is a Recall', 'Number of Communications', 'Number of Notes','Customer contract','Job contract'],
        'reporting_creditors_creditors' : ['Supplier Name', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Telephone', 'Email', 'Credit Terms', 'Total Amount Owed', 'Current', '1 to 30 Days', '31 to 60 Days', '61 to 90 Days', 'More than 90 Days'],
        'reporting_profit_profit_by_job':['Job date', 'Customer Name','Customer.Account.Number','Work.Address.Account.Number', 'Customer Address Line 1', 'Customer Address Line 2', 'Customer Address Line 3', 'Customer Town', 'Customer County', 'Customer Postcode', 'Job contact', 'Job description', 'Job notes', 'Job number', 'Customer reference', 'Job is complete', 'Final invoice raised', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Total number of diary events', 'Travel time', 'Time on site', 'Labour cost', 'Travel cost', 'Parts cost', 'Items cost', 'General and stock cost', 'Supplier invoices', 'Total cost', 'Open POs', 'Returns', 'Returns with credit note', 'Returns without credit note', 'Supplier credit notes', 'Customer credit notes', 'Total revenue', 'Total profit', 'Profit margin', 'Quoted amount', 'Completed Date', 'First engineer on site', 'Last engineer on site', 'Customer contract','Job contract'],
        'reporting_sales_sales' : ['Invoice or Credit Date','Invoice or Credit created date', 'Invoice Address Name', 'Invoice Address Line 1', 'Invoice Address Line 2', 'Invoice Address Line 3', 'Invoice Town', 'Invoice County', 'Invoice Postcode', 'Description', 'Invoice No or Credit No', 'Customer reference', 'Type', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Net', 'Deductions (CIS)', taxLabel, 'Total', 'Amount paid or allocated', 'Balance', 'Last payment or allocation date', 'Invoice Type','Last engineer on site','Payment due on'],
        'reporting_customers_jobEstimateAccessPhoneCallList':['Type', 'Job.estimate.no', 'Description', 'Status', 'Created.by', 'Created.on', 'Days.since.job.estimate.created', 'No.of.follow.up.sent', 'Last.communication.sent.on', 'Customer.name','Customer.Account.Number','Work.Address.Account.Number', 'Customer.reference', 'Customer.address.line.1', 'Customer.address.line.2', 'Customer.address.line.3', 'Customer.town', 'Customer.county', 'Customer.postcode', 'Customer.telephone.no', 'Customer.email', 'Work.address.name', 'Work.address.line.1', 'Work.address.line.2', 'Work.address.line.3', 'Work.address.town', 'Work.address.county', 'Work.address.postcode', 'Work.address.telephone.no', 'Work.address.email'],
        'ai_logs_waiting_customers' : ['Customer ID', 'Name', 'Surname', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Error'],
        'ai_logs_processing_customers' : ['Customer ID', 'Name', 'Surname', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Processing reason'],
        'ai_logs_failed_customers' : ['Customer ID', 'Name', 'Surname', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Failed reason'],
        'ai_logs_successful_customers' : ['Customer ID', 'Name', 'Surname', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Accounting reference', 'Sent on'],
        'ai_logs_do_not_send_customers' : ['Customer ID', 'Name', 'Surname', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode'],
        'ai_logs_waiting_customer_invoices' : ['Invoice date', 'Invoice no', 'Description', 'VAT', 'Total', 'Error'],
        'ai_logs_processing_customer_invoices' : ['Invoice date', 'Invoice no', 'Description', 'VAT', 'Total', 'Processing reason'],
        'ai_logs_failed_customer_invoices' : ['Invoice date', 'Invoice no', 'Description', 'VAT', 'Total', 'Failed reason'],
        'ai_logs_successful_customer_invoices' : ['Invoice date', 'Invoice no', 'Description', 'VAT', 'Total', 'Sent on'],
        'ai_logs_do_not_send_customer_invoices' : ['Invoice date', 'Invoice no', 'Description', 'VAT', 'Total'],
        'ai_logs_waiting_customer_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Error'],
        'ai_logs_processing_customer_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Processing reason'],
        'ai_logs_failed_customer_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Failed reason'],
        'ai_logs_successful_customer_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Sent on'],
        'ai_logs_do_not_send_customer_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference'],
        'ai_logs_waiting_customer_creditnotes' : ['Credit note date', 'Credit note no', 'Description', 'VAT', 'Total', 'Error'],
        'ai_logs_processing_customer_creditnotes' : ['Credit note date', 'Credit note no', 'Description', 'VAT', 'Total', 'Processing reason'],
        'ai_logs_failed_customer_creditnotes' : ['Credit note date', 'Credit note no', 'Description', 'VAT', 'Total', 'Failed reason'],
        'ai_logs_successful_customer_creditnotes' : ['Credit note date', 'Credit note no', 'Description', 'VAT', 'Total', 'Sent on'],
        'ai_logs_do_not_send_customer_creditnotes' : ['Credit note date', 'Credit note no', 'Description', 'VAT', 'Total'],
        'ai_logs_waiting_suppliers' : ['Supplier ID', 'Name', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Error'],
        'ai_logs_processing_suppliers' : ['Supplier ID', 'Name', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Processing reason'],
        'ai_logs_failed_suppliers' : ['Supplier ID', 'Name', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Failed reason'],
        'ai_logs_successful_suppliers' : ['Supplier ID', 'Name', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Accounting reference', 'Sent on'],
        'ai_logs_do_not_send_suppliers' : ['Supplier ID', 'Name', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode'],
        'ai_logs_waiting_supplier_invoices' : ['Invoice date', 'Invoice no', 'VAT', 'Total', 'Error'],
        'ai_logs_processing_supplier_invoices' : ['Invoice date', 'Invoice no', 'VAT', 'Total', 'Processing reason'],
        'ai_logs_failed_supplier_invoices' : ['Invoice date', 'Invoice no', 'VAT', 'Total', 'Failed reason'],
        'ai_logs_successful_supplier_invoices' : ['Invoice date', 'Invoice no', 'VAT', 'Total', 'Sent on'],
        'ai_logs_do_not_send_supplier_invoices' : ['Invoice date', 'Invoice no', 'VAT', 'Total'],
        'ai_logs_waiting_supplier_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Error'],
        'ai_logs_processing_supplier_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Processing reason'],
        'ai_logs_failed_supplier_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Failed reason'],
        'ai_logs_successful_supplier_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Sent on'],
        'ai_logs_do_not_send_supplier_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference'],
        'ai_logs_waiting_supplier_creditnotes' : ['Credit note date', 'Credit note no', 'VAT', 'Total', 'Error'],
        'ai_logs_processing_supplier_creditnotes' : ['Credit note date', 'Credit note no', 'VAT', 'Total', 'Processing reason'],
        'ai_logs_failed_supplier_creditnotes' : ['Credit note date', 'Credit note no', 'VAT', 'Total', 'Failed reason'],
        'ai_logs_successful_supplier_creditnotes' : ['Credit note date', 'Credit note no', 'VAT', 'Total', 'Sent on'],
        'ai_logs_do_not_send_supplier_creditnotes' : ['Credit note date', 'Credit note no', 'VAT', 'Total'],
        'ai_logs_failed_customer_import_payments' : ['Payment date', 'Description', 'Total', 'Payment reference', 'Failed reason'],
        'ai_logs_failed_supplier_import_payments' : ['Payment date', 'Description', 'Total', 'Payment reference', 'Failed reason'],
        'reporting_customers_advertising_performance' : ['Marketing', 'No New WorkAddresses', 'No New Customers', 'No Estimates Created', 'No Jobs Created', 'No Diary Events linked to Jobs', 'Total Revenue from Jobs', 'Total Costs from Jobs', 'Total Profit from Jobs'],
        'reporting_users_office_tasks':['Customer.Account.Number', 'Customer.name', 'Work.Address.Account.Number', 'Customer.address.line.1', 'Customer.address.line.2', 'Customer.address.line.3', 'Customer.town','Customer.county', 'Customer.postcode', 'Customer.telephone.no', 'Customer.mobile', 'Customer.email', 'Job.address.name', 'Job.address.line.1', 'Job.address.line.2', 'Job.address.line.3', 'Job.address.town', 'Job.address.county', 'Job.address.postcode', 'Job.address.telephone.no', 'Job.address.mobile', 'Job.address.email', 'Type', 'Job or Estimate created by', 'Job or Estimate created on', 'Job or Estimate no', 'Job or Estimate description', 'Job or Estimate notes', 'Task created on', 'Task created by', 'Task', 'Assigned to', 'Done', 'Done by', 'Done date time'],
        'reporting_customers_servicePlan':['Customer name','Customer.Account.Number','Work.Address.Account.Number', 'Customer address line 1', 'Customer address line 2', 'Customer address line 3', 'Customer town', 'Customer county', 'Customer postcode', 'Customer Telephone', 'Customer mobile', 'Customer email', 'Number of properties related to the plan', 'Service plan', 'Service plan created by', 'Service plan created on date/time', 'Service plan expiry date'],
        'reporting_parts_parts':['Part Name', 'Customer Name','Customer.Account.Number','Work.Address.Account.Number', 'Customer Address Line 1', 'Customer Telephone', 'Customer Mobile', 'Customer Email', 'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Job Address Telephone', 'Job Address Mobile', 'Job Address Email', 'Job Number', 'Job Description', 'Manufacturer Number', 'Quantity', 'Cost Price', 'Sale Price', 'Status', 'Requested Time', 'Fulfilled Time', 'Created By'],
        'reporting_parts_partsInstalled':['Part Name', 'Total Quantity', 'Total Cost Price', 'Total Sale Price'],
        'reporting_sales_Customer_Payments':['Payment Date', 'Invoice address name', 'Invoice address line 1', 'Invoice address line 2', 'Invoice address line 3', 'Invoice town', 'Invoice county', 'Invoice postcode', 'Invoice telephone', 'Invoice email', 'Payment created on', 'Payment created by', 'Invoice date', 'Invoice number', 'Invoice payment amount', 'Invoice customer reference', 'Invoice description', 'Invoice Amount', 'Payment method', 'Payment description', 'Payment nominal code', 'Payment reference', 'Payment amount', 'Payment Type'],
        'job_cost_supplier_returns': ['Reference ID', 'Part name','Quantity','Unit cost', 'Total (exc '+ taxLabel +')', taxLabel, 'Total (inc '+ taxLabel +')', 'Date', 'Credit received', 'Supplier invoice no', 'Supplier invoice date', 'Purchase order no', 'Created by', 'Created on'],
        'stock_location_reserve_list': ['Part', 'Quantity', 'Date reserved', 'Job number', 'Job date', 'Customer name', 'Address line 1', 'Address line 2', 'Address line 3', 'Town', 'County', 'Postcode'],
        'reporting_users_gps_leaderboard': [
            'User',
            'Distance',
            'Avg speed',
            'Excessive speeding',
            'Excessive braking',
            'Excessive acceleration',
            'Excessive cornering',
            'Score',
            'Max speed',
            'Engine hours',
            'Device ID',
            'Device unique identifier',
            'Device added date'
        ],
        'reporting_users_timesheet_approval' : [
            "Users",
            "Weeks",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday" ,
        ],
        'reporting_users_driver_trips' : [
            'User',
            "Start time",
            "Start address",
            "Start latitude",
            "Start longitude",
            "End time",
            "End address",
            "End latitude",
            "End longitude",
            "Distance",
            "Avg speed",
            "Duration",
            "Max speed"
        ],
        'reporting_users_driver_detail': [
            'Distance',
            'Avg speed',
            'Excessive speeding',
            'Excessive braking',
            'Excessive acceleration',
            'Excessive cornering',
            'Score',
            'Max speed',
            'Engine hours'
        ],
        // outstanding jobs
        'reporting_jobs_outstanding_jobs':[
            'Record Id',
            'Record Type',
            'Job Description',
            'Property Type',
            'Customer Name',
            'Contact Name',
            'Customer Account Number',
            'Work Address Account Number',
            'Customer Address Line1',
            'Customer Landline',
            'Customer Mobile',
            'Job/Estimate Address Name',
            'Job/Estimate Address Telephone',
            'Job/Estimate Address Mobile',
            'Job/Estimate Address Email',
            'Address Line1',
            'Address Line2',
            'Address Line3',
            'Town',
            'County',
            'Postcode',
            'Priority',
            'Business unit',
            'User Group',
            'Is Job/Estimate Complete',
            'Number of Diary Events',
            'Created Date'
        ],
        'reporting_jobs_SLA_jobs':[
            'Job Created On',
            'Job Created By',
            'Job Description',
            'Business unit',
            'User group',
            'Priority',
            'SLA metric name',
            'Customer SLA Name',
            'SLA Breached',
            'Diary Created On',
            'Diary Start Date Time',
            'Engineer Arrival Date Time',
            'Breach duration',
            'Job Completed On',
            'Customer Name',
            'Customer.Account.Number',
            'Job Number',
            'Contact Name',
            'Work.Address.Account.Number',
            'Customer Telephone',
            'Customer Mobile',
            'Customer Email',
            'Customer Address Line 1',
            'Job Address Name' ,
            'Job Address Line 1',
            'Job Address Line 2',
            'Job Address Line 3',
            'Job Address Town',
            'Job Address County',
            'Job Address Postcode',
            'Job Address Telephone',
            'Job Address Mobile',
            'Job Address Email',
            'SLA Breach time'
        ],
        'reporting_customers_contracts': [
            'Contract.template',
            'Customer.name',
            'Contract.category',
            'Contract.start.date',
            'Contract.expiry.date',
            'Status',
            'Telephone',
            'Address.Line1',
            'Address.Line2',
            'Address.Line3',
            'Town',
            'County',
            'Postcode',
            'Marketing',
            'Customer.type',
            'Customer.email',
            'Mobile.number',
            'Salesperson'
        ],
        'reporting_sales_draft_invoice' : [
            'Invoice Date',
            'Invoice Address Name',
            'Invoice Address Line 1',
            'Invoice Address Line 2',
            'Invoice Address Line 3',
            'Invoice Town',
            'Invoice County',
            'Invoice Postcode',
            'Description',
            'Invoice No',
            'Customer reference',
            'Job Address Line 1',
            'Job Address Line 2',
            'Job Address Line 3',
            'Job Address Town',
            'Job Address County',
            'Job Address Postcode',
            'Net',
            'Deductions (CIS)',
            taxLabel,
            'Total',
            'Invoice Type',
            'Last engineer on site'],
        'reporting_stock_control_stock_summary' : [
            'Location name' ,
            'Reserved stock valuation' ,
            'Reserved stock percentage' ,
            /*'Replenishment stock valuation',
            'Replenishment stock percentage',*/
            'Returning stock valuation',
            'Reserved for return percentage' ,
            'Available stock valuation' ,
            'Available stock percentage' ,
            'Total stock valuation'],
        'reporting_jobs_SLA_due_to_breach':[
            'Customer Name',
            'Customer.Account.Number',
            'Work.Address.Account.Number',
            'Customer Landline',
            'Customer Mobile',
            'Customer Email',
            'Customer Address Line 1',
            'Landlord Name',
            'Job Number',
            'Job Date',
            'Contact Name',
            'Job Description',
            'Job Notes',
            'Customer Reference',
            'Quoted Amount',
            'Job Address Name',
            'Job Address Line 1',
            'Job Address Line 2',
            'Job Address Line 3',
            'Job Address Town',
            'Job Address County',
            'Job Address Postcode',
            'Job Address Telephone',
            'Job Address Mobile',
            'Job Address Email',
            'Number of Diary Events',
            'Priority', 'Business unit',
            'Additional Contact',
            'Last Diary Event',
            'Next Diary Event',
            'Last Engineer Name On Site',
            'Next Engineer Name On Site',
            'Last Engineer Mobile Status',
            'Is Job Complete', 'Is a Recall',
            'Number of Invoices Raised',
            'Created From',
            'Preferred Date',
            'Completed Date',
            'Job On Hold Reasons',
            'Customer Contract',
            'Job Contract',
            'SLA Type',
            'SLA Countdown'],
        'reporting_sales_proposals_opportunities' : ['Customer name', 'Customer.Account.Number', 'Work.Address.Account.Number', 'Customer landline', 'Customer mobile', 'Customer email',
            'Created by', 'Created on date/time', 'Number',  'Template', 'Status', 'Customer reference', 'Access method', 'Customer contact', 'Customer contact telephone', 'Customer contact mobile', 'Customer contact email', 'Opportunity address line 1',
            'Opportunity address line 2', 'Opportunity address line 3', 'Opportunity town', 'Opportunity county', 'Opportunity postcode', 'Site contact', 'Sales person', 'Has proposal', 'Has customer photos/videos', 'Current pipeline stage',
            'Number of days in current pipeline stage', 'Pipeline stage last changed date/time', 'Pipeline stage last changed by',
            'Number of diary events', 'Is there a future diary event', 'First diary appointment created on date/time', 'First diary appointment date', 'First diary appointment time', 'First diary appointment assigned to', 'First diary appointment status','Next diary appointment created on date/time',
            'Next diary appointment date', 'Next diary appointment time', 'Next diary appointment assigned to', 'Next diary appointment status',
            'Number of days since last activity', 'Last activity type', 'Last activity created by', 'Last activity created on date/time', 'Current pipeline is rotting'],
        'reporting_sales_proposals_wonloss' : ['Sales person', 'Total number of opportunities assigned', 'Total number of activities', 'Average number of activities per opportunity',
                                                'Total number of proposals created', 'Number of accepted proposals', 'Value of accepted proposals', 'Number of rejected proposals',
                                                'Low value of rejected proposals', 'High value of rejected proposals', 'Conversion between proposals created and accepted', 'Conversion between opportunities created and proposals created'],
        'reporting_finance_financerequest' : ['Opportunity number', 'Opportunity template', 'Customer details', 'Total price', 'APR', 'Term', 'Deposit amount', 'Loan amount', 'Total payable', 'Installment', 'Finance status']
    };
    if($rootScope.moduleDetails['Contracts']==0){
        if(this.tableHeaders['reporting_jobs_jobs']){
            var test_reporting_jobs_jobs= this.tableHeaders['reporting_jobs_jobs'].findIndex(name=>name==='Customer contract');
            if (test_reporting_jobs_jobs !== -1) {
                this.tableHeaders['reporting_jobs_jobs'].splice(test_reporting_jobs_jobs, 1);
            }
        }
        if(this.tableHeaders['reporting_jobs_free_of_charge_jobs']){
            var test_reporting_jobs_free_of_charge_jobs= this.tableHeaders['reporting_jobs_free_of_charge_jobs'].findIndex(name=>name==='Customer contract');
            if (test_reporting_jobs_free_of_charge_jobs !== -1) {
                this.tableHeaders['reporting_jobs_free_of_charge_jobs'].splice(test_reporting_jobs_free_of_charge_jobs, 1);
            }
        }
    }
    if($rootScope.moduleDetails['Todos']==0){
        if(this.tableHeaders['reporting_estimates_estimates']){
            var expectedProfitHeaderIndex= this.tableHeaders['reporting_estimates_estimates'].findIndex(name=>name==='Expected Profit');
            if (expectedProfitHeaderIndex !== -1) {
                this.tableHeaders['reporting_estimates_estimates'].splice(expectedProfitHeaderIndex, 1);
            }

            var profitMarginHeaderIndex= this.tableHeaders['reporting_estimates_estimates'].findIndex(name=>name==='Profit Margin');
            if (profitMarginHeaderIndex !== -1) {
                this.tableHeaders['reporting_estimates_estimates'].splice(profitMarginHeaderIndex, 1);
            }
        }
        if(this.tableHeaders['reporting_estimates_rejected_reasons']){
            var expectedProfitHeaderIndex= this.tableHeaders['reporting_estimates_rejected_reasons'].findIndex(name=>name==='Expected Profit');
            if (expectedProfitHeaderIndex !== -1) {
                this.tableHeaders['reporting_estimates_rejected_reasons'].splice(expectedProfitHeaderIndex, 1);
            }

            var profitMarginHeaderIndex= this.tableHeaders['reporting_estimates_rejected_reasons'].findIndex(name=>name==='Profit Margin');
            if (profitMarginHeaderIndex !== -1) {
                this.tableHeaders['reporting_estimates_rejected_reasons'].splice(profitMarginHeaderIndex, 1);
            }
        }
    }
    if(!$rootScope.accessDetails[657]){
        if(this.tableHeaders['reporting_sales_proposals_opportunities']){
            var index = this.tableHeaders['reporting_sales_proposals_opportunities'].findIndex(name=>name==='Sales person');
            if(index !== -1) {
                this.tableHeaders['reporting_sales_proposals_opportunities'].splice(index,1);
            }
        }
    }
    this.collection = {};
    this.pageValue = false;
    this.setData = function setData(category, fullObject) {
        this.collection[category] = fullObject;
    }
    this.setHeaders = function setHeaders(category, fullObject){
        this.tableHeaders[category] = fullObject.all_columns;
    }
    this.setPageValue = function setPageValue(pageVal){
        this.pageValue = pageVal;
    }
    this.getCount = function getCount(category) {
        this.count = parseInt(this.collection[category].count);
        return this.count;
    }
    this.getPageValue = function getPageValue(){
        return this.pageValue;
    }
    this.appendData = function appendData(category, fullObject) {
        if(typeof this.collection[category] != "undefined"){
            let collection_data = this.collection[category].data;
            this.collection[category].data = collection_data.concat(fullObject.data)
        }
        else {
            this.collection[category] = fullObject;
        }
    }



    /*====================================================
     Instance variables
     ====================================================*/
    this.table_category;
    this.table_header_collection = [];
    this.table_data_collection = [];
    this.table_updated = false;
    this.table_view = 'table';
    this.table_view_params = {};

    /*====================================================
     setup the table with the last set of active_columns
     ====================================================*/
    this.handleTable = function handleTable(category) {
        this.table_category = category;
        if (this.collection[category]) {
            if(this.collection[category].view) {
                this.table_view = this.collection[category].view;
                this.table_view_params = this.collection[category].viewParams;
            } else {
                this.table_view = 'table';
                this.table_view_params = {};
            }

            this.handleTableData();
        }
    }

    /*====================================================
     - Builds an array of objects, each object stores the
     values for the relevant table headers.
     ====================================================*/
    this.handleTableData = function handleTableData() {
        var active_columns = this.orderColumnsByPos();
        var active_rows = this.orderRowsByPos();
        var data = this.collection[this.table_category].data;
        var rows = [];
        this.active_columns = active_columns;
        this.active_rows = active_rows;

        for(var x = 0, s = data.length; x < s; x++) {
            // reset the loop variables
            var row_cells = [];
            var temp_row = {};
            var temp_row_tpl = '';

            /*
             Build the temp_row object. Temp row obj attribute names
             are the table headers and the values will be the table cells.
             */
            for (var i = 0, l = active_columns.length; i < l; i++) {
                temp_row[active_columns[i].col_name] = data[x][active_columns[i].col_name];
            }

            /*
             Put each object attribute into an array.
             */

            for (var key in temp_row) {
                if (temp_row.hasOwnProperty(key)) {
                    row_cells.push(temp_row[key]);
                }
            }
            rows.push(temp_row);
        }
    }

    /*====================================================
     Update the table columns
     ====================================================*/
    this.updateTableCols = function updateTableCols(chosen_columns) {
        // replace the active_column obj with the columns selected by user
        this.collection[this.table_category].active_columns = chosen_columns;
        this.table_updated = true;
        this.handleTableData();
    }

    /*====================================================
     Re-order the objects in the active_columns array
     based on the attr 'pos' value
     ====================================================*/
    this.orderColumnsByPos = function orderColumnsByPos() {
        var active_columns = this.collection[this.table_category].active_columns;

        active_columns.sort(function(last, next) {
            return last.pos - next.pos
        });

        return active_columns;
    }

    /*====================================================
     Re-order the rows based on the pos of the row columns
     ====================================================*/
    this.summaryRowAdded = false;
    this.orderRowsByPos = function orderRowsByPos() {
        var columns = this.collection[this.table_category].active_columns;
        var data = this.collection[this.table_category].data;
        var summaryRow = (this.collection[this.table_category].summaryRow) ? this.collection[this.table_category].summaryRow : false;
        var rows = [];
        var sorted_rows = [];

        if(summaryRow && !this.summaryRowAdded && data.length) {
            var newRowToAppend = [];
            for (var colIndex = 0;colIndex < columns.length; colIndex++) {
                var columnName = columns[colIndex].col_name;
                if(summaryRow.hasOwnProperty(columnName)) {
                    var operation = summaryRow[columnName];
                    newRowToAppend[columnName] = this.getAdditionalData(columnName, data, operation);
                } else {
                    newRowToAppend[columnName] = '';
                }
            }
            data.push(newRowToAppend);
            this.summaryRowAdded = true;
        }

        if(this.table_view == 'table_customized'){

            rows = data;
            sorted_rows = rows;

        } else {

            for (var i = 0, l = columns.length; i < l; i++) {
                var col_name = columns[i].col_name;
                var col_pos = columns[i].pos;

                for (var c = 0, x = data.length; c < x; c++) {
                    for(var key in data[c]) {
                        if (key === col_name ) {
                            rows.push({ row: c, value: data[c][col_name], col_pos: col_pos, id: data[c]['id'] });
                        }

                    }
                }

            }


            for (var i = 0, l = rows.length / columns.length; i < l; i++) {
                sorted_rows.push(_.where(rows, {row: i}));
            }
        }

        return sorted_rows;
    }

    /*====================================================
     To check whether it has  summary rows
     ====================================================*/
    this.hasSummaryRow = function hasSummaryRow() {
        return (this.collection[this.table_category].summaryRow) ? true : false;
    }

    this.strikeoutRow = function (index) {
        return (this.collection[this.table_category].data[index].isVoided) ? true : false;
    }

    /*====================================================
     Get additional data for reporting
     ====================================================*/
    this.getAdditionalData = function getAdditionalData(columnName, data, operation) {
        var columnValue = 0;
        var hourValue = 0;
        var minuteValue = 0;
        for (var row = 0; row < data.length; row++) {
            for(var key in data[row]) {
                if (key === columnName ) {
                    var valueToAdd = parseFloat(data[row][key]);
                    if(operation == 'SUM' || operation == 'AVG') {
                        columnValue += valueToAdd;
                    } else if(operation == 'MAX') {
                        columnValue = (valueToAdd > columnValue) ? valueToAdd : columnValue;
                    } else if(operation == 'SUM_TIME') {
                        var valueToAdd = data[row][key];
                        if(valueToAdd != 0) {
                            valueToAdd = valueToAdd.replace(/\s/g, '');
                            var isHour = valueToAdd.indexOf('hour') !== -1;
                            var isMinute = valueToAdd.indexOf('min') !== -1;
                            hourValue += isHour ? parseFloat(valueToAdd.slice(0,valueToAdd.indexOf('hour'))) : 0;
                            minuteValue = isMinute ? (minuteValue += isHour ? parseFloat(valueToAdd.slice(valueToAdd.indexOf('hour')+4, valueToAdd.indexOf('min'))) : parseFloat(valueToAdd.slice(0,valueToAdd.indexOf('min')))) : 0;
                        }else{
                            columnValue += parseFloat(valueToAdd);
                        }
                    } else {
                        console.log('Dev : Please specify the operation');
                    }
                }
            }
        }

        if(operation == 'SUM_TIME') {
            // If the minutes exceed 60
            if (minuteValue >= 60) {
                // Divide minutes by 60 and add result to hours
                hourValue += Math.floor(minuteValue / 60);
                // Add remainder of totalminutes / 60 to minutes
                minuteValue = minuteValue % 60;
            }

            if(hourValue > 0 && minuteValue > 0) {
                return hourValue + " hour " + minuteValue + " min";
            }else if (hourValue == 0 && minuteValue > 0) {
                return minuteValue + " min";
            }else if (hourValue > 0 && minuteValue == 0) {
                return hourValue + "hour";
            } else {
                return columnValue;
            }

        }
        if(operation == 'AVG' && data.length) {
            columnValue = (columnValue / data.length).toFixed(2);
        }
        return columnValue.toFixed(2);
    }

    /*====================================================
     Getters
     ====================================================*/
    this.getActiveColumns = function getActiveColumns(category) {
        return this.collection[category].active_columns;
    }

    this.getOrderedColumns = function getOrderedColumns() {
        return this.active_columns;
    }

    this.getOrderedRows = function getOrderedRows() {
        return this.active_rows;
    }

    this.getBasicDetails = function getBasicDetails(category, i) {
        return this.collection[category].data[i];
    }

    this.getExtraDetails = function getExtraDetails(category, i) {
        return _.where(this.collection[category].panel_data,
            { id: this.collection[category].data[i].id }
        );
    }
    this.getAccessMethods = function getAccessMethods(category) {
        return this.collection[category].access_methods;
    }
    this.getSmsAccessMethods = function getSmsAccessMethods(category) {
        return this.collection[category].read_access;
    }
    this.getJobId = function getJobId(category) {
        return this.collection[category].jobId;
    }
    this.getAllColumns = function getAllColumns(category) {
        var category_collection = this.collection[category];
        var all_column_names = [];
        var columns = [];

        // Build an array of all of the available category names.
        var regexTaxLabel = new RegExp(taxLabel,"i");
        for (var key in this.tableHeaders[category]) {
            // commented out because if we dont have data, no headers are available - Raja - COM-1898
            //if (key != 'id' && category_collection.data[0].hasOwnProperty(key)) {
            var col = this.tableHeaders[category][key];
            if (col != 'id') {
                col = col.replace(regexTaxLabel, $rootScope.taxLabel);
                all_column_names.push(col);
            }
        }

        /*====================================================
         For all of the table headers in the category build an
         object that has the header name and whether the header
         is currently active.
         ====================================================*/
        for (var i = 0, l = all_column_names.length; i < l; i++) {

            var name = all_column_names[i];
            var temp = { col_name: name, active: false };

            for (var x = 0, s = category_collection.active_columns.length; x < s; x++) {
                var col = category_collection.active_columns[x];

                if(temp.col_name === col.col_name) {
                    temp.active = true;
                    temp.pos = col.pos;
                }
            }
            columns.push(temp);
        }

        return _.sortBy(columns, function(col) { return col.pos });  // Sort by user preference
    }

    this.getSidepanelData = function getSidepanelData(index, cat){
        return (this.collection[cat] != undefined)?this.collection[cat].data[index]:undefined;
    }

    this.getTableData = function getTableData(category){
        return this.collection[category].data;
    }

    /* Function for get the dropdown list for job edit todos */
    this.getDropdownData = function getDropdownData(cat) {
        return this.collection[cat].dropdownData;
    }

    this.updateActiveColumns = function updateActiveColumns(new_columns) {
        var new_active_columns = [];

        for (var i = 0, l = new_columns.length; i < l; i++) {
            if (new_columns[i].active === true) {
                new_columns[i].pos = i + 1;
                new_active_columns.push(new_columns[i]);
            }
        }

        // sort the new array based on the updated pos
        new_active_columns.sort(function(last, next) {
            return last.pos - next.pos
        });

        return new_active_columns;
    }
    this.getReminder = function getReminder(index){
        var data = [];
        data = this.collection.work_reminders_view_pending.data[index];
        return data;
    }
    this.countReminders = function countReminders(){
        var amount = this.collection.work_reminders_view_pending.data.length;
        return amount
    }
    this.getViewType = function() {
        return {
            name: this.table_view,
            params: this.table_view_params
        }
    }
});
