'use strict';

var pubnub, $q; // Holder for Pubnub
// beamer configuration
var beamer_config = {
    product_id : 'UXZXXPLF47656',
    selector : '.beamer-notifications',
    button : false,
    lazy : true
};

String.prototype.startsWith = function (prefix) {
    return this.indexOf(prefix) == 0;
};

String.prototype.endsWith = function (suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
};

function redirectOldViewToNew(toRoute, toParams){
    //Handled for bookmarked old submenus to redirect to new view
    var url = '';
    if(toRoute['name'] === 'loggedin.customer_list.view.notes.timeline') {
        url = '/customers/'+toParams['type']+'/'+toParams['id']+'/view_v2/communications/view';
    } else if(toRoute['name'] === 'loggedin.customer_list.view.contacts') {
        url = '/customers/'+toParams['type']+'/'+toParams['id']+'/view_v2/contacts/view';
    } else if(toRoute['name'] === 'loggedin.customer_list.view.branches') {
        url = '/customers/'+toParams['type']+'/'+toParams['id']+'/view_v2/branches/view';
    } else if(toRoute['name'] === 'loggedin.customer_list.view.workaddresses') {
        url = '/customers/'+toParams['type']+'/'+toParams['id']+'/view_v2/work/view';
    } else if(toRoute['name'] === 'loggedin.customer_list.view.appliances') {
        url = '/customers/'+toParams['type']+'/'+toParams['id']+'/view_v2/appliances/view';
    } else if(toRoute['name'] === 'loggedin.customer_list.view.assets') {
        url = '/customers/'+toParams['type']+'/'+toParams['id']+'/view_v2/assets/view';
    } else if(toRoute['name'] === 'loggedin.customer_list.view.technicalreference') {
        url = '/customers/'+toParams['type']+'/'+toParams['id']+'/view_v2/technical_reference/edit';
    } else if(toRoute['name'] === 'loggedin.customer_list.view.reminders') {
        url = '/customers/'+toParams['type']+'/'+toParams['id']+'/view_v2/service_reminder/view';
    } else if(toRoute['name'] === 'loggedin.customer_list.view.files') {
        url = '/customers/'+toParams['type']+'/'+toParams['id']+'/view_v2/files/view';
    }
    return url;
}

function checkForChannel(arr, channelId) {
    var found = false;
    angular.forEach(arr, function (val, key) {
        if (val.channelId && val.channelId == channelId) {
            found = true;
            return;
        }
    });
    return found;
}

// Override encodeURIComponent for null calls
(function () {
    var orig = window.encodeURIComponent;
    window.encodeURIComponent = function (str) {
        if (str !== null) {
            return orig.call(window, str);
        }
        return '';
    };
})();

// Polyfill CustomEvents for IE
(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

var globalTemplate = '<div compile-html="data"></div>';
// Declare app level module which depends on filters, and services
var csmodule = angular.module('commusoft', [
    'ngAnimate',
    'ngSanitize',
    'ui.router.compat',
    // 'commusoft.filters',
    // 'commusoft.services',
    // 'commusoft.directives',
    'commusoft.common',
    'pascalprecht.translate',
    'http-auth-interceptor',
    'ui.bootstrap',
    'ui.utils',
    'ui.tinymce',
    'pubnub.angular.service',
    'ui.select2',
    'ui.slider',
    'highcharts-ng',
    'angularFileUpload',
    'ngFileUpload',
    'ui.calendar',
    'company-settings',
    'system-settings',
    'suppliers',
    'setup-wizard',
    'customers',
    'reporting',
    'stocks',
    'ui.sortable',
    'ngDragDrop',
    'angularSpectrumColorpicker',
    'invoices',
    'credit-cards',
    'react',
    'ngCookies',
    'ngHandsontable',
    'csDiaryMap',
    //'integration',
    'assets',
    'asset-settings',
    'vsGoogleAutocomplete',
    'ui.bootstrap.datetimepicker'
]);

csmodule.
    constant('defaultPagingValue', defaultPaginationLimit).
    constant('externalJs', {
      'stripeJs': stripeJsUrl
    }).
    provider('cacheBust', function() {
        var cache = { 'cacheBust': 100};

        function $get() {
           return {
               cache: cache,
               setBustValue: setBustValue
           }
       }

       function setBustValue(val) {
            cache['cacheBust'] = val;
       }

       function getBustValue(val) {
            return cache['cacheBust'];
       }

       return {
           $get: $get,
           setBustValue: setBustValue,
           getBustValue: getBustValue
       }

    }).
    provider('canLoad',function () {
        this.$get = function () {
            return {
                canLoadAjax: true,
                setLoadValue: function (canLoad) {
                    this.canLoadAjax = canLoad;
                },
                canLoad: function () {
                    return this.canLoadAjax;
                }
            }
        }
    }).
    config(
        ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide', 'cacheBustProvider', 'CS_VERSION','CS_LANGUAGE','HybridFactory',
            function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide, cacheBustProvider, CS_VERSION,CS_LANGUAGE, HybridFactory) {
                cacheBustProvider.setBustValue(CS_VERSION);     // Init the value of CacheBust to CS_VERSION
                fos.Router.getInstance().setBaseUrl(prefix);
                $httpProvider.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
                $locationProvider.html5Mode(true).hashPrefix('!');
                $httpProvider.defaults.headers.common['Original-Request'] = window.location.pathname;

                $httpProvider.responseInterceptors.push('myHttpInterceptor');

                $provide.decorator("$http", ["$delegate", function($delegate) {
                    var get = $delegate.get;
                    $delegate.get = function(url, config) {
                        // Check is to avoid breaking AngularUI ui-bootstrap-tpls.js: "template/accordion/accordion-group.html"
                        if (url.indexOf('template/') != -1) {
                            // Append ?v=[cacheBustVersion] to url
                            url += (url.indexOf("?") === -1 ? "?" : "&");
                            url += "v=" + cacheBustProvider.getBustValue();
                        }
                        return get(url, config);
                    };
                    return $delegate;
                }]);

                $provide.decorator("uiSelect2Directive", function($delegate) {
                    var directive;
                    directive = $delegate[0];
                    directive.priority = 1;
                    return $delegate;
                });

                $provide.decorator("uiTinymceDirective", function($delegate) {
                    var directive;
                    directive = $delegate[0];
                    directive.priority = 1;
                    return $delegate;
                });

                $httpProvider.interceptors.push(function ($q, $rootScope) {
                    return {
                        'request': function(config) {
                            // Create a unique url identifer to group request and inject into header
                            if (!$rootScope.randomUUID) {
                                $rootScope.randomUUID = $rootScope.generateUUID();
                            }
                            if(!config.gpsOverride) config.headers['urlIdentifier'] = $rootScope.randomUUID;
                            return config;
                        },
                        'response': function (response) {
                            var data = response.data;
                            if(data.hasOwnProperty('webToken')) {
                                console.log('v5Token created');
                                $rootScope.msSetToken(data['webToken']);
                                let tokenService = $rootScope.renewalTokenService.getTokenService();
                                tokenService.setTokenInterval(data['nextRenewalTime']);
                                // $rootScope.renewalTokenService.start();
                                // NgMicroserviceHelperService.setTokenInterval(data['nextRenewalTime']);
                            }
                            if( data.hasOwnProperty('V5_Authentication_Failed_5a438c1f15a0680bb1b16520341d6673')) {
                                console.log('V5_Authentication_Failed:', data['V5_Authentication_Failed_5a438c1f15a0680bb1b16520341d6673']);
                            }
                            if (data.location) {
                                if(data.location.endsWith("/")) {
                                    $rootScope.$broadcast('event:redirectToLogin');
                                    return $q.reject(response);
                                } else if($rootScope.loadingLoginScreen) {
                                    $rootScope.loadingLoginScreen = false;
                                }
                            }
                            return response;
                        }
                    };
                });

                $translateProvider.registerAvailableLanguageKeys(['en', 'en-US'], {
                    'en': 'en',
                    'en-US': 'en-US'
                });

                $translateProvider.useStaticFilesLoader({
                    prefix: '/resources/i18n/locale_',
                    suffix: '.json'
                });
                $translateProvider.preferredLanguage(CS_LANGUAGE);
                $translateProvider.fallbackLanguage("en");
                let language = CS_LANGUAGE;
                if(language == 'en'){
                    language = 'en-GB';
                }
                moment.locale(language);

                var spinnerFunction = function (data, headersGetter) {
                    if (angular.element('body').injector()) { // TODO: REmove when all links are fixed,causes an issue if it goes to empty pages now
                        var canLoadService = angular.element('body').injector().get('canLoad');
                        if (canLoadService.canLoad()) {
                            $('#loading-indicator').show();
                        }
                    }
                    return data;
                };
                $httpProvider.defaults.transformRequest.push(spinnerFunction);

                /** START - Angular upgrade changes***********************************************/
                $urlRouterProvider.otherwise(function (e, locationHtml5Url, $injector) {
                    var isUpgraded = HybridFactory.isUpgraded(decodeURIComponent(locationHtml5Url.$$path));
                    if (!isUpgraded) {
                        return '/404';
                    }
                });

                // Exposing internal state for finding state match for url
                $stateProvider.decorator('parent', function (internalStateObj, parentFn) {
                    // This fn is called by StateBuilder each time a state is registered

                    // The first arg is the internal state. Capture it and add an accessor to public state object.
                    internalStateObj.self.$$state = function() { return internalStateObj; };

                    // pass through to default .parent() function
                    return parentFn(internalStateObj);
                });

                /** END***********************************************/

                $stateProvider
                    .state('home', {
                        url: '/',
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('login')
                        },
                        controller: LoginCtrl,
                        resolve: Resolver,
                        data: {
                            'errorMessage': ''
                        }
                    }).
                    state('google_openid', {
                        url: '/google_openid',
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('google_openid')
                        },
                        controller: LoginCtrl,
                        resolve: OpenIdResolver
                    }).
                    state('facebook_openid', {
                        url: '/facebook_openid',
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('facebook_openid')
                        },
                        controller: LoginCtrl,
                        resolve: OpenIdResolver
                    }).
                    state('facebook_connect', {
                        url: '/facebook_connect',
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('facebook_connect')
                        },
                        controller: LoginCtrl,
                        resolve: OpenIdResolver
                    }).
                    state('linkedin_connect', {
                        url: '/linkedin_connect',
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('linkedin_connect')
                        },
                        controller: LoginCtrl,
                        resolve: OpenIdResolver
                    }).
                    state('openidlogin', {
                        url: '/openidlogin',
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('openidlogin')
                        },
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('forgetpassword', {
                        url: Routing.generateAngularRoute('forgetpassword', false),
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('forgetpassword')
                        },
                        controller: ForgetPasswordCtrl,
                        resolve: Resolver,
                        data: {
                            'errorMessage': '',
                            'successMessage': ''
                        }
                    }).
                    state('resetPassword', {
                        url: Routing.generateAngularRoute('resetPassword', true),
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('resetPassword', params)
                        },
                        controller: ResetPasswordCtrl,
                        resolve: Resolver,
                        data: {
                            'errorMessage': ''
                        }
                    }).
                    state('setPassword', {
                        url: Routing.generateAngularRoute('setPassword', true),
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('setPassword', params)
                        },
                        controller: ResetPasswordCtrl,
                        resolve: Resolver,
                        data: {
                            'errorMessage': ''
                        }
                    }).
                    state('fivehundred', {
                        url: '/500',
                        templateUrl: '/template/500.html',
                        controller: FiveHundredErrorCtrl
                    }).
                    state('fourohfour', {
                        url: '/404',
                        templateUrl: 'template/404.html'
                    }).
                    state('contract', {
                        abstract: true,
                        templateUrl: '/template/contract_layout.html'
                    }).
                    state('contract.contractsummary', {
                        url: Routing.generateAngularRoute('contractsummary', false),
                        templateUrl: function (params) {
                            return Routing.generate('contractsummary')
                        },
                        views: {
                            "contractPane@contract": {
                                template: globalTemplate,
                                controller: ContractRootCtrl,
                                resolve: ContractResolver
                            }
                        }
                    }).
                    state('contract.termsandconditions', {
                        url: Routing.generateAngularRoute('termsandconditions', false),
                        templateUrl: function (params) {
                            return Routing.generate('termsandconditions')
                        },
                        views: {
                            "contractPane@contract": {
                                template: globalTemplate,
                                controller: ContractRootCtrl,
                                resolve: ContractResolver
                            }
                        }
                    }).
                    state('contract.payment', {
                        url: Routing.generateAngularRoute('payment', false),
                        templateUrl: function (params) {
                            return Routing.generate('payment')
                        },
                        views: {
                            "contractPane@contract": {
                                template: globalTemplate,
                                controller: ContractRootCtrl,
                                resolve: ContractResolver
                            }
                        }
                    }).
                    state('loggedin.search', {
                        url: Routing.generateAngularRoute('search', false),
                        templateUrl: function (params) {
                            return Routing.generate('search')
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 20
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: SearchCtrl,
                                resolve: Resolver
                            },
                            "searchPane@": {
                                templateUrl: 'template/search_results.html',
                                controller: SearchListCtrl
                            }
                        }
                    }).
                    state('loggedin.setup_contract', {
                        url: Routing.generateAngularRoute('setup_contract', true),
                        templateUrl: function (params) {
                            return Routing.generate('setup_contract')
                        },
                        template: globalTemplate,
                        controller: ContractRootCtrl,
                        resolve: ContractResolver
                    }).
                    state('loggedin.system_settings', {
                        url: Routing.generateAngularRoute('system_settings', false),
                        templateUrl: function (params) {
                            return Routing.generate('system_settings')
                        },
                        template: globalTemplate,
                        controller: SettingsCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.chat_message_view', {
                        url: Routing.generateAngularRoute('chat_message_view', true),
                        templateUrl: function (params) {
                            return Routing.generate('chat_message_view')
                        },
                        data: {
                            'chatUrl': '/listChatHistory'
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: ChatMessageMainCtrl,
                                resolve: Resolver
                            },
                            "chatlistPane@": {
                                templateUrl: 'template/settings/chatmessages/list_chatmessages.html',
                                controller: ChatMessageViewCtrl,

                                resolve: {
                                    chathistoryresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.chatUrl).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.chat_message_view.show', {
                        params: ['channelid'],
                        views: {
                            "chatmessagePane@": {
                                templateUrl: 'template/settings/chatmessages/show_chatmessages.html',
                                controller: ChatMessageShowCtrl
                            },
                            "chatPrintPane@": {
                                templateUrl: 'template/settings/chatmessages/chatprint.html',
                                controller: ChatPrintCtrl
                            }
                        }
                    }).
                    state('loggedin', {
                        abstract: true,
                        templateUrl: 'template/layout.html',
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.change_password', {
                        url: Routing.generateAngularRoute('change_password', false),
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('change_password')
                        },
                        controller: ChangePasswordCtrl,
                        resolve: Resolver,
                        data: {
                            'errorMessage': '',
                            'successMessage': ''
                        }
                    }).
                    state('loggedin.confirmsmtp', {
                        url: Routing.generateAngularRoute('confirmsmtp', true),
                        templateUrl: function (params) {
                            return Routing.generate('confirmsmtp')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: OpenIdResolver
                    }).
                    state('loggedin.logout', {
                        url: Routing.generateAngularRoute('logout', false) + '?errorMessage',
                        templateUrl: function (params) {
                            return Routing.generate('logout')
                        },
                        template: globalTemplate,
                        controller: LogoutCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.editprofile', {
                        url: Routing.generateAngularRoute('editprofile', true),
                        templateUrl: function (params) {
                            return Routing.generate('editprofile')
                        },
                        template: globalTemplate,
                        controller: EditProfileCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.progress', {
                        url: Routing.generateAngularRoute('progress', false),
                        templateUrl: function (params) {
                            return Routing.generate('progress')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.progress.levels', {
                        url: Routing.generateAngularRoute('progress_levels', true),
                        templateUrl: function (params) {
                            return Routing.generate('progress_levels', params)
                        },
                        views: {
                            "progressPane@": {
                                templateUrl: 'template/settings/users/progress_levels.html',
                                controller: ProgressLevelCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.progress.colleagues', {
                        url: Routing.generateAngularRoute('progress_colleagues', true),
                        templateUrl: function (params) {
                            return Routing.generate('progress_colleagues', params)
                        },
                        views: {
                            "progressPane@": {
                                templateUrl: 'template/settings/users/progress_colleagues.html',
                                controller: ProgressColleaguesCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.diary', {
                        url: '/diary',
                        templateUrl: function () {
                            return Routing.generate('diary')
                        },
                        views: {
                            "": {
                                controller: DiaryCtrl,
                                templateUrl: 'template/diary/diary.html',
                                resolve: {
                                    diaryObj: DiaryResolver.diaryObj,
                                    skills_data: SkillsResolver.skills_data
                                }
                            }
                        }
                    }).
                    state('loggedin.view_diary', {
                        url: Routing.generateAngularRoute('view_diary', true),
                        templateUrl: function (params) {
                            return Routing.generate('view_diary', params)
                        },
                        views: {
                            "": {
                                controller: DiaryCtrl,
                                templateUrl: 'template/diary/diary.html',
                                resolve: {
                                    diaryObj: DiaryResolver.diaryObj,
                                    skills_data: SkillsResolver.skills_data
                                }
                            }
                        }
                    }).
                    state('loggedin.diary_from_job_estimate', {
                        url: Routing.generateAngularRoute('diary_from_job_estimate', true),
                        templateUrl: function (params) {
                            return Routing.generate('diary_from_job_estimate', params)
                        },
                        views: {
                            "": {
                                controller: DiaryCtrl,
                                templateUrl: 'template/diary/diary.html',
                                resolve: {
                                    diaryObj: DiaryResolver.diaryObj,
                                    skills_data: SkillsResolver.skills_data
                                }
                            }
                        }
                    }).
                    state('loggedin.diary_from_milestone', {
                        url: Routing.generateAngularRoute('diary_from_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('diary_from_milestone', params)
                        },
                        views: {
                            "": {
                                controller: DiaryCtrl,
                                templateUrl: 'template/diary/diary.html',
                                resolve: {
                                    diaryObj: DiaryResolver.diaryObj,
                                    skills_data: SkillsResolver.skills_data
                                }
                            }
                        }
                    }).
                    state('loggedin.calendar_integration', {
                        url: Routing.generateAngularRoute('calendar_integration', false),
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('calendar_integration')
                        },
                        controller: CalendarIntegrationCtrl,
                        resolve: Resolver,
                        data: {
                            'errorMessage': '',
                            'successMessage': ''
                        }
                    }).
                    state('loggedin.view_calendar_integration', {
                        url: Routing.generateAngularRoute('view_calendar_integration', false),
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('view_calendar_integration')
                        },
                        controller: CalendarIntegrationCtrl,
                        resolve: Resolver,
                        data: {
                            'errorMessage': '',
                            'successMessage': ''
                        }

                    })
                    .state('loggedin.empty', {url: '', template: ''});

                // Customising ui-bootstrap datepicker
                $provide.decorator('datepickerDirective', function ($delegate) {

                    // replace ui-bootstrap datepicker html template with custom one
                    $delegate[0].templateUrl = 'template/date_picker.html';

                    /* If the datepicker is a date drop down then check that there is
                     enough height between the bottom of the input and the bottom of the
                     browser. If not then make the datepicker show above the input  */

                    var original = $delegate[0].compile;

                    $delegate[0].compile = function (element, attrs, transclude) {
                        var elmHeight = 275;

                        var handleDatepickerPos = function handleDatepickerPos(elmHeight, elmPos) {
                            if (elmHeight + elmPos.top > $(window).height()) {
                                element.addClass('show-above');
                            } else {
                                element.removeClass('show-above');
                            }
                        }

                        if (attrs.type === 'pagePicker') {
                            var elmPos = element.parents('.page-datepicker').offset();

                            handleDatepickerPos(elmHeight, elmPos);

                            window.onresize = function (event) {
                                var elmPos = element.parents('.page-datepicker').offset();

                                handleDatepickerPos(elmHeight, elmPos);
                            }
                        }
                        return original(element, attrs, transclude);
                    }

                    return $delegate;
                });
                // Customising ui-bootstrap carousel
                $provide.decorator('carouselDirective', function ($delegate) {

                    $delegate[0].compile = function (element, attrs, transclude) {
                        if (attrs.type === 'withDate') {
                            element.find('.circle-controller').hide();
                            element.find('.calendar-controller').show();
                        } else {
                            element.find('.calendar-controller').hide();
                            element.find('.circle-controller').show();
                        }
                    }

                    $delegate[0].templateUrl = 'template/carousel/data_section_slider.html';
                    return $delegate;
                });
            }
        ]).
    factory('myHttpInterceptor',function ($q, canLoad) {
        return function (promise) {
            return promise.then(function (response) {
                $('#loading-indicator').hide();
                canLoad.setLoadValue(true);
                return response;

            }, function (response) {
                $('#loading-indicator').hide();
                canLoad.setLoadValue(true);
                return $q.reject(response);
            });
        };
    }).
    run(function ($rootScope, $state, $templateCache, $location, $anchorScroll, $modal, canLoad, PubNub, prefix, $http, shortcutsContainer, $timeout, sharedPhraises, sessionLogger, ALL_PERMISSIONS, MODULE_PERMISSIONS, permissions, CurrencySymbol, gpsServerConfig, HybridFactory, $injector, scriptLoader, $translate, CS_LANGUAGE, NgSettingsService, CS_Intl,
                  NgMicroserviceHelperService,
                  NgRenewalTokenService) {
        $rootScope.loadedScript = scriptLoader.load();
        $rootScope.errorMessage = "";
        $rootScope.successMessage = "";
        $rootScope.clientId = $rootScope.userId = $rootScope.username = $rootScope.sessionId = '';
        $rootScope.chatWindows = [];
        $rootScope.chatMessages = [];
        $rootScope.channelMembers = [];
        $rootScope.accessDetails = [];
        $rootScope.shortcutModal = null;
        $rootScope.selectedSome = false;
        $rootScope.selection = '';
        $rootScope.previousUrl = '';
        $rootScope.changableState = false;
        $rootScope.previousState = '';
        $rootScope.stateToRedirectFromLogin = '';
        $rootScope.paramsToRedirectFromLogin = [];
        $rootScope.pubnubKeys = HybridFactory.getEnvironment().pubNubDetails;
        $rootScope.AIHost = null;
        $rootScope.loadingLoginScreen = false;
        $rootScope.settings_value = CS_Intl;
        $rootScope.renewalTokenService = NgRenewalTokenService;

        // Detect if the user is on mobile or not
        // Detect if the user is on mobile or not
        // Detect if the user is on mobile or not
        $rootScope.isMobile = isMobile;

        // Make permissions check functions available globally (for use in React)
        window.CSpermissionsCheck = permissions;

        // Set access permissions for user
        $rootScope.accessDetails = ALL_PERMISSIONS.accessDetails || [];

        // Set localization standards for user
        $rootScope.localStandards = ALL_PERMISSIONS.localStandards || {};


    $rootScope.msIsReplaceableEndpointUrl = function (requestUrl) {
        return NgMicroserviceHelperService.isReplaceableEndpointUrl(requestUrl);
    };

    $rootScope.msGetReplaceableEndpointReplacementUrl = function (requestUrl) {
        return NgMicroserviceHelperService.getReplaceableEndpointReplacementUrl(requestUrl);
    };

    $rootScope.msIsInterceptableRequest = function (requestUrl) {
        return NgMicroserviceHelperService.isInterceptableRequest(requestUrl);
    };

    $rootScope.msIsTokenExist = function () {
        return NgMicroserviceHelperService.isTokenExist();
    };

    $rootScope.msGetTokenHeaders = function () {
        return NgMicroserviceHelperService.getTokenHeaders();
    };

    $rootScope.msSetToken = function(token){
        return NgMicroserviceHelperService.setToken(token);
    };

        $rootScope.escapeHtmlSpecial = function(s){
            return(s + '')
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/'/g, '&apos;')
                .replace(/"/g, '&quot;');
        };

        $rootScope.escapeRegExpSpecial = function(s){
            return (s + '').replace(/[-[\]{}()*+?.,\\^$#\s]/g, "\\$&");
        };

        $rootScope.waitAndRedirect = function(stateToRedirect, stateParams, additionalParams) {
            var toState = 'loggedin.' + stateToRedirect;
            var stateData = $state.get(toState);
            if(!stateData) {
                toState = 'loggedin.customers';
                stateParams = {};
                additionalParams = {};
            }

            var refreshCount = 0;
            var waitingForConfigData = setInterval(function() {
                console.log('waiting ' + refreshCount);
                refreshCount ++;
                if($rootScope.accessDetails && $rootScope.accessDetails.hasOwnProperty('clientsconfig')) {
                    $state.transitionTo(toState, stateParams, additionalParams);
                    clearInterval(waitingForConfigData);
                } else if(refreshCount > 30) {
                    //Notify developer as the data is not loaded till 15 seconds
                    clearInterval(waitingForConfigData);
                }
            }, 500);
        }

        // Set GPS configurations for user
        if (ALL_PERMISSIONS['gpsConfig']) {
            gpsServerConfig.apiUrl = ALL_PERMISSIONS['gpsConfig']['gps_api'];
            gpsServerConfig.socketUrl = ALL_PERMISSIONS['gpsConfig']['gps_api_socket'];
            gpsServerConfig.token = ALL_PERMISSIONS['gpsConfig']['gps_api_key'];
            gpsServerConfig.mapTilesServer = ALL_PERMISSIONS['gpsConfig']['map_tiles_server'];
            gpsServerConfig.mapStylesUrl = ALL_PERMISSIONS['gpsConfig']['map_styles_url'];
        }

        //Checking the module permission
        $rootScope.moduleDetails = MODULE_PERMISSIONS.moduleDetails;

        sharedPhraises.create();
        //Here, set the local timezone into request header
        var timezone = jstz.determine();
        $http.defaults.headers.common.Timezone = timezone.name();

        /** START - Angular upgrade changes***********************************************/
        $q = $injector.get('$q');

        // Cancel angularJs navigation on viewing AngularES routes
        $rootScope.$on('$locationChangeStart', function (event, next, full) {
            HybridFactory.onLocationChangeStart(event, next, full);
        });

        // Redirecting into a blank logged-in view on viewing AngularES routes (Mainly for page reload)
        $rootScope.$on('$locationChangeSuccess', function (event) {
           HybridFactory.onLocationChangeSuccess(event, 'NG');
        });

        $rootScope.$on('$stateChangeStart', function (event, toRoute, toParams, fromRoute, fromParams) {
            var url = redirectOldViewToNew(toRoute, toParams);
            if(url !== '') {
                event.preventDefault();
                window.location.replace(url);
            }
            HybridFactory.onStateChangeStart(event, toRoute, toParams, fromRoute, fromParams);
        });
        /**END ***************************************************************************/

        $rootScope.$on('$stateChangeSuccess', function (event, toRoute, toParams, fromRoute, fromParams) {
            HybridFactory.onStateChangeSuccess(toRoute, toParams, fromRoute, fromParams);

            if($rootScope.hash) $location.$$hash = $rootScope.hash;

            if(toRoute.url) {
                var tabValue = toRoute.url.split('/')
                if(tabValue[1] != null && tabValue[1] != '' ){
                    $rootScope.selectedTab = tabValue[1];
                }
            }
            // For Angular7 routes
            else if(toRoute.name == 'loggedin.empty'){
                var tabValue = $location.path().split('/');
                if(tabValue[1] != null && tabValue[1] != '' ){
                    $rootScope.selectedTab = tabValue[1];
                }
            }

            setTimeout(function(){$anchorScroll()}, 1);
            sessionLogger.setEndTime(new Date().getTime());
            if (toRoute.name !== 'loggedin.empty' && fromRoute.url != ''){
                $http.post(prefix + '/add_to_sessionlog', "username=" + $rootScope.username + "&url=" + encodeURIComponent($location.url()) + "&timeTaken=" + sessionLogger.getDuration());
            }
        });

        $rootScope.getCustomCurrency = function () {
            $http.get(prefix + '/get_custom_currency').success(function (data) {

            var decodeHtmlEntity = function (str) {
                return str.replace(/&#(\d+);/g, function (match, dec) {
                    return String.fromCharCode(dec);
                });
            };

              $rootScope.CustomCurrencyCode = data.CustomCurrency;
              var CurrencySymbols = CurrencySymbol.getCurrencySymbol();
            var check_symbol = _.where(CurrencySymbols, {code: data.CustomCurrency});
            var symbol = decodeHtmlEntity(check_symbol[0].symbol);
            if (symbol) {
                $rootScope.CustomCurrency = symbol;
            }
            else {
                $rootScope.CustomCurrency = '£';
            }
        });
        };

    $rootScope.getTaxInfo = function(){
        $http.get(prefix + '/get_tax_info').success(function (data) {
            if(data)
            {
                $rootScope.isTaxEnabled = data.isTaxEnabled;
                $rootScope.taxType      = data.taxType;
                $rootScope.taxBreakdownType = data.taxBreakdownType;
                $rootScope.taxLabel = data.taxLabel;
            }else
            {
                $rootScope.isTaxEnabled = false;
                $rootScope.taxType      = null;
                $rootScope.taxBreakdownType = 1;
                $rootScope.taxLabel = '';
            }
        });
    };

        $rootScope.hasPermission = function (featureId, featureAccess) {
            if(!$rootScope.accessDetails) {
                //Todo: Need to remove this once we fix access method loading issue, Temporarily handling-santha
                return true;
            }
            if ($rootScope.accessDetails.hasOwnProperty(featureId)) {
                return $rootScope.accessDetails[featureId][featureAccess];
            } else {
                //TODO: Need to remove this later, after adding permissions
                // console.log("Please add permissions to this feature Id: " + featureId);
            }
            return false;
            // TODO: Changed to true so that permissions are always available. Revert later -- Raja Oct/9
            return true;
        }
        
        $rootScope.hasUIPermission = function (screen) {
            if($rootScope.accessDetails && !$rootScope.accessDetails.clientsconfig) {
                return false;
            }
            if ($rootScope.accessDetails && $rootScope.accessDetails.clientsconfig.hasOwnProperty(screen)) {
                return $rootScope.accessDetails.clientsconfig[screen];
            } else {
                return false;
            }
        }

        $rootScope.keyup = function (event) {
            if (event.target.localName === 'input' || event.target.localName === 'textarea' || (event.target.localName === 'div' && event.target.hasAttribute('contenteditable'))) {
                event.stopImmediatePropagation();
            } else {
                if (event.which === 190) {
                    $rootScope.shortcuts = shortcutsContainer.getShortcuts($state, $rootScope.selection);

                    $rootScope.currentstate = $state.$current.name.split('.');
                    if($rootScope.currentstate[0] == 'loggedin' ){
                    canLoad.setLoadValue(false);
                    if (!$rootScope.shortcutModal) {           
                        $newTopMenuEnabled = $rootScope.hasUIPermission('NewTopMenu')
                        $newIntegrationsEnabled = $rootScope.hasUIPermission('NewIntegrations')
    
                        if ($newTopMenuEnabled) {
                            $rootScope.shortcuts[0].options = $rootScope.shortcuts[0].options.filter(el => !['Company Settings', 'System Settings'].includes(el.description))
                        } else {
                            $rootScope.shortcuts[0].options = $rootScope.shortcuts[0].options.filter(el => !['Settings'].includes(el.description))
                        }
                                
                        if (!$newIntegrationsEnabled) {
                            $rootScope.shortcuts[0].options = $rootScope.shortcuts[0].options.filter(el => !['Integrations'].includes(el.description))
                        }
                                
                        $rootScope.shortcutModal = $modal.open({
                            templateUrl: 'template/shortcut_modal.html',
                            scope: $rootScope
                        });
    
                        $rootScope.shortcutModal.opened.then(function() {
                            $timeout(function () {
                                angular.element('#shortcut-modal').find('.select2-choice').trigger('mousedown');
                            }, 500);
                        })

                        $rootScope.shortcutModal.result.then(function () {
                            $rootScope.shortcutModal = null;
                        }, function () {
                            $rootScope.shortcutModal = null;
                        });
                        
                    }
                    }
                }

                if (event.which === 83) {
                    $('#search-input').focus();
                }

                if (event.which === 38) {
                    if ($rootScope.selection != '') {
                        $rootScope.$broadcast('event:moveUp');
                    }
                }

                if (event.which === 40) {
                    if ($rootScope.selection != '') {
                        $rootScope.$broadcast('event:moveDown');
                    }
                }
                // Escape key to close the right panel.
                if (event.which === 27) {
                    $rootScope.$broadcast('event:escape');
                    /*if ($rootScope.selection != '') { //Commented by Santha; i dont know why we add this if Block here
                        $rootScope.$broadcast('event:escape');
                    }*/
                }
                if(event.target.id == "search-input" && event.which == 13) {
                    $('#search-input').unbind('keyup', $rootScope.keyup);
                    $('#search-input').bind('keyup', $rootScope.keyup);
                }
            }
        };

        $rootScope.generateUUID = function() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
            });
        }

        $rootScope.$on('$stateChangeStart', function (event, toRoute, toParams, fromRoute, fromParams) {
            var bypassRoutes = ['home', 'google_openid', 'facebook_openid', 'facebook_connect', 'linkedin_connect', 'openidlogin', 'forgetpassword', 'loggedin.logout', 'resetPassword', 'setPassword'];
            var toStateName = toRoute.name;

            if (bypassRoutes.indexOf(toStateName) == -1 ) {
                $rootScope.stateToRedirectFromLogin = toStateName;
                $rootScope.paramsToRedirectFromLogin = toParams;
                if(_.isEmpty($rootScope.moduleDetails)) {
                    $rootScope.updateModulePermissions();
                }
            }

            if(toStateName == 'loggedin.logout') {
                $rootScope.stateToRedirectFromLogin = '';
                $rootScope.paramsToRedirectFromLogin = [];
                localStorage.removeItem("navigateDetails");
                localStorage.removeItem("cs-tags");
                // $rootScope.msClearToken();
                // $rootScope.renewalTokenService.stop();
            }

            if ($state.href(toRoute.name, toParams) != $rootScope.nextUrl) {
                // Its probably a new URL, so generate a uuid and store
                $rootScope.randomUUID = $rootScope.generateUUID();
            }
            $rootScope.nextUrl = $state.href(toRoute.name, toParams);

            $rootScope.previousState = fromRoute.name;
            $rootScope.previousStateparams = fromParams;
            if ($rootScope.changableState && $rootScope.previousUrl != '' && toStateName != $rootScope.previousUrl && fromRoute.name == $rootScope.toStateSelected) {
                event.preventDefault();
                $rootScope.changableState = false;
                //$rootScope.toStateSelected = '';
                var state = $rootScope.previousUrl;
                //$rootScope.previousUrl = '';
                $state.transitionTo(state);
            } else if (toStateName != $rootScope.toStateSelected && toStateName != $rootScope.previousUrl) {
                //console.log($rootScope.selection);
                $rootScope.selection = '';
            }
            sessionLogger.setStartTime(new Date().getTime());

            if (toRoute.name && toRoute.name.indexOf('loggedin') > -1) {
                $rootScope.ignore_minimum_resolution = false;
            } else {
                $rootScope.ignore_minimum_resolution = true;
            }

            window.DeviceSniffer.checkSpec({
                operatingSystems: [
                    'mac',
                    'linux',
                    'windows',
                    'chrome'
                ],
                browsers: [
                    {
                        name: 'ie',
                        version: 11
                    },
                    {
                        name: 'firefox',
                        version: 45
                    },
                    {
                        name: 'edge'
                    },
                    {
                        name: 'chrome',
                        version: 49
                    },
                    {
                        name: 'safari',
                        version: 9,
                        operatingSystems: ['mac']
                    },
                    {
                        name: 'safari',
                        version: 9,
                        operatingSystems: ['mac']
                    }
                ],
                browserWidth: 1280,
                // browser_height: 768,
                browserHeight: 0,
                screenWidth: 1280,
                // screenHeight: 768,
                screenHeight: 0,
                cookies: true,
                localStorage: true,
                customEvents: true
            }, function(spec) {
                $timeout(function() {
                    $rootScope.specErrors = undefined;
                    $rootScope.currentSpec = undefined;
                    $rootScope.meetsMinimumSpec = true;
                    document.body.classList.remove('mobile-view');
                }, 10);
            }, function(errors, spec) {
                if (toRoute.name &&
                    toRoute.name !== "forgetpassword" &&
                    toRoute.name !== "resetPassword" &&
                    toRoute.name !== "setPassword" ) {
                    $timeout(function() {
                        $rootScope.specErrors = errors;
                        $rootScope.currentSpec = spec;
                        $rootScope.meetsMinimumSpec = false;
                        document.body.classList.add('mobile-view');
                    }, 10);
                } else {
                    $timeout(function() {
                        $rootScope.specErrors = undefined;
                        $rootScope.currentSpec = undefined;
                        $rootScope.meetsMinimumSpec = true;
                        document.body.classList.remove('mobile-view');
                    }, 10);
                }
            });
        });

        $rootScope.selectShortcut = function (event, selectedOption) {
            $rootScope.previousUrl = $state.current.name;
            var state = shortcutsContainer.getUrl(selectedOption);

            if (state != "false") {
                $rootScope.toStateSelected = state;
                if ($rootScope.selection != '' || $state.params != '') {
                    var parameter = shortcutsContainer.getParameter(selectedOption);
                    if(HybridFactory.isUpgraded(state)){
                        $location.path(state);
                    }else{
                        $state.transitionTo(state, parameter);
                    }

                } else {
                    $state.transitionTo(state);
                }
            } else {
                var parameter = shortcutsContainer.getParameter(selectedOption);
                ShortCutsResponse.call($rootScope, parameter);

            }

            $rootScope.close();
        };

        $rootScope.opts = {
            backdropFade: true,
            dialogFade: true
        }

        $rootScope.close = function () {
            //$rootScope.selection = '';
            $rootScope.shortcutModal.close();
        }

        $rootScope.$on('event:auth-loginRequired', function (event, msg) {
            $('#loading-indicator').hide();
            if (angular.element('#successful-login').scope()) {
                angular.element('#successful-login').scope().saving = false;
            }
            $rootScope.errorMessage = msg.error;
            if (window.location.pathname == '/openidlogin') {
                //$state.transitionTo('openidlogin');
            } else {
                if($state.$current.name == 'home' && ((msg.reload && msg.reload == true) || $rootScope.stateToRedirectFromLogin != '')) {
                    $state.transitionTo('home', {}, {reload:true});
                } else {
                    $state.transitionTo('home');
                }
            }
        });

        $rootScope.$on('event:redirectToLogin', function (event) {
            if(!$rootScope.loadingLoginScreen) {
                $rootScope.$emit('closeAllSidepanels');
                $rootScope.loadingLoginScreen = true;
                $state.transitionTo('home');
            }
        });

        $rootScope.getAssigneeCount = function(){
            if($rootScope.accessDetails && $rootScope.hasPermission(features['ScheduleActivity'], 'readaccess')) {
                $http.get(prefix + '/sales/getScheduleActivityAssignee').success(function (data) {
                    if(data) {
                        $rootScope.assignedCount = data.assignedCount;
                        $rootScope.unAssignedCount = data.unAssignedCount;
                    }
                });
            }
        };

        $rootScope.$on('event:auth-loginConfirmed', function (event, msg) {
            let isFromLogin = false;
            if(msg && msg.hasOwnProperty('fromLogin')) {
                isFromLogin = true;
                $http.get(prefix + '/get_access_details').success(function (data) {
                    $rootScope.accessDetails = data.accessDetails;
                    shortcutsContainer.accessDetails = data.accessDetails;
                    $rootScope.localStandards = data.localStandards;

                    if (data['gpsConfig']) {
                        gpsServerConfig.apiUrl = data['gpsConfig']['gps_api'];
                        gpsServerConfig.socketUrl = data['gpsConfig']['gps_api_socket'];
                        gpsServerConfig.token = data['gpsConfig']['gps_api_key'];
                        gpsServerConfig.mapTilesServer = data['gpsConfig']['map_tiles_server'];
                        gpsServerConfig.mapStylesUrl = data['gpsConfig']['map_styles_url'];
                    }

                    $rootScope.$broadcast('permissionsChanged', data);
                });
            }
            loadAppData.call(this, $rootScope, $http, prefix, shortcutsContainer, CurrencySymbol, CS_LANGUAGE, gpsServerConfig, NgSettingsService, isFromLogin,$translate);
            /*$http.get(prefix + '/get_client_language?isBeamer=true').success(function (data) {
                if(typeof data == 'object' && data.hasOwnProperty('clientDetails')){
                    beamer_config.filter = data['clientDetails'];
                    if(window.Beamer){
                        window.Beamer.init()
                    }
                }
                if(typeof data == 'object' && data.hasOwnProperty('language') && CS_LANGUAGE !== data['language']){
                    $translate.uses(data['language']);
                    window.location.reload();
                }
            });
            $http.get(prefix + '/get_access_details').success(function (data) {
                $rootScope.accessDetails = data.accessDetails;
                shortcutsContainer.accessDetails = data.accessDetails;
                $rootScope.localStandards = data.localStandards;

                if (data['gpsConfig']) {
                    gpsServerConfig.apiUrl = data['gpsConfig']['gps_api'];
                    gpsServerConfig.socketUrl = data['gpsConfig']['gps_api_socket'];
                    gpsServerConfig.token = data['gpsConfig']['gps_api_key'];
                    gpsServerConfig.mapTilesServer = data['gpsConfig']['map_tiles_server'];
                    gpsServerConfig.mapStylesUrl = data['gpsConfig']['map_styles_url'];
                }

                $rootScope.$broadcast('permissionsChanged', data);
            });

            $rootScope.updateModulePermissions();

            $http.get(prefix + '/get_ai_host').success(function(data) {
                $rootScope.AIHost = data.host;
            });


            //check schedule activity permission
            var featureName = 'ScheduleActivity', featureAccess = 'readaccess';
            var featureId = features[featureName];
            var scheduleActivityList = $rootScope.hasPermission(featureId, featureAccess);

            if(scheduleActivityList == 1){
                $rootScope.scheduleActivityList = true;
            }else{
                $rootScope.scheduleActivityList = false;
            }

            $rootScope.getTaxInfo();
            $rootScope.getCustomCurrency();*/

            NgSettingsService.getJWTTOKENData();
        });

        $rootScope.updateModulePermissions = function() {
            $http.get(prefix + '/module_permission_check').success(function(data) {
                $rootScope.moduleDetails = data.moduleDetails;
                $rootScope.commusoft_loading = false;
                $rootScope.$broadcast('modulesChanged', data);
            });
        }

        $rootScope.$broadcast('event:auth-loginConfirmed');

        $rootScope.$on('$viewContentLoaded', function () {
            var requiredTemplates = ['template/side_panels/add_new_diary_event.html', 'template/side_panels/view_diary_event.html', 'template/side_panels/estimate_view_diary_event.html'];
            var templateToRestore = [];
            for(var index = 0; index < requiredTemplates.length; index++) {
                var templateId = requiredTemplates[index];
                templateToRestore.push({templateId: templateId, content: $templateCache.get(templateId)});
            }
            if(templateToRestore.length) {
                $templateCache.removeAll();
                for(var i = 0; i < templateToRestore.length; i++) {
                    var templateId = templateToRestore[i].templateId;
                    $templateCache.put(templateId, templateToRestore[i].content);
                }
            }
        });
        $rootScope.$on('chat:unregister', function (event, msg) {
            // Unsubscribe from all channels
            $timeout(function() {
                pubnub.unsubscribe({
                    channel: 'commusoft_' + $rootScope.clientId
                });
                pubnub.unsubscribe({
                    channel: 'commusoft_' + $rootScope.clientId + '_' + $rootScope.userId
                });
            }, 10);
        });
        $rootScope.$on('chat:register', function (event, msg) {
            pubnub =  $rootScope.initializePubnub();
            pubnub.subscribe({
                channel: 'commusoft_' + $rootScope.clientId + '_' + $rootScope.userId,
                callback: function (message, meta) {
                    if (message.text && message.text == 'cs:logout') {
                        // If its a logout message and not from "self", then log me out
                        if (message.from.userId && $rootScope.sessionId && $rootScope.sessionId != message.from.sessionId
                            && $rootScope.userId && message.from.clientId && $rootScope.clientId
                            && message.from.userId == $rootScope.userId && message.from.clientId == $rootScope.clientId) {
                            $rootScope.$emit('closeAllSidepanels');
                            $rootScope.$emit('event:escape');
                            $rootScope.errorMessage = "Another user has logged in using your username. You have been logged out.";
                            $state.transitionTo('loggedin.logout');
                            $rootScope.$apply();
                        }
                        return;
                    }
                    if (message.text == 'print_status') {
                        $rootScope.$broadcast(message.action, message.data);
                    }
                    if (message.text && message.text == 'cs:newNotification') {
                        // Get scope for notifications and call getNotifications on it
                        angular.element('.notifications-wrapper').scope().getNotifications();
                        $rootScope.$apply();
                        return;
                    }
                    if (message.text && message.text == 'office_task_notifications') {
                        $rootScope.$broadcast(message.action, message.data);
                    }
                    if (message.from && checkForChannel($rootScope.chatWindows, message.from.channelId) == false) {
                        var msgFrom = message.from;
                        msgFrom.channelId = message.channelId; // Add channelId to track conversations, sent by publisher
                        $rootScope.chatWindows.push(msgFrom);
                        $rootScope.chatMessages[message.channelId] = [];
                        $rootScope.$apply();

                    }

                    if (message.from) {
                        var msg = {
                            'text': message.text,
                            'timestamp': meta[1],
                            'formattedTime': moment.unix(Math.round(meta[1] / 10000000)).format('HH:mm'),
                            'from': message.from
                        }
                        $rootScope.chatMessages[message.channelId].push(msg);
                        $rootScope.$apply();
                        $location.hash(msg.timestamp);
                        $anchorScroll();
                        $timeout(function () {
                            $location.hash('');
                        });
                    }
                    console.log(message);
                }
            });
            pubnub.subscribe({
                channel: 'commusoft_' + $rootScope.clientId,
                callback: function (msg, meta) {
                    if (msg.text == 'diary' && msg.from.userId != $rootScope.userId) {
                        $rootScope.$broadcast(msg.action, msg.event);
                    }
                    if (msg.text == 'diary_status') {
                        $rootScope.$broadcast(msg.action, msg.data);
                    }
                    if(msg.text === 'job_status'){
                        $rootScope.$broadcast(msg.action, msg.data);
                    }
                    if(msg.text === 'opportunity_board'){
                        $rootScope.$broadcast(msg.action, msg.data);
                    }
                    if(msg.text === 'schedule_activity'){
                        $rootScope.getAssigneeCount();
                    }
                    if(msg.text === 'scheduled_activities'){
                        $rootScope.$broadcast(msg.action, msg.data);
                    }
                    if(msg.text === 'workflow_broadcast'){
                        $rootScope.$broadcast(msg.action, msg.data);
                    }
                },
                presence: function (message, env, channel) {
                    var uuid = message.uuid;
                    var userId = null;
                    var splitArray = message.uuid.split('_');
                    if (splitArray.length == 3) {
                        if (splitArray[2] != 'undefined') {
                            userId = splitArray[2];
                        }
                    }

                    if (userId != null) {
                        if (message.action == 'join') {
                            $rootScope.channelMembers.push(userId);
                        } else if (message.action == 'leave' || message.action == 'timeout') {
                            var elemIndex = $rootScope.channelMembers.indexOf(userId);
                            if (elemIndex != -1) {
                                $rootScope.channelMembers.splice(elemIndex, 1);
                            }
                        }
                    }
                    console.log(message);
                }
            })
        });

        $rootScope.getAssigneeCount = function(){
            if($rootScope.accessDetails && $rootScope.hasPermission(features['ScheduleActivity'], 'readaccess')) {
                $http.get(prefix + '/sales/getScheduleActivityAssignee').success(function (data) {
                    if(data) {
                        $rootScope.assignedCount = data.assignedCount;
                        $rootScope.unAssignedCount = data.unAssignedCount;
                    }
                });
            }
        };

        /*$rootScope.getTaxInfo();
        $rootScope.getCustomCurrency();*/

    // Initialize PubNub
    $rootScope.initializePubnub = function (){
        if(pubnub) {
            return pubnub;
        }
        let initialize;
        initialize = PubNub.init({
            publish_key: $rootScope.pubnubKeys.publishKey,
            subscribe_key: $rootScope.pubnubKeys.subscribeKey,
            origin: 'ps.pndsn.com',
            ssl: true,
            uuid: 'commusoft_' + $rootScope.clientId + '_' + $rootScope.username
        });
        return initialize;
    }

    //publish logout using pubnub
    $rootScope.publishLogoutEventToCommusoft = function (){
        if (pubnub){
            pubnub.publish({
                channel: 'commusoft_' + $rootScope.clientId + '_' + $rootScope.userId,
                message: {
                    text: 'cs:logout',
                    from: {
                        userId: $rootScope.userId,
                        username: $rootScope.username,
                        sessionId: $rootScope.sessionId,
                        clientId: $rootScope.clientId
                    }
                },
                callback: function () {
                }
            });
            $rootScope.$broadcast('chat:register');
        }
    }

        // Load Diary Side Panel
        $http.get('template/side_panels/add_new_diary_event.html', {cache: $templateCache});
        $http.get('template/side_panels/view_diary_event.html', {cache: $templateCache});
        $http.get('template/side_panels/estimate_view_diary_event.html', {cache: $templateCache});
    });

    function loadAppData($rootScope, $http, prefix, shortcutsContainer, CurrencySymbol, CS_LANGUAGE, gpsServerConfig, NgSettingsService, isFromLogin,$translate) {
        $http.get(prefix + '/get_user_config_data').success(function (data, status, headersObj) {
            var headers = headersObj();
            if(data) {
                if($rootScope.sessionId == ''){
                    if ('x-sessionid' in headers) {
                        $rootScope.sessionId = headers['x-sessionid'];
                        $rootScope.clientId = headers['x-clientid'];
                        $rootScope.userId = headers['x-userid'];
                        $rootScope.username = headers['x-username'];
                        if ($rootScope.clientId && $rootScope.userId && $rootScope.username){
                            if (!pubnub) {
                                pubnub = $rootScope.initializePubnub();
                            }
                            if (pubnub){
                                $rootScope.publishLogoutEventToCommusoft();
                            }
                        }
                    }
                }
                // Access details config
                let accessResponse = data['accessDetailsResp'];
                let gpsConfig = accessResponse['gpsConfig'];
                let accessDetails = accessResponse['accessDetails'];
                if(accessDetails && accessDetails.hasOwnProperty('clientsconfig') && !$rootScope.accessDetails.hasOwnProperty('clientsconfig')) {
                    isFromLogin = true;
                }
                $rootScope.accessDetails = accessDetails;
                shortcutsContainer.accessDetails = accessDetails;
                $rootScope.localStandards = accessResponse['localStandards'];
                if (gpsConfig) {
                    gpsServerConfig.apiUrl = gpsConfig['gps_api'];
                    gpsServerConfig.socketUrl = gpsConfig['gps_api_socket'];
                    gpsServerConfig.token = gpsConfig['gps_api_key'];
                    gpsServerConfig.mapTilesServer = gpsConfig['map_tiles_server'];
                    gpsServerConfig.mapStylesUrl = gpsConfig['map_styles_url'];
                }
                $rootScope.$broadcast('permissionsChanged', accessResponse);

                // Module config
                $rootScope.moduleDetails = data['moduleDetails']['moduleDetails'];
                $rootScope.commusoft_loading = false;
                $rootScope.$broadcast('modulesChanged', data['moduleDetails']);

                // Language config
                if(CS_LANGUAGE !== data['clientLanguage']){
                    $translate.uses(data['clientLanguage']);
                }

                // Beamer config
                beamer_config.filter = data['beamerData'];
                if(window.Beamer){
                    window.Beamer.init()
                }

                // AI Host config
                $rootScope.AIHost = data['aiHost'];

                // Tax info config
                let taxInfo = data['taxInfo'];
                $rootScope.isTaxEnabled = taxInfo['isTaxEnabled'];
                $rootScope.taxType = taxInfo['taxType'];
                $rootScope.taxBreakdownType = taxInfo['taxBreakdownType'];
                $rootScope.taxLabel = taxInfo['taxLabel'];
                // LMS ON check
                $rootScope.isLMSOn = data['isLMSOn'];
                // Custom currency config
                let customCurrencyRes = data['customCurrency'];
                let decodeHtmlEntity = function (str) {
                    return str.replace(/&#(\d+);/g, function (match, dec) {
                        return String.fromCharCode(dec);
                    });
                };
                let customCurrency = customCurrencyRes['CustomCurrency'];
                $rootScope.CustomCurrencyCode = customCurrency;
                let CurrencySymbols = CurrencySymbol.getCurrencySymbol();
                let check_symbol = _.where(CurrencySymbols, {code: customCurrency});
                let symbol = decodeHtmlEntity(check_symbol[0].symbol);
                if (symbol) {
                    $rootScope.CustomCurrency = symbol;
                } else {
                    $rootScope.CustomCurrency = '£';
                }
                $rootScope.intlSettingsCurrency = data['intlSettings']['currency'];


                if(isFromLogin) {
                    let appData = {
                        ALL_PERMISSIONS: accessResponse,
                        MODULE_PERMISSIONS: data['moduleDetails'],
                        CS_VERSION: data['csVersion'],
                        CS_LANGUAGE: data['clientLanguage'],
                        INTL_SETTINGS: data['intlSettings'],
                        responseHeaders: Response.headers,
                        COUNTRY_CODE: data['clientCountryCode']};
                    NgSettingsService.dataFromA1App(appData);
                }

                // Load schedule activities
                $rootScope.getAssigneeCount();
            }
            $rootScope.commusoft_loading = false;
        });
    }
